import React, { useContext, useEffect, useReducer, useState } from "react";
import Dialog from "@material-ui/core/Dialog";

import {
    createStyles,
    Grid,
    IconButton,
    makeStyles,
    Typography,
    useTheme,
    Box

} from "@material-ui/core";
import tableIcons, { options } from "components/universal/table_attributes";
import MaterialTable from "material-table";
import { ToastMessageContext } from "lib/contexts/message_context";
import { getOrganisationId } from "lib/utils/common_utils";
import CommercialIconRed from "assets/maint/Commercial/CommercialIconRed.png";


const useStyles = makeStyles((theme) =>
    createStyles({
        modal: {
            "& .MuiDialog-paperWidthSm": {
                minWidth: "800px",
                height: "max-content"
            },
        },
        container: {
            // minWidth: "1200px",
            // minHeight: "400px",
            padding: "25px",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            // overflow: "hidden !important"
        },


        actionButtons: {
            marginTop: '25px',
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
        },

        partValue: {
            fontSize: "14px",
            color: "#4D4F5C",
            padding: "2px",
            display: "flex",
            alignItems: "center",
        },
        partKey: {
            "& p": {
                fontSize: "14px",
                padding: "1px",
            },
        },
        partCard: {
            borderRadius: "8px",
            padding: "17px 24px",
            "& p": {
                fontSize: "14px",
                padding: "5px",
            },
        },
    })
);
export default function ApprovalVar(props) {

    const { handleClose, currentSelection, orderType } = props
    const classes = useStyles();

    const message = useContext(ToastMessageContext);

    const [loading, setLoading] = useState(false)

    const [data, setData] = useState([])
    const [wipData, setWipData] = useState([])


    const GetInfoCard = (props) => {
        const classes = useStyles();
        const theme = useTheme();

        return (
            <Box display="flex">
                <Box flex={25} className={classes.partKey}>
                    <Typography
                        style={{ padding: "5px", color: theme.palette.primary.main }}
                        color="secondary"
                    >
                        {props?.keyName}
                    </Typography>
                </Box>
                <Box flex={75} className={classes.partValue}>
                    {props?.value ?? "-"}
                </Box>
            </Box>
        );
    };

    useEffect(() => {

        setLoading(true)
        if (currentSelection !== undefined) {

            let wip_labour_r = currentSelection?.wip_labour ? parseFloat(currentSelection?.wip_labour) / currentSelection?.sales_rate : ""
            let wip_parts_r = currentSelection?.wip_parts ? parseFloat(currentSelection?.wip_parts) / currentSelection?.sales_rate : ""
            let wip_services_r = currentSelection?.wip_services ? parseFloat(currentSelection?.wip_services) / currentSelection?.sales_rate : ""
            let wip_equipment_r = currentSelection?.wip_equipment ? parseFloat(currentSelection?.wip_equipment) / currentSelection?.sales_rate : ""

            let total = (wip_labour_r ? +wip_labour_r : 0) + (wip_parts_r ? +wip_parts_r : 0) + (wip_services_r ? +wip_services_r : 0) + (wip_equipment_r ? wip_equipment_r : 0)

            let data = [

                {
                    wip_labour: parseFloat(currentSelection?.wip_labour).toFixed(2),
                    wip_parts: wip_parts_r !== "" || null ? parseFloat(currentSelection?.wip_parts).toFixed(2) : "0.00",
                    wip_services: parseFloat(currentSelection?.wip_services).toFixed(2),
                    wip_equipment: parseFloat(currentSelection?.wip_equipment).toFixed(2),
                    total: parseFloat(currentSelection?.wip_overall).toFixed(2),
                    currency: currentSelection?.default_currency


                },
                {
                    wip_labour: parseFloat(wip_labour_r).toFixed(2),
                    wip_parts: wip_parts_r !== "" || null ? parseFloat(wip_parts_r).toFixed(2) : "0.00",
                    wip_services: parseFloat(wip_services_r).toFixed(2),
                    wip_equipment: parseFloat(wip_equipment_r).toFixed(2),
                    total: parseFloat(total).toFixed(2),
                    currency: currentSelection?.currency
                }

            ]

            if (currentSelection?.wip_labour === null && currentSelection?.wip_parts === null && currentSelection?.wip_services === null && currentSelection?.wip_equipment === null) {
                setWipData([])
            } else { setWipData(data) }

            setData([currentSelection])
            setLoading(false)
        }
    }, [currentSelection])






    const tableOptions = {
        ...options,
        paging: false,
        toolbar: false,
        search: false,
        header: true

    };


    const column = [

        {
            title: "Labour",
            field: "labour",


            render: (rowData) => <div>
                {rowData?.labour === null ? <img src={CommercialIconRed} height={"16px"} /> : <div style={{ color: rowData?.labour > 100 ? "#FF0202" : "#4FC605" }}>{rowData?.labour} % </div>}

            </div>
        },
        {
            title: "Parts",
            field: "parts",

            render: (rowData) => <div>
                {rowData?.parts === null ? <img src={CommercialIconRed} height={"16px"} /> : <div style={{ color: rowData?.parts > 100 ? "#FF0202" : "#4FC605" }}>{rowData?.parts} %</div>}

            </div>
        },

        {
            title: "Services",
            field: "service",

            render: (rowData) => <div>
                {rowData?.service === null ? <img src={CommercialIconRed} height={"16px"} /> : <div style={{ color: rowData?.service > 100 ? "#FF0202" : "#4FC605" }}>{rowData?.service} %</div>}

            </div>
        }, {
            title: "Equipment",
            field: "equipment",

            render: (rowData) => <div>
                {rowData?.equipment === null ? <img src={CommercialIconRed} height={"16px"} /> : <div style={{ color: rowData?.equipment > 100 ? "#FF0202" : "#4FC605" }}>{rowData?.equipment} %</div>}

            </div>
        }, {
            title: "Overall",
            field: "overall",

            render: (rowData) => <div>
                {rowData?.overall === null ? <img src={CommercialIconRed} height={"16px"} /> : <div style={{ color: rowData?.overall > 100 ? "#FF0202" : "#4FC605" }}>{rowData?.overall} % </div>}

            </div>
        },
    ];
    const column1 = [

        {
            title: "Labour",
            field: "wip_labour",


        },
        {
            title: "Parts",
            field: "wip_parts",



        },

        {
            title: "Services",
            field: "wip_services",


        }, {
            title: "Equipment",
            field: "wip_equipment",


        }, {
            title: "Total",
            field: "total",


        },
        {
            title: "Currency",
            field: "currency",


        },
    ];

    return (
        <div>
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className={classes.modal}
            >
                <div className={classes.container}>
                    <Grid >
                        <Typography color="primary" style={{ paddingBottom: "10px" }}>Order </Typography>


                        <Box
                            display="flex"
                            alignItems="center"
                            //   justifyContent={(loading || _.isEmpty(partDetails)) && "center"}
                            bgcolor="#F5F5F5"
                            className={classes.partCard}
                        >
                            <Box flexGrow={1}>
                                <div style={{ width: "100%" }}>
                                    <GetInfoCard keyName={"Order #"} value={currentSelection?.order_number} />
                                    <GetInfoCard keyName={"Customer"} value={currentSelection?.customer} />
                                    <GetInfoCard keyName={"PO#"} value={currentSelection["cus-ref"]} />


                                </div>
                            </Box>
                        </Box>

                        <Typography color="primary" style={{ paddingTop: '10px', paddingBottom: "10px" }}>Approval Variance </Typography>

                        <Box
                            display="flex"
                            alignItems="center"
                            //   justifyContent={(loading || _.isEmpty(partDetails)) && "center"}
                            bgcolor="#F5F5F5"
                            className={classes.partCard}
                        >
                            <Box flexGrow={1}>
                                <div style={{ width: "100%" }}>
                                    <GetInfoCard keyName={"Approved Value"} value={currentSelection?.approved_value} />
                                    <GetInfoCard keyName={"Current Position"} value={currentSelection?.current_position} />
                                    <GetInfoCard keyName={"Variance"} value={currentSelection?.variance} />


                                </div>
                            </Box>
                        </Box>

                        <div style={{ paddingTop: '20px', paddingBottom: "10px" }}>


                            <MaterialTable
                                style={{ boxShadow: "0px 1px 3px #00000033", paddingRight: "0px" }}
                                icons={tableIcons}
                                title={" "}
                                isLoading={loading}
                                data={data || []}
                                columns={column}
                                options={tableOptions}
                            />
                        </div>

                        <Typography color="primary" style={{ paddingTop: '10px', paddingBottom: "10px" }}>WIP</Typography>
                        <div style={{ paddingTop: '10px', paddingBottom: "10px" }}>
                            <MaterialTable
                                style={{ boxShadow: "0px 1px 3px #00000033", paddingRight: "0px" }}
                                icons={tableIcons}
                                title={" "}
                                isLoading={loading}
                                data={wipData || []}
                                columns={column1}
                                options={tableOptions}
                            />
                        </div>
                    </Grid>
                </div>
            </Dialog>
        </div>
    )
}
