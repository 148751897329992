import {
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Grid,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import MROButton from "components/buttons";
import { makeStyles } from "@material-ui/core/styles";
import exclamationIcon from "assets/exclamation.png"
import WarningIcon from "@material-ui/icons/Warning";
import LoadingIndicator from "components/universal/loading_indicator";
import MROTextField from 'components/form_components/TextField';
import { ToastMessageContext } from "lib/contexts/message_context";
import { WorkOrderServices } from 'lib/services/api/operaitons/lineMaintenance/workOrder/workOrder';
import NumericIncrement from "views/Operations/LineMaintenance/WorkOrder/FieldComponents/numeric_increment";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        backgroundColor: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        margin: "1% 0",
        display: "flex",
        "& button": {
            marginRight: theme.spacing(2),
            width: "200px",
            "& .MuiButton-label": {
                justifyContent: "space-between",
                width: "150px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
            },
        },
    },
    input: {
        color: "#fff",
    },
    paper: {
        borderRadius: "5px",
        backgroundColor: "#fff",
        padding: theme.spacing(3),
        boxShadow: "0px 1px 4px #0000001A",
    },
    head: {
        marginTop: "20px",
        marginBottom: "10px",
    },
    error: {
        borderColor: "red",
    },
    svg: {
        filter: "invert(1)",
        height: 18,
        marginBottom: 2,
    },
    disableSwitch: {
        pointerEvents: "none",
    },
    disableField: {
        pointerEvents: "none",
        backgroundColor: "#F5F5F5",
    },
    message: {
        display: "flex",
        justifyContent: "flex-start",
        margin: theme.spacing(1)
    },
    modal: {
        "& .MuiDialog-paperWidthMd": {
            width: "670px",
        },
    },
    disableField: {
        pointerEvents: "none",
        backgroundColor: "#F5F5F5",
    },
}));


export default function Deassign(props) {
    const classes = useStyles();
    const { handleClose, currentSelection, getListing, action } = props
    const [loading, setLoading] = useState(false)
    const [acceptAll, setAcceptAll] = useState(false)
    const message = useContext(ToastMessageContext)
    const [errors, setErrors] = useState({})
    const [mroState, setMROState] = useState({ man_hours: '' });

    const handleSubmit = async (e) => {
        e.preventDefault()
        const formdata = new FormData(e.target)
        let valid = true;
        let error = {};


        for (let pair of formdata.entries()) {
            const value = pair[1];
            const key = pair[0];
            // console.table("formdata=====", key, value)
            if (!value) {
                error[key] = true;
                valid = false;
            }
        }

        if (!valid) {
            setErrors(error);
            return;
        }
        const reason = formdata.get("reason")
        const hours = formdata.get("man_hours")
        formdata.delete("reason")
        formdata.delete("man_hours")

        const params = {
            task_id: currentSelection?.id,
            reason: reason,
            man_hours: hours,
            act_hours: hours

        }

        setLoading(true)
        await WorkOrderServices.DeassignPlannedTask(params).then((res) => {
            if (res.success) {
                message.showToastMessage({
                    message: "Planned Task Deassigned successfully !",
                    variant: "success",
                });
                handleClose()
                getListing()
            }
            else {
                message.showToastMessage({
                    message: res?.errors ? res?.errors : "Something went wrong. Try again!",
                    variant: "error",
                });
                handleClose()
            }


        }).catch((res) => {
            handleClose()
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })
    }

    const handlePopUp = (e) => {
        handleClose()
        handleSubmit(e)
    }

    return (
        <div>
            <Dialog
                maxWidth={"md"}
                className={classes.modal}
                aria-labelledby="deassign"
                onClose={handleClose}
                open={true}
            >
                <DialogTitle id="customized-dialog-title">
                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                        <Box display="flex" alignItems="center">
                            <Typography
                                style={{
                                    fontSize: "18px",
                                }}
                                color="primary"
                            >
                                {action === "deassign" ? "Deassign" : "Reason"}
                            </Typography>
                        </Box>
                        {action === "deassign" && <Box display="flex" justifyContent="center">
                            {" "}
                            <img
                                src={exclamationIcon}
                                alt="Icon"
                            // style={{ height: "28px" }}
                            />{" "}
                        </Box>}
                    </Box>
                </DialogTitle>


                {action === "deassign" &&
                    <DialogContent >
                        <form onSubmit={handleSubmit} >

                            {/* 1content */}
                            <div className={classes.message} style={{ marginBottom: '5px' }}>
                                <WarningIcon
                                    style={{
                                        color: "#FFB300",
                                        marginRight: "10px",
                                        fontSize: 20,
                                    }}
                                />&nbsp;&nbsp;The task will remain listed, but be marked as not completed!
                            </div>
                            <div className={classes.message} style={{ marginBottom: '30px' }}>
                                <WarningIcon
                                    style={{
                                        color: "#FFB300",
                                        marginRight: "10px",
                                        fontSize: 20,
                                    }}
                                />&nbsp;&nbsp;Any Part Requests already added for this task must also be deleted directly on the Work Order
                            </div>
                            <Grid item xs={12}>

                                <MROTextField
                                    name={"reason"}
                                    label={"Reason *"}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ style: { height: 'max-content', width: "100%" } }}
                                    inputProps={{ maxLength: 100 }}
                                    placeholder={"Record the reason for deassignment"}
                                    error={errors.reason}
                                    multiline
                                    onChange={(e) => { setErrors({ ...errors, reason: false }); }}
                                    defaultValue={currentSelection?.reason}
                                />



                            </Grid>
                            <Grid xs={10}>


                                <NumericIncrement
                                    name="man_hours"
                                    label="Man Hours *"
                                    error={errors.man_hours}
                                    value={mroState.man_hours}
                                    placeholder={""}
                                    // onChange={handleChange}
                                    noIncreament
                                    state={mroState}
                                    setState={setMROState}
                                    inputProps={{ min: 0, step: 0.5 }}
                                    helperText={
                                        (errors["man_hours"] && mroState.man_hours <= 0) ? <span style={{ color: "red" }}>QTY Required Must Be Greater Than 0</span> : (errors["man_hours"] && mroState.man_hours === "") ? <span style={{ color: "red" }}>*Please Enter  Man Hrs</span> : ''
                                    }
                                    InputLabelProps={{ shrink: true }}
                                    setError={() =>
                                        setErrors((prevState) => ({
                                            ...prevState,
                                            man_hours: false,
                                        }))
                                    }

                                />
                            </Grid>

                            <Box
                                mt="auto"
                                display="flex"
                                justifyContent="center"
                                style={{ marginTop: "20px", marginBottom: "20px" }}
                            >
                                <MROButton
                                    style={{ marginRight: "20px" }}
                                    type="button"
                                    variant={"contained"}
                                    onClick={handleClose}
                                    buttonName="Cancel"
                                >
                                    Cancel
                                </MROButton>
                                <MROButton
                                    type="submit"
                                    variant={"contained"}
                                    color={"primary"}
                                    loading={loading}
                                >
                                    Complete
                                </MROButton>
                            </Box>
                        </form>
                    </DialogContent>}

                {action === "reason" && <DialogContent style={{ width: "670px", marginBottom: "20px" }}>

                    <Grid item xs={12}>

                        <MROTextField
                            name={"reason"}
                            label={"Reason *"}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ style: { height: 'max-content', width: "100%" }, className: classes.disableField, }}
                            inputProps={{ maxLength: 100 }}
                            placeholder={"Record the reason for deassignment"}
                            error={errors.reason}
                            defaultValue={currentSelection?.reason}
                            multiline
                            onChange={(e) => { setErrors({ ...errors, reason: false }); }}

                        />



                    </Grid>


                </DialogContent>}

            </Dialog>

        </div>
    )
}
