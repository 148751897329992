import { Grid, InputBase, Typography, InputAdornment, IconButton, makeStyles, TextField, FormControlLabel, useTheme, MenuItem, FormControl, Select } from '@material-ui/core'
import React, { useState, useReducer, useEffect, useContext } from 'react'
import SearchIcon from "@material-ui/icons/Search";
import { ICON_COLOUR } from 'lib/constants/style_constants';
import MROTextField from 'components/form_components/TextField';
import PartIcon from 'assets/PartIcon.png'
import DescriptionIcon from 'assets/Description.png'
import PositiveSwitch from 'components/form_components/switch';
import MaterialTable from 'material-table';
import tableIcons, { options } from 'components/universal/table_attributes';
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import TableAction from 'actions/table_actions';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import HistoricalJobs from './HistoricalJobs';
import useDebounce from 'lib/utils/debounce';
import BoxpTableAction from "actions/boxp_table_actions";
import { formatToDateOnly, getOrganisationId } from "lib/utils/common_utils";
import { ToastMessageContext } from 'lib/contexts/message_context';
import { MaintenanceServices } from 'lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation';
import ViewIcon from "@material-ui/icons/Visibility";
import TechFilesPopup from './TechFilesPopup';
import GreenCopyIcon from "assets/GreenCopyIcon.svg"
import { WorkshopContext } from 'views/Operations/Workshop/WorkshopContext/WorkshopContext';
import Stages from './Stages';
import CopyActions from './CopyActions';
import { Autocomplete } from '@material-ui/lab';




const useStyle = makeStyles((theme) => ({

    root: {
        margin: '2%'
    },
    wrapper: {
        backgroundColor: "#fff",
        padding: theme.spacing(1, 2),
        border: "1px solid #d5d5d5",
        margin: "1% 0",
    },
    divider: {
        margin: '8px 0'
    },
    filters: {
        padding: "10px 0",
        '& .filter': {
            paddingRight: theme.spacing(3),
        }
    }

}))

function History(props) {
    const { currentWorkshopDetails, tabId, tabname, copyTab, projectParts } = props
    const [searchText, setSearchText] = useState("");
    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const [action, setAction] = useState('')
    const [partNumber, setPartNumber] = useState('')
    const [description, setDescription] = useState('')
    const [showOnWorkOrders, setShowOnWorkorders] = useState(false)
    const debouncedVal = useDebounce(searchText);
    const message = useContext(ToastMessageContext);
    const [selectedRow, setSelectedRow] = useState('')
    const [actionData, setActionData] = useState([])
    const [actionDetails, setActionDetails] = useState(false)
    const [currentSelectedRow, setCurrentSelectedRow] = useState({})
    const [isTechFiles, setIsTechFiles] = useState(false)
    const [currentSelection, setCurrentSelection] = useState({})
    const { copiedJobs } = useContext(WorkshopContext)
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [totalCount, setTotalCount] = useState(10)
    const [loading, setLoading] = useState(false)
    const theme = useTheme()
    const classes = useStyle()
    const [filters, setFilters] = useState({ order_type: tabId === 20 || tabId === 24 ? copyTab === "normal" ? "Maintenance" : "Maintenance Template" : tabId === 21 || tabId === 25 ? copyTab === "normal" ? "Production" : "Production Template" : '', action_type: tabname === "Inspection" ? "Inspection" : tabname === "Maintenance" ? "Maintenance" : tabname === "Production" ? "Production" : undefined });
    const [project, setProject] = useState(null)
    const [errors, setErrors] = useState({})
    useEffect(() => {
        if (currentWorkshopDetails) {
            setPartNumber(currentWorkshopDetails?.part_number)
            setDescription(currentWorkshopDetails?.description)
        }

    }, [currentWorkshopDetails])

    console.log(currentWorkshopDetails)
    //Handle part copy
    const handlePartCopy = () => {
        setPage(0);
        setSearchText(partNumber)

    }
    const handleDescriptionCopy = () => {
        setPage(0);
        setSearchText(description)

    }
    const handleSearch = (value) => {

        setPage(0);

        setSearchText(value)


    }
    const clearSearch = () => {
        handleSearch('')
    }




    const getActionList = async () => {
        setActionDetails(true)
        const params = {
            // workshop_order_id: currentWorkshopDetails.id,
            page: page + 1,
            limit: pageSize,
            part_desc_search: debouncedVal,
            organisation_id: getOrganisationId(),
            ...filters
        }
        await MaintenanceServices.getCopyInspectionHistory(params).then((response) => {

            setActionData(response.data)

            setTotalCount(response.total_count);
        }).catch((err) => {
            message.showToastMessage({
                message: "Something went wrong. Try again",
                variant: "error",
            })
        }).finally(() => {
            setActionDetails(false)
        })
    }

    useEffect(() => {

        getActionList()


    }, [currentWorkshopDetails, page, pageSize, totalCount, debouncedVal, filters,])

    // useEffect(() => {

    //     getMaintenanceOrderList();

    // }, [page, pageSize, debouncedVal, showOnWorkOrders]);

    // const getMaintenanceOrderList = () => {
    //     const params = {
    //         page: page + 1,
    //         limit: pageSize,
    //         order_type: tabId === 21 || tabId === 25
    //             ? "Production"
    //             : tabId === 23
    //                 ? "Storage"
    //                 : "Maintenance",
    //         organisation_id: getOrganisationId(),
    //         part_desc_search: debouncedVal,
    //         show_in_work_orders: showOnWorkOrders,
    //         action_type: tabname


    //     };

    //     BoxpTableAction.getList(dispatch, message, params, "OrderList");
    // };


    const tableOptions = {
        ...options,
        page: page,
        total: totalCount,
        pageSize: pageSize,
        search: false,
        toolbar: false,
        // rowStyle: { height: 42 },
    };
    const column = [
        {
            title: 'Title',
            field: 'title',
            width: '25%',
            headerStyle: {

                textAlign: 'left',
                paddingLeft: '15px',
                width: '25%',
            },
            cellStyle: {

                textAlign: 'left',
                paddingLeft: '15px',
                width: '25%',
            }
        },
        {
            title: 'Order #',
            field: 'order_number',
            headerStyle: {

                textAlign: 'left'
            },
            cellStyle: {

                textAlign: 'left'
            }
        },

        {

            title: "Action #",
            field: "action_number",
        },
        {

            title: "Part #",
            field: "part_number",
            headerStyle: {

                textAlign: 'left',
            },
            cellStyle: {

                textAlign: 'left'
            }
        },
        {

            title: "Description",
            field: "item_description",
            headerStyle: {
                textAlign: 'left',
            },
            cellStyle: {
                textAlign: 'left'
            }
        },
        {
            title: "Detail",
            field: "maintenance_file",
            render: (rowData) => (
                <div>
                    <IconButton>
                        <ViewIcon fontSize="small" style={{ color: ICON_COLOUR }} onClick={() => {
                            setIsTechFiles(true)
                            setAction("view_files")
                            setCurrentSelectedRow(rowData)
                        }}
                        />
                    </IconButton>

                </div>

            )

        },
        {
            title: "Stages",
            field: "stages",
            render: (rowData) => (
                <div>
                    <a style={{ textDecoration: "underline" }} onClick={() => {
                        setAction("view_stages")
                        setCurrentSelection(rowData)
                    }}>{rowData?.stages}</a>
                </div>
            )
        },
        {
            title: 'Parts',
            field: 'parts',

        },
        {
            title: 'Service',
            field: 'services',

        },
        {
            title: 'Equipment',
            field: 'equipment',

        },

        {
            title: 'Copy',
            field: 'view',
            render: (rowData) => <>
                {copiedJobs.find((item) => item === rowData.workshop_action_id) ? <IconButton
                >
                    <img src={GreenCopyIcon} height="25px" style={{ pointerEvents: copiedJobs.find((item) => item === rowData.workshop_action_id) ? "none" : "", }} />
                </IconButton> :
                    <IconButton onClick={() => {
                        if ((project === null || project === undefined) && currentWorkshopDetails?.project_part) {
                            setErrors((err) => ({ ...err, project_part_id: true }))
                        }
                        else {

                            setAction('copy')
                            setCurrentSelection(rowData)
                        }
                    }}>
                        <ArrowForwardIcon color={ICON_COLOUR} />
                    </IconButton>}

            </>
        }

    ]



    return (
        <div className={classes.root}>
            <Grid >

                {currentWorkshopDetails?.project_part &&
                    <>

                        <Grid item xs={12}>
                            <Typography color="primary">Project Part</Typography>
                        </Grid>


                        <Grid item xs={3}>


                            <Autocomplete
                                fullWidth
                                getOptionSelected={(option, value) => option.id === value.id}
                                filterSelectedOptions={true}
                                onChange={(e, value) => {

                                    setProject(value)
                                    setErrors({ ...errors, project_part_id: false })
                                }}
                                id="tags-user"
                                options={projectParts || []}
                                getOptionLabel={(option) => option.part_number === "N/A" ? option.part_number : `${option.part_number} - ${option.serial}` || ""}

                                renderInput={(params) => (
                                    <MROTextField
                                        {...params}
                                        // variant="standard"
                                        label="Project Part *"
                                        name="project_part_id"
                                        color={"primary"}
                                        InputLabelProps={{ shrink: true }}
                                        placeholder={"Search & Select"}
                                        error={errors.project_part_id}
                                        helperText={errors.project_part_id ? "Please select Project Part" : ""}
                                    />
                                )}
                            />


                        </Grid> </>}

                <Grid item xs={12}>
                    <Typography color="primary">Historical Actions</Typography>
                </Grid>

                <div className={classes.wrapper}>

                    <MROTextField
                        fullWidth
                        placeholder={"   Search anything"}
                        onChange={(e) => handleSearch(e.target.value)}
                        value={searchText}
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{

                            startAdornment: <InputAdornment position="start" >
                                <SearchIcon style={{ fontSize: '16px', color: theme.palette.primary.main, marginRight: '10px' }} />
                            </InputAdornment>,

                            endAdornment: <>
                                <InputAdornment position="end" onClick={() => { handlePartCopy() }} style={{ cursor: 'pointer' }}>
                                    <img src={PartIcon} height={'22px'} />
                                </InputAdornment>
                                <InputAdornment position="end" onClick={() => { handleDescriptionCopy() }} style={{ cursor: 'pointer' }}>
                                    <img src={DescriptionIcon} height={'22px'} />
                                </InputAdornment>
                            </>
                        }}

                    />
                    <div className={classes.filters}>
                        <Typography variant="subtitle2" color="primary" gutterBottom>Filters</Typography>

                    </div>
                    <FormControl className={"filter"}>
                        <Select
                            // defaultValue={tabId === 20 ? "Maintenance" : tabId === 21 || tabId === 25 ? "Production" : undefined}
                            value={filters?.order_type}
                            displayEmpty
                            onChange={(e) => {
                                setPage(0)
                                setFilters((prevState) => ({
                                    ...prevState,
                                    order_type: e.target.value,
                                }))

                            }
                            }
                            input={<InputBase />}
                        >
                            {tabId === 20 && <MenuItem value={"Maintenance"}>Maintenance</MenuItem>}
                            {tabId === 20 && <MenuItem value={"Maintenance Template"}>Maintenance Template</MenuItem>}
                            {tabId === 24 && <MenuItem value={"Maintenance"}>Maintenance</MenuItem>}
                            {tabId === 24 && <MenuItem value={"Maintenance Template"}>Maintenance Template</MenuItem>}
                            {tabId === 21 && <MenuItem value={"Production"}> Production</MenuItem>}
                            {tabId === 21 && <MenuItem value={"Production Template"}> Production Template</MenuItem>}
                            {tabId === 25 && <MenuItem value={"Production"}> Production</MenuItem>}
                            {tabId === 25 && <MenuItem value={"Production Template"}> Production Template</MenuItem>}
                        </Select>
                    </FormControl>
                    {/* <FormControl className={"filter"}>
                        <Select
                            defaultValue={tabname === "Inspection" ? "Inspection" : tabname === "Maintenance" ? "Maintenance" : tabname === "Production" ? "Production" : undefined}
                            displayEmpty
                            onChange={(e) => {
                                setPage(0)
                                setFilters((prevState) => ({
                                    ...prevState,
                                    action_type: e.target.value,
                                }))

                            }
                            }
                            input={<InputBase />}
                        >

                            {tabname === "Inspection" && <MenuItem value={"Inspection"}>Inspection</MenuItem>}
                            {tabname === "Maintenance" && <MenuItem value={"Maintenance"}> Maintenance</MenuItem>}
                            {tabname === "Production" && <MenuItem value={"Production"}> Production</MenuItem>}
                        </Select>
                    </FormControl> */}


                </div>
                <br />
                <Grid xs={12}>
                    <MaterialTable
                        style={{
                            boxShadow: "0px 1px 3px #00000033",
                            marginTop: "10px",
                        }}
                        icons={tableIcons}
                        classes
                        title={""}
                        columns={column}
                        data={actionData}
                        isLoading={actionDetails}
                        options={tableOptions}
                        onChangePage={(page) => {
                            setPage(page);
                        }}
                        onChangeRowsPerPage={(pageSize) => {
                            setPageSize(pageSize);
                        }}
                        totalCount={totalCount}
                        page={page}
                    />

                </Grid>
            </Grid>
            {/* {action && action === 'history_jobs' &&
                <HistoricalJobs handleClose={() => setAction('')} selectedRow={selectedRow} currentWorkshopDetails={currentWorkshopDetails} tabname={tabname} />


            } */}

            {
                action === 'view_files' && (
                    <TechFilesPopup
                        handleClose={() => setAction(null)}
                        currentSelectedRow={currentSelectedRow}
                        open={isTechFiles}
                    />
                )
            }
            {
                action === 'view_stages' && (
                    <Stages
                        handleClose={() => setAction(null)}
                        currentSelection={currentSelection}
                    />

                )

            }
            {action === 'copy' && (
                <CopyActions handleClose={() => setAction(null)}
                    currentSelection={currentSelection}
                    currentWorkshopDetails={currentWorkshopDetails}
                    currentClosedDetails={selectedRow}
                    orderType={filters?.order_type}
                    projectPartId={project}
                />

            )
            }
        </div>
    )
}

export default History