import React, { useContext, useEffect, useReducer, useState } from "react";
import Dialog from "@material-ui/core/Dialog";

import {
    createStyles,
    Grid,
    IconButton,
    makeStyles,
    Typography,

} from "@material-ui/core";
import tableIcons, { options } from "components/universal/table_attributes";
import MaterialTable from "material-table";
import { ToastMessageContext } from "lib/contexts/message_context";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import AddIconBox from "@material-ui/icons/AddBox";
import { getOrganisationId } from "lib/utils/common_utils";
import { Close, Edit, Save, } from "@material-ui/icons";
import { ICON_COLOUR } from "lib/constants/style_constants";
import SearchCapability from "./SearchCapability";
import MROTextField from "components/form_components/TextField";


const useStyles = makeStyles((theme) =>
    createStyles({
        modal: {
            "& .MuiDialog-paperWidthSm": {
                minWidth: "1065px",
                // height: "110vh"
            },
        },
        container: {
            // minWidth: "1200px",
            // minHeight: "400px",
            padding: "73px 50px",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            overflow: "scroll"
        },

        popupTitle: {
            fontSize: "20px",
            lineHeight: "28px",
            position: "absolute",
            top: "19px",
            bottom: "15px",
            left: "25px",
        },
        divider: {
            borderBottom: `1px solid ${theme.palette.primary.main}`,
        },
        tableTitle: {
            fontSize: "16px",
            lineHeight: "22px",
            color: "#4D4F5C",
            fontWeight: "bold",
            marginTop: "19px",
            marginBottom: "16px",
        },
        tableCell: {
            height: '32px',
            justifyContent: "space-between",
            whiteSpace: "normal",
            wordWrap: "break-word",

        },
    })
);

export default function UpdateProjectParts(props) {
    const { handleClose, currentSelection, getPartDetails, projectParts, setCurrentSelectedData, id, primeId, workshopDetails, disabled, orderStateNum } = props
    const classes = useStyles();
    const message = useContext(ToastMessageContext);
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [action, setAction] = useState(null)
    const [rowDetails, setRowDetails] = useState(null)

    const tableOptions = {
        ...options,
        paging: false,
        search: false,
        toolbar: false


    };
    useEffect(() => {
        if (currentSelection !== undefined) {
            console.log(currentSelection)
            if (currentSelection?.serial_array.length > 0) {
                setData(currentSelection?.serial_array)
            }
            else {
                setData([])
            }

        }
    }, [currentSelection])



    useEffect(() => {
        const value = projectParts.find((item) => item.primary_part_id === currentSelection.primary_part_id)
        // setCurrentSelectedData(value)
        setData(value?.serial_array)


    }, [projectParts])




    const handleDelete = (newData, oldData) => {

        MaintenanceServices.deletePlanningProjectParts({ project_part_id: newData.id }).then((res) => {
            if (res.success) {
                message.showToastMessage({
                    message: "Deleted Successfully !",
                    variant: "success",
                });
                getPartDetails()
            }
            else {
                message.showToastMessage({
                    message: res?.error ? res?.error : "Something went wrong. Try again!",
                    variant: "error",
                });
            }

        }).catch((res) => {
            message.showToastMessage({
                message: res?.error ? res?.error : "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })


    }

    const handleAdd = (newData, oldData) => {
        const formData = new FormData()

        formData.append("reportable_type", "WorkshopOrder");
        formData.append("primary_part_id", currentSelection?.primary_part_id);
        formData.append("organisation_id", getOrganisationId());
        formData.append("reportable_id", id);
        formData.append("project_primary_part_id", primeId?.id);
        formData.append("quantity", 1);
        setLoading(true);
        MaintenanceServices.createProjectParts(formData)
            .then((res) => {
                if (res?.success) {

                    setLoading(false);
                    message.showToastMessage({
                        message: "Project Parts Added Successfully",
                        variant: "success",
                    });
                    getPartDetails();

                }

                else {

                    setLoading(false);
                    message.showToastMessage({
                        message: res?.error ? res?.error : "Something went wrong. Try again!",
                        variant: "error",
                    });
                    // getList();
                    handleClose();
                }



            })
            .catch((res) => {

                message.showToastMessage({
                    message: res?.error ? res?.error : "Something went wrong. Try again!",
                    variant: "error",
                });
            }).finally(() => {
                setLoading(false)
            })
    }


    const dataFromParent = (referData, id) => {

        const params = {
            quantity: rowDetails.quantity,
            serial: rowDetails.serial,
            project_part_id: rowDetails.id,
            certification_remarks: rowDetails.certification_remarks,
            capability_id: id
        }
        handleSave(params)

    };

    const handleUpdate = (newData, oldData) => {

        const params = {
            quantity: newData.quantity,
            serial: newData.serial,
            project_part_id: newData.id,
            certification_remarks: newData.certification_remarks,
            capability_id: newData.capability_id
        }
        handleSave(params)

    }

    const handleSave = (params) => {
        setLoading(true)
        MaintenanceServices.updatePLanningProjectParts(params).then((res) => {
            if (res?.success) {
                getPartDetails()
                message.showToastMessage({
                    message: "Updated Successfully !",
                    variant: "success",
                });
            }
            else {
                message.showToastMessage({
                    message: res?.error ? res?.error : "Something went wrong. Try again!",
                    variant: "error",
                });
            }
        }).catch((res) => {
            message.showToastMessage({
                message: res?.error ? res?.error : "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })
    }

    const column = [

        {
            title: "Part #",
            field: "part_number",
            width: '10%',
            editable: "never",
            headerStyle: {
                textAlign: "left",
                paddingLeft: "15px",
                width: '10%',
            },
            cellStyle: {
                textAlign: "left",
                paddingLeft: "15px",
                width: '10%',
            },
        },
        {
            title: "Serial #",
            field: "serial",

            editComponent: props => (

                <MROTextField
                    variant="standard"
                    value={props.value}
                    onChange={e => props.onChange(e.target.value)}
                    inputProps={{ maxLength: 30 }}

                />

            )
        },
        {
            title: "CRS Remarks *",
            field: "certification_remarks",

            width: '50%',
            headerStyle: {
                textAlign: "left",

                width: '50%'
            },
            cellStyle: {
                textAlign: "left",
                width: '50%'

            },
            editComponent: props => (

                <MROTextField
                    variant="standard"
                    value={props.value}
                    onChange={e => props.onChange(e.target.value)}
                    inputProps={{ maxLength: 500 }}
                    multiline
                />

            )
        },
        {
            title: "CAP Ref *",
            field: "reference_data",
            render: (rowData) => rowData?.reference_data || "-",
            editComponent: (props) =>
                <a style={{ textDecoration: "underline" }} onClick={() => {
                    setAction("searchCapability")
                    setRowDetails(props?.rowData)
                }}>{props?.rowData?.reference_data || "-"}</a>
        },

        {
            title: "Quantity",
            field: "quantity",
            editable: "never",
        },

    ];

    return (
        <div>
            <Dialog
                open={true}
                // onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className={classes.modal}
            >
                <div className={classes.container}>



                    <Grid container xs={12}>
                        <Grid item xs={11}>
                            <Typography
                                variant="body1"
                                color="primary"
                                style={{ marginBottom: "20px", marginLeft: "27px" }}
                                className={classes.popupTitle}
                            >
                                Project Parts
                            </Typography></Grid>
                        <Grid item xs={1} style={{ marginTop: "-52px", cursor: "pointer", textAlignLast: "right" }}>

                            <Close fontSize="medium" onClick={() => handleClose()} />
                        </Grid>
                    </Grid>


                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                        <MaterialTable
                            style={{
                                boxShadow: "0px 1px 3px #00000033",
                                borderRadius: "4px",
                                paddingBottom: "50px"
                                // minWidth: "500px"
                            }}

                            options={tableOptions}
                            title=""
                            icons={tableIcons}
                            columns={column}
                            data={data || []}
                            isLoading={loading}

                            editable={
                                {

                                    isDeleteHidden: (rowData) => orderStateNum > 2 || disabled,
                                    isEditHidden: (rowData) => orderStateNum > 2 || disabled,
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            handleUpdate(newData, oldData)
                                            resolve();
                                        }),
                                    onRowDelete: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            handleDelete(newData, oldData)
                                            resolve();
                                        }),
                                }
                            }

                        />
                    </Grid>
                    <div style={{ marginTop: "-30px", marginLeft: "13px", position: "relative" }}><i>* Only add customised ‘CRS Remarks’ and ‘Capability Reference’ if they <u>differ</u> from those planned against the general Workshop Order </i> </div>
                </div>
            </Dialog>
            {
                action === "searchCapability" && (
                    <SearchCapability
                        handleClose={() => setAction(null)}
                        func={dataFromParent}
                    />
                )
            }
        </div>
    )
}
