import { Box, IconButton, Menu, MenuItem } from '@material-ui/core';
import MROButton from 'components/buttons';
import React, { useState, useReducer, useContext } from 'react'
import AddIcon from "@material-ui/icons/Add";
import MaterialTable from 'material-table';
import tableIcons, {
    options,
} from "components/universal/table_attributes";
import { ToastMessageContext } from "lib/contexts/message_context";
import TableAction from "actions/table_actions";
import { WorkOrderServices } from 'lib/services/api/operaitons/lineMaintenance/workOrder/workOrder';
import { useEffect } from 'react';
import { getOrganisationId } from 'lib/utils/common_utils';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import RedCrossIcon from 'assets/Red remove icon.png'
import GreenTickIcon from 'assets/green check.png'
import GearAmbear from 'assets/Amber gear@3x.png'
import Deassign from './PlannedMainTask.js/Deassign';
import ViewRecords from './PlannedMainTask.js/ViewRecords';
import EditTask from './PlannedMainTask.js/EditTask';

function PlannedTaskDetails(props) {
    const [action, setAction] = useState('')
    const { workOrderId, wo_details } = props
    const [totalCount, setTotalCount] = useState(5)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const message = useContext(ToastMessageContext)
    const [currentSelection, setCurrentSelection] = useState({});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(5)
    const [loadingDelete, setloadingDelete] = useState(false)


    const tableOptions = {
        ...options,
        page: page,
        pageSize: pageSize,
        totalCount: totalCount,
        actionsColumnIndex: 6,
        search: false,
    };
    const handleClick = (event) => {
        setOpen(true);
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setOpen(false);
        setAction(null);
        setCurrentSelection({});
    };
    const options1 = ["Edit", "Deassign", "View Records"];
    const ITEM_HEIGHT = 48;





    const columns = [
        {
            title: "Status",
            field: "pt_status",

            headerStyle: {
                textAlign: "center",

            },
            cellStyle: {
                textAlign: "center",


            },
            render: rowData => (
                <img src={rowData?.pt_status === 1 ? GreenTickIcon : rowData?.pt_status === 2 ? RedCrossIcon : rowData?.pt_status === 0 ? GearAmbear : ''} height={'18px'} />
            ),

        },
        {
            title: "Task #",
            field: "task",
            headerStyle: {
                textAlign: "left",
            },
            cellStyle: {
                textAlign: "left",

            },

        },
        {
            title: "ATA",
            field: "ata",
            headerStyle: {
                textAlign: "left",
            },
            cellStyle: {
                textAlign: "left",

            },


        },
        {
            title: "Description",
            field: "description",
            width: "50%",
            headerStyle: {
                textAlign: "left",
                width: "50%"
            },
            cellStyle: {
                textAlign: "left",
                width: "50%"
            },

        },
        {
            title: "Act M/Hrs",
            field: "act_hrs",

        }, {
            title: "Engineer",
            field: "engineer",

        },

        {
            title: "Actions",
            field: "",
            render: (rowData) => (
                <div id={`div-${rowData.id}`}>
                    <IconButton
                        aria-label="more"
                        aria-controls={`long-menu-${rowData.id}`}
                        aria-haspopup="true"
                        onClick={(e) => {
                            setCurrentSelection(rowData);
                            handleClick(e);
                        }}
                    >
                        <MoreVertIcon />

                    </IconButton>
                </div>
            )
        },

    ]


    const getPlannedDetails = async () => {
        setLoading(true)
        const params = {
            organisation_id: getOrganisationId(),
            id: workOrderId,
            page: page + 1,
            count_per_page: pageSize

        }
        await WorkOrderServices.getMaintPlannedTableDetails(params).then((response) => {
            setData(response.data)
            setTotalCount(response.total_count)

        }).catch((response) => {
            message.showToastMessage({
                message: response?.message,
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)

        })
    }
    useEffect(() => {
        getPlannedDetails()
    }, [page, pageSize])


    const handleUndoTask = () => {

        const params = {
            task_id: currentSelection?.id,


        }

        setLoading(true)
        WorkOrderServices.UndoPlannedTask(params).then((res) => {
            if (res.success) {
                message.showToastMessage({
                    message: "Planned Task updated successfully !",
                    variant: "success",
                });
                setOpen(false)
                getPlannedDetails()
            }
            else {
                message.showToastMessage({
                    message: res?.errors ? res?.errors : "Something went wrong. Try again!",
                    variant: "error",
                });
                setOpen(false)
            }


        }).catch((res) => {
            setOpen(false)
            message.showToastMessage({
                message: "Something went wrong. Try again!",
                variant: "error",
            });
        }).finally(() => {
            setLoading(false)
        })


    }

    return (
        <div>


            <Box>
                <MaterialTable style={{ boxShadow: "0px 1px 3px #00000033" }}
                    icons={tableIcons}
                    classes
                    title={""}
                    columns={columns}
                    isLoading={loading}
                    data={data ?? []}
                    options={tableOptions}

                    onChangePage={(page) => {
                        setPage(page)
                    }}
                    onChangeRowsPerPage={(pageSize) => {
                        setPageSize(pageSize)
                    }}
                    totalCount={totalCount}
                    page={page}


                />
            </Box>
            <br />
            {open && <Menu
                id={`long-menu`}
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleCloseMenu}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                    },
                }}
            >
                {currentSelection &&

                    <> {currentSelection?.pt_status !== 2 &&
                        <MenuItem
                            // disabled={!props.permission.write && option !== "U/S label"}
                            key={"Edit"}
                            style={{ fontSize: "12px" }}
                            onClick={() => {
                                setAction("edit")
                            }}
                        >
                            Edit
                        </MenuItem>}
                        {currentSelection?.pt_status === 0 && <MenuItem
                            // disabled={!props.permission.write && option !== "U/S label"}
                            key={"Deassign"}
                            style={{ fontSize: "12px" }}
                            onClick={() => {
                                setAction("deassign");
                            }}
                        >
                            Deassign
                        </MenuItem>}
                        {currentSelection?.pt_status !== 2 && <MenuItem
                            // disabled={!props.permission.write && option !== "U/S label"}
                            key={"View Records"}
                            style={{ fontSize: "12px" }}
                            onClick={() => {
                                setAction("view_records");
                            }}
                        >
                            View Records
                        </MenuItem>}
                        {currentSelection?.pt_status === 2 && <MenuItem
                            // disabled={!props.permission.write && option !== "U/S label"}
                            key={"reason"}
                            style={{ fontSize: "12px" }}
                            onClick={() => {
                                setAction("reason");
                            }}
                        >Reason
                        </MenuItem>}
                        {currentSelection?.pt_status === 2 && wo_details?.status !== "Closed" && <MenuItem
                            // disabled={!props.permission.write && option !== "U/S label"}
                            key={"undo"}
                            style={{ fontSize: "12px" }}
                            onClick={() => {
                                handleUndoTask()
                            }}
                        >
                            Undo
                        </MenuItem>}
                    </>

                }



            </Menu>}
            {action && action === "deassign" && <Deassign
                currentSelection={currentSelection}
                handleClose={() => {
                    setAction('')
                    setOpen(false)
                }}
                getListing={getPlannedDetails}
                action={action}
            />}
            {action && action === "reason" && <Deassign
                currentSelection={currentSelection}
                handleClose={() => {
                    setAction('')
                    setOpen(false)
                }}
                action={action}
                getListing={getPlannedDetails}

            />}
            {action && action === "edit" && <EditTask
                currentSelection={currentSelection}
                handleClose={() => {
                    setAction('')
                    setOpen(false)
                }}
                getListing={getPlannedDetails}

            />}
            {action && action === "view_records" && <ViewRecords
                currentSelection={currentSelection}
                handleClose={() => {
                    setAction('')
                    setOpen(false)
                }}
                getListing={getPlannedDetails}

            />}

        </div>


    )
}

export default PlannedTaskDetails