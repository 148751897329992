import React, { useState, useEffect, useReducer, useMemo } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MROFullScreenRightDrawer from "../../../../components/fullwidth_dialog";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import { Box, Menu, MenuItem } from "@material-ui/core";
import { Edit as EditIcon, Visibility as ViewIcon } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";
import { ICON_COLOUR } from "../../../../lib/constants/style_constants";
import Grid from "@material-ui/core/Grid";
import tableIcons, {
  options,
} from "../../../../components/universal/table_attributes";
import tableReducer, {
  INITIAL_TABLE_STATE,
} from "../../../../reducers/table_reducer";
import MaterialTable from "material-table";
import TableAction from "../../../../actions/table_actions";
import MROButton from "../../../../components/buttons";
import StockInfoEditPopup from "./StockInfoEditPopup";
import PartsClientServices from "../../../../lib/services/api/stores/tooling/parts_client";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Fade from "@material-ui/core/Fade";
import TransferPartDialog from "./TransferPartDialog";
import EditDialog from "./EditDialog";
import CertificatePopup from "./CertificatePopup";
import TouchAppIcon from "@material-ui/icons/TouchApp";

import _ from "lodash";
import { format24HrDateOnly } from "lib/utils/common_utils";
import PartsCertificate from "./PartsCertificate";
import SplitDialog from "./SplitDialog";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  root: {
    backgroundColor: "#ffffff",
  },
  wrapper: {
    padding: "0 24px",
  },
  title: {
    fontSize: "18px"
  },
  mainTitle: {
    fontSize: "17px",
    paddingTop: "25px",
  },
  subTitle: {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "10px",
    marginTop: "20px",
    color: "#4D4F5C",
    "& button": {
      float: "right",
      minWidth: "90px",
      height: "30px",
      fontSize: "12px",
    },
  },
  partCard: {
    borderRadius: "8px",
    padding: "17px 24px",
    "& p": {
      fontSize: "14px",
      padding: "5px",
    },
  },
  partKey: {
    fontSize: "14px",
    // padding: "5px",
  },
  partValue: {
    fontSize: "14px",
    color: "#4D4F5C",
    padding: "5px",
    display: "flex",
    alignItems: "center",
  },
  alternatesInfo: {
    fontSize: "16px",
    color: "#4D4F5C",
    marginTop: "15px",
    display: "block",
  },
}));

const GetInfoCard = (props) => {
  const classes = useStyles();
  return (
    <Box display="flex">
      <Box flex={50} className={classes.partKey}>
        <Typography style={{ padding: "5px" }} color="primary">
          {props?.keyName}
        </Typography>
      </Box>
      <Box flex={50} className={classes.partValue}>
        {props?.value ?? "-"}
      </Box>
    </Box>
  );
};

const FadeMenu = (props) => {
  const { menuItems } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        className={classes.menu}
        style={{ width: "174px" }}
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {menuItems?.map((item, i) => (
          <MenuItem
            className={classes.menuItem}
            key={i}
            onClick={() => {
              handleClose();
              item.dialogOpenHandler();
            }}
          >
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

function StockInfo(props) {
  const classes = useStyles();
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);

  const [alternateTableState, setAlternateTableState] = useState({
    page: 0,
    totalCount: 5,
    pageSize: 10,
    isLoading: false,
  });
  const { page, totalCount, pageSize } = tableState;
  const [loading, setLoading] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isSplitPopupOpen, setIsSplitPopupOpen] = useState(false);
  const {
    action,
    currentSelection,
    handleClose,
    className,
    showSelectPartBtn,
    onSelectPartBtnClick,
    hideEdit,
    baseStations
  } = props;

  const [stockList, setStockList] = useState([]);
  const [alternateList, setAlternateList] = useState([]);
  const [isTransferPartDialogOpen, setIsTransferPartDialogOpen] =
    useState(false);

  const [isEditDialopOpen, setIsEditDialopOpen] = useState(false);

  const [isCertificatePopupOpen, setIsCertificatePopupOpen] = useState(false);

  const [partDetails, setPartDetails] = useState({});

  const [selectedSecondaryPartId, setSelectedSecondaryPartId] = useState("");

  const [isMsdsPopupOpen, setIsMsdsPopupOpen] = useState(false);

  //
  const [alternatesPartStack, setAlternatesPartStack] = useState(0);

  const [msds, setMsds] = useState([]);

  // id for fetching the right stock details and alternates part according to the current parts
  const [currentPartId, setCurrentPartId] = useState();

  // selected secondary part to be edited
  const [selectedRow, setSelectedRow] = useState({});

  const organisation = JSON.parse(sessionStorage.getItem("organisation"));

  const CURR_PART_ID = useMemo(() => partDetails.alternate_part_id ?? currentSelection.part_id ?? currentSelection.id, [partDetails.alternate_part_id, currentSelection.part_id, currentSelection.id])

  useEffect(() => {
    // if (partDetails.alternate_part_id) {
    //   setCurrentPartId(partDetails.alternate_part_id);
    // } else if(currentSelection.part_id) {
    //   setCurrentPartId(currentSelection.part_id);
    // } else {
    //   setCurrentPartId(currentSelection.id);
    // }
    setCurrentPartId(CURR_PART_ID)
  }, [CURR_PART_ID]);

  const getPartList = () => {
    const params = {
      id: currentPartId,
      page: 1 + page,
      count_per_page: pageSize,
    };

    setLoading(true);
    PartsClientServices.getAllSecondaryParts(params).then((res) => {
      if (res?.data) {
        setStockList(res?.data);
        setLoading(false);
        TableAction.setTotalCount(dispatch, res.total_count);
      } else {
        setStockList([]);
        setLoading(false);
      }
    });
  };
  const getAlternateList = () => {
    const params = {
      id: currentPartId,
      page: alternateTableState.page + 1,
      count_per_page: alternateTableState.pageSize,
    };
    setAlternateTableState((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });
    PartsClientServices.getAlternateParts(params)
      .then((res) => {
        if (res?.data) {
          setAlternateList(res?.data);

          setAlternateTableState((prevState) => {
            return {
              ...prevState,
              totalCount: res.total_count,
            };
          });
        } else {
          setAlternateList([]);
        }
      })
      .finally(() => {
        setAlternateTableState((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
  };

  useEffect(() => {
    setLoading(true);
    if (currentPartId) {
      getPartList();
    }
  }, [currentPartId, pageSize, page]);

  useEffect(() => {
    if (currentPartId) {
      getAlternateList();
    }
  }, [currentPartId, alternateTableState.pageSize, alternateTableState.page]);

  // fetching part info
  useEffect(() => {
    setLoading(true);
    if (currentPartId) {
      PartsClientServices.getPrimaryPartInfo(currentPartId)
        .then((response) => {
          response.data && setPartDetails(response.data);
          setMsds(response.data.mds);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  }, [currentPartId]);

  // Ui-table column config
  const stockColumnConfig = [
    {
      title: "QTY",
      field: "quantity",
      headerStyle: {
        paddingLeft: "19px",
        textAlign: "left",
      },
      cellStyle: {
        paddingLeft: "19px",
        textAlign: "left",
      },
      render: (rowData) => rowData?.quantity || "-",
    },
    {
      title: "State",
      field: "state",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        padding: "10px 5px",
      },
      render: (rowData) => rowData?.state || "-",
    },
    {
      title: "Base",
      field: "base_station",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        padding: "10px 5px",
      },
      render: (rowData) => rowData?.base_station || "-",
    },
    {
      title: "Owner",
      field: "owner",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        padding: "10px 5px",
      },
      render: (rowData) =>
        rowData.mro_owned ? organisation.company_name : rowData?.owner || "-",
    },
    {
      title: "",
      field: "mro_owned",
      headerStyle: {
        display: "none",
      },
      cellStyle: {
        display: "none",
      },
    },
    {
      title: "Batch #",
      field: "batch_number",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        padding: "10px 5px",
      },
      render: (rowData) => rowData?.batch_number || "-",
    },
    {
      title: "Serial #",
      field: "serial_number",
      width: "10%",
      render: (rowData) => rowData?.serial_number || "-",
    },
    {
      title: "Location",
      field: "store_location",
      render: (rowData) => rowData?.store_location || "-",
    },
    {
      title: "Shelf-life",
      field: "shelf_life_expiry",
      render: (rowData) => format24HrDateOnly(rowData?.shelf_life_expiry) || "-",
    },
    {
      title: "Action",
      field: "",
      render: (rowData) => {
        if (!hideEdit) {
          return (
            <FadeMenu
              menuItems={[

                !!props.permission?.write
                  ? {
                    name: "Edit",
                    dialogOpenHandler: () => {
                      // !!props.permission.write &&
                      setIsEditDialopOpen(true);
                      // !!props.permission.write &&
                      setSelectedRow(rowData);
                    },
                  }
                  : {},
                {
                  name: "Part Certificates",
                  dialogOpenHandler: () => {
                    setSelectedSecondaryPartId(rowData.secondary_part_id);
                    setIsCertificatePopupOpen(true);
                  },
                },
                !!props.permission?.write
                  ? {
                    name: "Split Batch",
                    dialogOpenHandler: () => {

                      setIsSplitPopupOpen(true);
                      setSelectedSecondaryPartId(rowData.secondary_part_id);
                      setSelectedRow(rowData);
                    },
                  }
                  : {},
                !!props.permission?.write
                  ? {
                    name: "Transfer Part",
                    dialogOpenHandler: () => {
                      !!props.permission.write &&
                        setIsTransferPartDialogOpen(true);
                      setSelectedSecondaryPartId(rowData.secondary_part_id);
                      setSelectedRow(rowData);
                    },
                  }
                  : {},

              ]}
            />
          );
        }
      },
    },
  ];

  // tableOptions
  const stockTableOptions = {
    ...options,
    page: page,
    total: totalCount,
    pageSize: pageSize,
    search: false,
    toolbar: false,
    headerStyle: {
      paddingTop: "22px",
      paddingBottom: "15px",
      textAlign: "center",
    },
  };

  // Ui-table column config
  const alternatesColumnConfig = [
    {
      title: "Part #",
      field: "part_number",
      width: "4%",
      headerStyle: {
        textAlign: "left",
        paddingLeft: "19px",
      },
      cellStyle: {
        textAlign: "left",
        paddingLeft: "19px",
      },
    },
    {
      title: "Description",
      field: "description",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        padding: "10px 5px",
      },
    },
    {
      title: "Stock",
      field: "total_stock",
    },
    {
      title: "Action",
      field: "id",
      render: (rowData) => (
        <ViewIcon
          style={{
            cursor: "pointer",
            pointerEvents: alternatesPartStack >= 3 && "none",
          }}
          onClick={() => {
            // setPartDetails(rowData);
            // setCurrentPartId(rowData.id)

            setCurrentPartId(rowData.alternate_part_id);
            setAlternatesPartStack((prevState) => prevState + 1);
          }}
          fontSize="small"
          htmlColor={ICON_COLOUR}
        />
      ),
    },
  ];

  // tableOptions
  const alternatesTableOptions = {
    ...options,
    ...alternateTableState,
    search: false,
    toolbar: false,
  };

  return (
    <MROFullScreenRightDrawer open={true} className={className}>
      <AppBar position="static" className={classes.appBar} elevation={0}>
        <Toolbar>
          <IconButton
            onClick={() => {
              alternatesPartStack === 0 && handleClose();
              // setCurrentPartId(currentSelection.part_id);
              setCurrentPartId(CURR_PART_ID);
              setAlternatesPartStack(0);
            }}
            edge="start"
            className={classes.backButton}
            color="inherit"
            aria-label="back"
          >
            <BackIcon />
          </IconButton>

          <Typography variant="subtitle2" className={classes.title}>
            {alternatesPartStack > 0 ? "Back to origin part" : "Stock"}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Typography
            variant={"subtitle1"}
            color={"primary"}
            className={classes.mainTitle}
          >
            Stock Information
          </Typography>
          <Typography variant="subtitle2" className={classes.subTitle}>
            Part Details
          </Typography>
          <div style={{ width: "100%", marginBottom: "20px" }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent={(loading || _.isEmpty(partDetails)) && "center"}
              bgcolor="#F5F5F5"
              className={classes.partCard}
            >
              {loading &&
                _.isEmpty(partDetails) &&
                alternatesPartStack === 0 ? (
                <CircularProgress color="primary" />
              ) : (
                <>
                  <Box flexGrow={1}>
                    <div style={{ width: "100%" }}>
                      <GetInfoCard
                        keyName={"Part Number"}
                        value={<strong>{partDetails?.part_number}</strong>}
                      />
                      <GetInfoCard
                        keyName={"Description"}
                        value={partDetails?.description}
                      />
                      <GetInfoCard
                        keyName={"Total Stock"}
                        value={<strong>{currentSelection?.total_stock}</strong>}
                      />
                    </div>
                  </Box>
                  <Box flexGrow={1}>
                    <div style={{ width: "100%" }}>
                      <GetInfoCard
                        keyName={"Unit Of Measure"}
                        value={partDetails?.units_of_measure}
                      />
                      <GetInfoCard
                        keyName={"Min QTY Issue"}
                        value={partDetails?.units_issued}
                      />
                      <GetInfoCard
                        keyName={"Shelf-life Control"}
                        value={partDetails?.shelf_life_control ? "Yes" : "No"}
                      />
                    </div>
                  </Box>
                  <Box flexGrow={1}>
                    <div style={{ width: "100%" }}>
                      <GetInfoCard keyName={"Unit Value"} value={partDetails?.cost} />
                      <GetInfoCard
                        keyName={"Value Date"}
                        value={partDetails?.cost_date}
                      />
                      <GetInfoCard
                        keyName={"Supplier"}
                        value={partDetails?.supplier}
                      />
                    </div>
                  </Box>
                  <Box flexGrow={1} alignSelf="flex-start">
                    <div style={{ width: "100%" }}>
                      <GetInfoCard
                        keyName={"MSDS"}
                        value={
                          <ViewIcon
                            onClick={() => setIsMsdsPopupOpen(true)}
                            style={{ cursor: "pointer" }}
                            fontSize="small"
                            htmlColor={ICON_COLOUR}
                          />
                        }
                      />

                      {showSelectPartBtn && (
                        <MROButton
                          style={{ marginTop: 20 }}
                          onClick={() => onSelectPartBtnClick(partDetails)}
                          startIcon={<TouchAppIcon />}
                          color="primary"
                          variant="contained"
                        >
                          Select Part
                        </MROButton>
                      )}
                    </div>
                  </Box>
                </>
              )}
            </Box>
          </div>

          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography variant="subtitle2" className={classes.subTitle}>
                Stock Details
              </Typography>
              <MaterialTable
                style={{ boxShadow: "0px 1px 3px #00000033" }}
                headerStyle={{ padding: "50px" }}
                icons={tableIcons}
                title={
                  <Typography variant="subtitle2">Min / Max Levels</Typography>
                }
                columns={stockColumnConfig}
                data={stockList}
                isLoading={loading}
                options={stockTableOptions}
                localization={{
                  toolbar: {
                    searchPlaceholder: "Search anything",
                  },
                  body: {
                    emptyDataSourceMessage: "No Stock Found",
                    filterRow: {
                      filterTooltip: "Filter",
                    },
                  },
                }}
                onChangePage={(page) => {
                  TableAction.setPage(dispatch, page);
                }}
                onChangeRowsPerPage={(pageSize) => {
                  TableAction.setPageSize(dispatch, pageSize);
                }}
                totalCount={totalCount}
                page={page}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2" className={classes.subTitle}>
                Alternates *{" "}
                {!!props.permission?.write
                  ? !hideEdit && (
                    <MROButton
                      variant="contained"
                      color="primary"
                      onClick={() => setIsEditPopupOpen(true)}
                      startIcon={<EditIcon />}
                    >
                      Edit
                    </MROButton>
                  )
                  : null}
              </Typography>
              <MaterialTable
                style={{ boxShadow: "0px 1px 3px #00000033" }}
                icons={tableIcons}
                columns={alternatesColumnConfig}
                data={alternateList}
                isLoading={alternateTableState.isLoading}
                options={alternatesTableOptions}
                localization={{
                  toolbar: {
                    searchPlaceholder: "Search anything",
                  },
                  body: {
                    emptyDataSourceMessage: "No Alternates Found",
                    filterRow: {
                      filterTooltip: "Filter",
                    },
                  },
                }}
                onChangePage={(page) => {
                  setAlternateTableState((prevState) => {
                    return { ...prevState, page: page };
                  });
                }}
                onChangeRowsPerPage={(pageSize) => {
                  setAlternateTableState((prevState) => {
                    return { ...prevState, pageSize: pageSize };
                  });
                }}
                totalCount={alternateTableState.totalCount}
                page={alternateTableState.page}
              />
              <i className={classes.alternatesInfo}>
                * Alternates must be verified IAW approved maintenance data!
              </i>
            </Grid>
          </Grid>
        </div>
      </div>
      {isEditPopupOpen && (
        <StockInfoEditPopup
          open={isEditPopupOpen}
          popupCloseHandler={() => {
            setIsEditPopupOpen(false);
            getAlternateList();
          }}
          selectedPrimaryPartId={currentPartId}
        />
      )}
      {isTransferPartDialogOpen && (
        <TransferPartDialog
          open={isTransferPartDialogOpen}
          secondaryPartId={selectedSecondaryPartId}
          dialogCloseHandler={() => setIsTransferPartDialogOpen(false)}
          getPartList={getPartList}
          batchNumber={selectedRow?.batch_number}
        />
      )}

      {isSplitPopupOpen && (
        <SplitDialog
          open={isSplitPopupOpen}
          secondaryPartId={selectedSecondaryPartId}
          dialogCloseHandler={() => setIsSplitPopupOpen(false)}
          getPartList={getPartList}
          batchNumber={selectedRow?.batch_number}
          quantitys={selectedRow?.quantity}
        />
      )}

      {isEditDialopOpen && (
        <EditDialog
          open={isEditDialopOpen}
          dialogCloseHandler={() => {
            setIsEditDialopOpen(false);
            getPartList();
          }}
          selectedSecondaryPart={selectedRow}
          partNumber={partDetails?.part_number}
          shelfLifeControl={partDetails?.shelf_life_control}
          oemShelfLife = {partDetails?.oem_shelf_life}
          baseStations={baseStations}
        />
      )}

      {/* {isCertificatePopupOpen && (
        <CertificatePopup
          open={isCertificatePopupOpen}
          dialogCloseHandler={() => setIsCertificatePopupOpen(false)}
          selectedSecondaryPart={selectedRow}
          selectedSecondaryPartId={selectedSecondaryPartId}
          title="Material Certificate"
        />
      )} */}
      {isCertificatePopupOpen && (
        <PartsCertificate
          open={isCertificatePopupOpen}
          dialogCloseHandler={() => setIsCertificatePopupOpen(false)}
          selectedSecondaryPart={selectedRow}
          selectedSecondaryPartId={selectedSecondaryPartId}
          title="Material Certificate"
          {...props}
        />
      )}

      {isMsdsPopupOpen && (
        <CertificatePopup
          open={isMsdsPopupOpen}

          dialogCloseHandler={() => setIsMsdsPopupOpen(false)}
          // selectedSecondaryPart={selectedRow}
          selectedSecondaryPartId={selectedSecondaryPartId}
          msds={msds}
        />
      )}
    </MROFullScreenRightDrawer>
  );
}

export default StockInfo;
