import { Box } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { useContext } from 'react'
import MROButton from '../../../../../components/buttons'
import UploadMultipleButton from '../../../../../components/form_components/upload_multiple_button'
import { ToastMessageContext } from '../../../../../lib/contexts/message_context'
import DataService from '../../../../../lib/services/api'
import FileUpload from 'components/form_components/fileUpload'

export const MultiUploadPreview = (props) => {

    const [files, setFiles] = useState([]);
    const message = useContext(ToastMessageContext);
    const [busy, setBusy] = useState(null)
    const handleChange = (e) => {
        console.log(e)
        setFiles(e);
        props.setFiles(e);
    }

    useEffect(() => {
        props.setOrgSectionId(props.org_section_id)
    }, [props.org_section_id, props])

    const handleUpload = () => {
        let formData = new FormData();

        formData.append('org_section_id', props.org_section_id)
        formData.append('id', props.id)
        // formData.append('documents[]', files[0][0])
        formData.append('operable_type', 'WorkOrder')
        files.length > 0 && files.forEach(item => formData.append(`documents[]`, item))

        DataService.createData(formData, 'uploadDocs').then(res => {
            props.getSectionDetails()
            message.showToastMessage({ message: 'Documnet uploaded successfully', variant: 'success' })
        }).catch(err => {
            message.showToastMessage({ message: 'Failed to upload documnet.', variant: 'error' })
        })
    }

    const handleRemoveFiles = (id) => {
        setBusy("file-deleting")
        DataService.deleteData(null, 'destroyDoc', { id: id })
            .then(res => {
                message.showToastMessage({ message: 'Documnet removed successfully', variant: 'success' })
                props.getSectionDetails()
            }).catch(err => {
                message.showToastMessage({ message: 'Failed to remove documnet.', variant: 'error' })
            }).finally(() => {
                setBusy(null)
            })
    }

    return (

        <>

            <FileUpload
                defaultValue={props?.file !== null && props?.file.length > 0 &&
                    props?.file.map((file) => ({
                        filename: file?.filename,
                        url: file?.file,
                        id: file?.id,
                    }))
                }
                uploadButtonView={false}
                setFiles={(files) => {
                    setFiles(files)
                    handleChange(files)
                }}
                showBorderOnNoFiles={true}
                accept={"video"}
                // name="internal_files[]"
                multiple={true}
                APIUrl={""}
                action={"Upload File"}
                onDelete={!props.permission.write ? null : handleRemoveFiles}
                isDeleting={busy === "file-deleting"}
                viewOnly={!props.permission.write}
                width={"56vw"}
            />



            {/* <UploadMultipleButton {...props} handleImageChange={handleChange} multiple
                defualtValue={props.file}
                handleRemove={handleRemoveFiles}
            />
            <Box>
                {!!files.length && <MROButton disabled={!props?.permission?.write } onClick={handleUpload}>
                    Upload File
                </MROButton>}
            </Box> */}
        </>
    )
}

export default React.memo(MultiUploadPreview)
