import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  IconButton,
  Box,
  FormControl,
  Select,
  InputBase,
  MenuItem,
  Button,
  Menu,
  useTheme,
  Tooltip,
  Paper,
} from "@material-ui/core";
import React, { useContext, useEffect, useReducer, useState, useMemo } from "react";
import tableReducer from "reducers/table_reducer";
import tableIcons, { options } from "components/universal/table_attributes";
import TableAction from "actions/table_actions";
import clxc from "clsx";
import { ICON_COLOUR } from "lib/constants/style_constants";
import MaterialTable from "material-table";
import { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import {
  Visibility as ViewIcon,
  Create as CreateIcon,
  Edit as EditIcon,
} from "@material-ui/icons";
import MROButton from "components/buttons";
import SearchBarWithFilters from "components/search_with_filters";
import ApprovalManage from "./ApprovalManage";
import DetailedOverview from "./DetailedOverview";
import VisibilityIcon from "@material-ui/icons/Visibility";
import greenCheckIcon from "assets/green check.png";
import { ToastMessageContext } from "lib/contexts/message_context";
import BoxpTableAction from "actions/boxp_table_actions";
import useDebounce from "lib/utils/debounce";
import { CommercialServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/commercial";
import { formatDateFunction } from "lib/utils/common_utils";
import CustomerApproval from "./CustomerApproval";
import AmberGear from "assets/AmberGear.png";
import GreenHollow from "assets/Green hollow.png";
import RedRemoveIcon from "assets/Red remove icon.png";
import { WorkshopContext } from "../WorkshopContext/WorkshopContext";
import { ReactComponent as RightIcon } from 'assets/right.svg'
import LabourPopup from "./LabourPopup";
import LinearProgressBar from 'components/linear_progress_bar';
import RedHandIcon from "assets/red_hand.png";
import ApproveAll from "./ApproveAll";
import CreateNewApprovedPosition from "./CreateNewApprovedPosition"
import Chart from 'react-apexcharts'
import LoadingIndicator from "components/universal/loading_indicator";
import _ from "lodash";
import RescheduleIcon from "@material-ui/icons/History";
import EstimationHistory from "./EstimationHistory";
import moment from "moment";
import UpdateEstimation from "./UpdateEstimation";
import CustomerApprovalNew from "./CustomerApprovalNew";




const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "85%",
    margin: "1% 3%",
  },
  paper: {
    borderRadius: "5px",
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(3),
    boxShadow: "0px 1px 4px #0000001A",
  },
  container: {},
  secondPaper: {
    height: "600px",
    borderRadius: "10px",
    backgroundColor: "#fff",
    // padding: theme.spacing(3),
    boxShadow: "0px 1px 4px #0000001A",
  },
  input1: {
    height: "20px",
  },
  head: {
    marginTop: "20px",
    marginBottom: "10px",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: "5px 38px",
  },
  bold: {
    fontWeight: "bold",
  },
  text: {
    fontWeight: "bold",
    fontSize: "30px",
    color: "#4FC605",
    marginLeft: "20px",
  },
  text1: {
    fontSize: "25px",
    color: "#4FC605",
    marginLeft: "20px",
  },
  actionButtons: {
    paddingTop: "20px",
    "& button": {
      marginRight: "20px",
    },
  },
  addBtn: {
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: `${theme.palette.secondary.main} !important`,
    },
  },
  stockBtn2: {
    borderColor: "#4D4F5C",
    borderRadius: "7px",
    minWidth: "20px",
    height: "30px",
    color: "#fff",
    backgroundColor: "#4FC605",
    maxWidth: "70px",
  },
  stockBtn: {
    minWidth: "70px",
    height: "30px",
    borderColor: "#4D4F5C",
    borderRadius: "7px",
    width: "85px",
  },

  Tooltip: {
    fontSize: theme.spacing(2)
  }
}));

function Commercial(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { color, currentSelectedOrder, orderState, orderStateNum } = props;
  const message = useContext(ToastMessageContext);
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const { page, totalCount, pageSize, data, inProgress } = tableState;
  const [action, setAction] = useState(null);
  const [approvalState, setApprovalState] = useState(null);
  // const [states, setStates] = useState([]);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [searchText, setSearchText] = useState(null);
  const [filters, setFilters] = useState({});

  const [actionIndicators, setActionIndicators] = useState({});
  const [submissionData, setSubmissionData] = useState(null);
  const [currentSelection, setCurrentSelection] = useState({});
  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedActionRowData, setSelectedActionRowData] = useState({});
  const [approvedPosition, setApprovedPosition] = useState({});
  const [currentPosition, setCurrentPosition] = useState({});
  const [approveData, setData] = useState({});
  const [totalRow, setTotalRow] = useState({});
  const [tatDays, setTatDaysCount] = useState(null);
  const { approvalStateCommercial } = useContext(WorkshopContext);
  const [loadingCurrentPosition, setLoadingCurrentPosition] = useState(false);
  const [loadingApprovedPosition, setLoadingApprovedPosition] = useState(false);
  const [loadingEstimatedDeparture, setLoadingEstimatedDeparure] = useState(false);
  const [approveTotal, setApproveTotal] = useState(0)
  const [variance, setVariance] = useState({
    variance_1: "",
    variance_2: "",
  })
  const [quoteAccepted, setCheckQuoteAccepted] = useState(false)
  const [labourAction, setlabourAction] = useState('')
  const [currentRow, setCurrentRow] = useState({})
  const deboucedText = useDebounce(searchText)
  let varianceTotal = totalRow.total - approveTotal
  const [chartLoading, setChartLoading] = useState(false)
  const [estData, setEstData] = useState([])
  const [formatedDate, setFormatedDate] = useState(null)
  const [approvalData, setApprovalData] = useState({
    name: "Approved Position",
    position: null,
    tat: "",
    etd: null,
    labour: "",
    parts: "",
    services: "",
    equipment: "",
    aog_fee: "",
    total: "",
    currency: "",
    approvalType: "",
    release_type_id: null,
    sales_rate: 1,
    standard_id: null,
    inducted_start: null,
    t_labour: ""
  })
  const [approvalInitialData, setApprovalInitialData] = useState({
    name: "Approved Position",
    position: null,
    tat: "",
    etd: null,
    labour: null,
    parts: null,
    services: null,
    equipment: null,
    aog_fee: null,
    total: null,
    currency: "",
    approvalType: "",
    release_type_id: null,
    sales_rate: 1,
    standard_id: null,
    inducted_start: null,
    t_labour: ""
  })
  const [currentData, setCurrentData] = useState({
    name: "Current Position",
    position: "-",
    tat: "",
    etd: "-",
    labour: "",
    parts: "",
    services: "",
    equipment: "",
    aog_fee: "",
    total: "",
    currency: "",
    approvalType: "",
    release_type_id: "",
    sales_rate: "",
    standard_id: null,
    inducted_start: null,
    t_labour: ""
  })
  const [varianceData, setVarianceData] = useState({
    name: "Variance",
    position: "-",
    tat: "-",
    etd: "-",
    labour: "",
    parts: "",
    services: "",
    equipment: "",
    aog_fee: "",
    total: "",
    currency: "",
    approvalType: "",
    release_type_id: "",
    sales_rate: "",
    standard_id: null,
    inducted_start: null,
    t_labour: ""
  })




  //Gauage
  const [state, setState] = useState({
    series: [0],

    options: {
      chart: {
        type: 'radialBar',
        offsetY: -20,
        sparkline: {
          enabled: false
        }
      },
      plotOptions: {
        radialBar: {
          startAngle: -100,
          endAngle: 100,
          track: {
            background: "#e7e7e7",
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: false,

            }
          },

          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,

            },
            total: {
              show: false,
              label: 'TOTAL'
            }
          }

        }
      },
      grid: {
        padding: {
          top: -10
        }
      },
      fill: {
        type: 'solid',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        },
      },
      labels: ["Percentage"],
    }
  })






  useMemo(() => {
    setChartLoading(true)
    setVariance({
      variance_1: varianceTotal,
      variance_2: (totalRow.total) / (approveTotal) * 100
    })
    let value = (totalRow.total) / (approveTotal) * 100
    let finalValue = parseFloat(value).toFixed(2)

    if (finalValue !== "Infinity") {
      let chartColor = finalValue > 0 && finalValue <= 85 ? "#4FC605" : finalValue > 85 && finalValue <= 100 ? "#FFB300" : finalValue > 100 ? "#FF0202" : "#e7e7e7"
      setState((prevState) => {
        const updatedState = _.clone(prevState);
        return {
          ...updatedState,
          series: [finalValue ? finalValue : 0],
          options: {
            ...updatedState.options,
            colors: [chartColor],
          }
        }
      })
    }
    setChartLoading(false)
  }, [approveTotal, totalRow])

  const getActionIndicators = async () => {
    try {
      const response = await CommercialServices.getActionIndicators({
        workshop_order_id: currentSelectedOrder.id,
      });
      if (response.success) setActionIndicators(response.data);
    } catch (error) {
      console.log("something went wrong from commercial tab action indicators");
    }
  };

  useEffect(() => {
    getActionIndicators();
    getApprovedPosition();
    getCurrentPosition();
    getEstimatedDetails()
  }, []);

  const getApprovedPosition = async () => {
    setLoadingApprovedPosition(true);
    const params = {
      workshop_order_id: currentSelectedOrder?.id,
    };
    await CommercialServices.getApprovedPosition(params)
      .then((res) => {
        if (res) {
          setApprovedPosition(res);
          // if (res?.data.length > 0) {
          //   setApprovalData()
          // }
          // setCheckQuoteAccepted(res.data[0]?.quote_accepted)
        }
      })
      .catch(() => {
        console.log("error");
      })
      .finally(() => {
        setLoadingApprovedPosition(false);
      });
  };
  const getCurrentPosition = async () => {
    setLoadingCurrentPosition(true);
    const params = {
      workshop_order_id: currentSelectedOrder?.id,
    };
    await CommercialServices.getCurrentPosition(params)
      .then((res) => {
        if (res) {
          setCurrentPosition(res);
        }
      })
      .catch(() => {
        console.log("error");
      })
      .finally(() => {
        setLoadingCurrentPosition(false);
      });
  };
  const getEstimatedDetails = async () => {
    setLoadingEstimatedDeparure(true);
    const params = {
      workshop_order_id: currentSelectedOrder?.id,
    };
    await CommercialServices.getEstimationDeparture(params).then((res) => {

      if (res?.success) {
        setEstData(res?.data);
      }
    })
      .catch(() => {
        console.log("error");
      })
      .finally(() => {
        setLoadingEstimatedDeparure(false);
      });
  };

  const handleClick = (event) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };



  // console.log("data====", approvalData, currentData)

  // useEffect(() => {
  //   let data = approvedPosition?.data?.filter((item) => {
  //     if (selectedRowData?.id === item?.id) {
  //       setData(item);
  //       return selectedRowData?.id === item?.id;
  //     }
  //   });
  //   setSubmissionData(data);
  //   console.log("submissionData", filters)
  // }, [selectedRowData?.id]);

  const ITEM_HEIGHT = 48;

  const getList = () => {
    const params = {
      // reportable_type: "WorkshopOrder",
      ...filters,
      search: deboucedText || "",
      filter: filters.filter || "",
      page: 1 + page,
      limit: pageSize,
      workshop_order_id: currentSelectedOrder.id,


    };

    BoxpTableAction.getList(dispatch, message, params, "actionListings");
    console.log("filters", action)
  };

  useEffect(() => {
    getList();
  }, [page, pageSize, deboucedText, filters]);

  const updateApprovalStatus = async (id, state) => {
    const formData = new FormData();
    formData.append("workshop_action_id", id);
    formData.append("approval_state", state);

    try {
      const response = await CommercialServices.updateApprovalStatus(formData);
      if (response.success) {
        getList();
        getActionIndicators()
        message.showToastMessage({
          message: "Updated Successfully",
          variant: "success",
        });
      }
    } catch (error) {
      message.showToastMessage({
        message: "Something went wrong. Try again!",
        variant: "error",
      });
    }
  };

  const handleApproveBtnClick = (rowData) => {
    setAction("customer_Approval");
    setSelectedRowData(rowData);
  };

  const selectedRowTotal = (rowData) => {
    setTotalRow(rowData);
    return <>{rowData?.total}</>;
  };

  const tableOptions = {
    ...options,
    page: page,
    total: totalCount,
    pageSize: pageSize,
    search: false,
    paging: false,
    rowStyle: { height: 42 },
  };
  const tableOptions3 = {
    ...options,
    page: page,
    total: totalCount,
    pageSize: pageSize,
    search: false,
    // paging: false,
    rowStyle: { height: 42 },
  };
  const tableOptions4 = {
    ...options,
    page: page,
    total: totalCount,
    pageSize: pageSize,
    search: false,

    rowStyle: { height: 42 },
  };
  const column1 = [
    {
      title: "Live values",
      field: "live_values",
      headerStyle: {
        textAlign: "left",
        paddingLeft: "20px",
      },
      cellStyle: {
        width: "20%",
        textAlign: "left",
        paddingLeft: "20px",
      },
    },
    {
      title: "TAT",
      field: "tat_days" || "0.00",
      render: (rowData) => rowData.tat_days != null ? rowData.tat_days : ""
    },
    {
      title: "Actions",
      field: "actions" || "0.00",
    },
    {
      title: "Labour *",
      field: "labour" || "0.00",
      render: (rowData) => <a style={{ textDecoration: "underline" }} onClick={() => {
        setlabourAction('labour')
        setCurrentRow(rowData)
      }}>{rowData.labour !== null ? rowData.labour : "0.00"}</a>
    },
    {
      title: "Parts",
      field: "parts" || "0.00",
      render: (rowData) => rowData.parts != null ? rowData.parts : "0.00"
    },
    {
      title: "Services",
      field: "services" || "0.00",
      render: (rowData) => rowData.services != null ? rowData.services : "0.00"
    },
    {
      title: "Equipment",
      field: "equipment" || "0.00",
      render: (rowData) => rowData.equipment != null ? rowData.equipment : "0.00"
    },
    {
      title: "Other",
      field: "aog_fee" || "0.00",
      render: (rowData) => rowData.aog_fee != null ? rowData.aog_fee : "0.00"
    },
    {
      title: "Total",
      field: "total" || "0.00",
      render: (rowData) => selectedRowTotal(rowData),
    },
  ];

  const renderApproveTotal = (total) => {
    setApproveTotal(total)
    return total === null ? "0.00" : total
  }

  const column2 = [
    {
      title: "Approval",
      field: "approval_number",
      width: "5%",
      headerStyle: {
        textAlign: "center",
        paddingLeft: "22px",
      },
      cellStyle: {
        textAlign: "center",
        paddingLeft: "22px",
      },
      render: (rowData) => rowData?.approval_number === 0 ? "Quote" : rowData?.approval_number
    },
    {
      title: "Date",
      field: "date",
      render: (rowData) => formatDateFunction(rowData?.approved_datetime),
    },
    {
      title: "TAT (Days)",
      field: "tat_days_count",
      render: (rowData) => {
        setTatDaysCount(rowData?.tat_days_count);
        return <>{rowData?.tat_days_count || "0"}</>;
      },
    },
    {
      title: "Actions",
      field: "action_count",
    },
    {
      title: "Labour",
      field: "labour_value",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (rowData) => rowData.labour_value === null ? "0.00" : rowData.labour_value
    },
    {
      title: "Parts",
      field: "parts_value",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
      },
      render: (rowData) => rowData.parts_value === null ? "0.00" : rowData.parts_value
    },
    {
      title: "Services",
      field: "service_value",
      render: (rowData) => rowData.service_value === null ? "0.00" : rowData.service_value
    },
    {
      title: "Equipment",
      field: "equipment_value",
      render: (rowData) => rowData.equipment_value === null ? "0.00" : rowData.equipment_value
    },
    {
      title: "Other",
      field: "aog_fee",
      render: (rowData) => rowData.aog_fee === null ? "0.00" : rowData.aog_fee
    },
    {
      title: "Total",
      field: "total",
      render: (rowData) => renderApproveTotal(rowData.total)
    },
    {
      title: "Currency",
      field: "currency_name",

    },
    // {
    //   title: "Report",
    //   field: "report",
    //   render: (rowData) => {
    //     if (rowData.approval_number === 0) {
    //       return (
    //         <IconButton onClick={() => {
    //           if (rowData.quotation_report.length === 0) {
    //             message.showToastMessage({
    //               message: "No Report Found.",
    //               variant: "error",
    //             });
    //           } else if (rowData.quotation_report.length != 0) {
    //             window.open(rowData?.quotation_report?.map(report => report?.file_path));
    //           }
    //         }}
    //           size="small">
    //           <VisibilityIcon fontSize="small" color={ICON_COLOUR} />
    //         </IconButton>
    //       )

    //     }
    //     else {
    //       return (
    //         <IconButton onClick={() => {
    //           if (rowData.commercial_report.length === 0) {
    //             message.showToastMessage({
    //               message: "No Report Found.",
    //               variant: "error",
    //             });
    //           } else if (rowData.commercial_report.length != 0) {
    //             window.open(rowData?.commercial_report?.map(report => report?.file_path));
    //           }
    //         }}
    //           size="small">
    //           <VisibilityIcon fontSize="small" color={ICON_COLOUR} />
    //         </IconButton>
    //       )
    //     }
    //   },
    // },
    {
      title: "Acceptance",
      render: (rowData) => {
        if (rowData?.quote_accepted) {
          return (
            <div style={{ backgroundColor: "transparent", color: "green" }}>
              <img width="25px" height="25px" src={greenCheckIcon} />
            </div>
          );
        } else if (rowData?.quote_accepted === null) {
          setCheckQuoteAccepted(true)
          return (
            <div style={{ backgroundColor: "transparent" }}>
              <img width="25px" height="25px" src={AmberGear} />
            </div>
          );
        } else if (rowData?.quote_accepted === false) {
          return (
            <div style={{ backgroundColor: "transparent" }}>
              <img width="25px" height="25px" src={RedRemoveIcon} />
            </div>
          );
        }
      },
    },
    {
      title: "Action",
      render: (rowData) => {
        if (rowData?.quote_accepted === null) {
          return (
            <IconButton size="small" onClick={() => handleApproveBtnClick(rowData)}>
              <VisibilityIcon fontSize="small" color={ICON_COLOUR} />
            </IconButton>)
        }
        else if (rowData?.quote_accepted || !rowData?.quoteAccepted) {
          return (

            <IconButton size="small" onClick={() => {
              setAction("view_customer_Approval");
              setSelectedRowData(rowData);
            }}>

              <VisibilityIcon fontSize="small" color={ICON_COLOUR} />
            </IconButton>)
        }

      }
    },
    // {
    //   title: "Action",
    //   render: (rowData) => {
    //     if (rowData?.quote_accepted) {
    //       return (
    //         <IconButton onClick={() => {
    //           if (rowData?.proof_of_acceptance !== undefined) {
    //             window.open(rowData?.proof_of_acceptance?.map(proof => proof?.file_path));
    //           } else {
    //             message.showToastMessage({
    //               message: "No Document Found.",
    //               variant: "error",
    //             });
    //           }
    //         }}
    //         >

    //         </IconButton>
    //       );
    //     } else if (rowData?.quote_accepted === null) {
    //       return (
    //         <MROButton
    //           className={clxc(classes.button, "button-info", classes.stockBtn2)}
    //           onClick={() => handleApproveBtnClick(rowData)}
    //           disabled={!props.permission.write}
    //           variant="contained"
    //         // color="primary"
    //         >
    //           <u style={{ fontSize: "13px" }}>APPROVE</u>
    //         </MROButton>
    //       );
    //     } else if (!rowData?.quote_accepted) {
    //       return (
    //         <IconButton onClick={() => {
    //           if (rowData?.proof_of_rejection !== []) {
    //             window.open(rowData?.proof_of_rejection.map(proof => proof?.file_path));
    //           } else {
    //             message.showToastMessage({
    //               message: "No Document Found.",
    //               variant: "error",
    //             });
    //           }
    //         }}
    //           size="small">
    //           <VisibilityIcon fontSize="small" color={ICON_COLOUR} />
    //         </IconButton>
    //       )
    //     }
    //   },
    // },
  ];
  const renderZeroState = (value) => {
    return (

      <div>
        <img src={RedHandIcon} height={'16px'} />
      </div>
    )

  }
  //Gauge 
  const column3 = [
    {
      title: "Action",
      field: "action_number",
      width: "5%",
    },
    {
      title: "Type",
      field: "action_category",
      width: "20%",
      headerStyle: {
        width: "20%",
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
        width: "20%",
      },
    },



    {
      title: "Description",
      field: "title",
      width: "20%",
      headerStyle: {
        width: "20%",
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
        width: "20%",
      },
    },
    {
      title: "Ready",
      field: "approval_readiness",
      render: (rowData) => renderApvlState(rowData.approval_readiness),
    },
    {
      title: "State",
      field: "approval_state",
      render: (rowData) => renderButton(rowData),
    },
    {
      title: "Labour",
      field: "labour",
      render: (rowData) =>
        <div style={{ position: 'absolute', marginTop: '-10px' }}>
          <LinearProgressBar
            variant='determinate'
            value={Number(rowData.labour)}
            width='60px'
            barHeight='20px'
            bgColor={(rowData.labour > 0 && rowData.labour < 75) ? '#4FC605' : (rowData.labour >= 75 && rowData.labour < 100) ? '#FFB300' : rowData.labour >= 100 ? '#FF0202' : '#fff'}
            color={rowData.labour >= 100 ? "#FFFFFF" : "#4D4F5C"}
          />
        </div>

    },
    {
      title: 'Parts',
      field: 'parts',
      render: (rowData) => <>
        {rowData?.parts === "" ? renderZeroState(rowData?.parts) : <Typography style={{ color: rowData?.parts > 100 ? '#FF0202' : '#4FC605', fontSize: '14px' }}>{Number(rowData?.parts).toFixed(0)}% </Typography>}
      </>
    },
    {
      title: 'Services',
      field: 'service',
      render: (rowData) => <>
        {rowData?.service === "" ? renderZeroState(rowData?.service) : <Typography style={{ color: rowData?.service > 100 ? '#FF0202' : '#4FC605', fontSize: '14px' }}>{Number(rowData?.service).toFixed(0)}% </Typography>}
      </>
    },

    {
      title: 'Equipment',
      field: 'equipment',
      render: (rowData) => <>
        {rowData?.equipment === "" ? renderZeroState(rowData?.equipment) : <Typography style={{ color: rowData?.equipment > 100 ? '#FF0202' : '#4FC605', fontSize: '14px' }}>{Number(rowData?.equipment).toFixed(0)}% </Typography>}
      </>
    },
    {
      title: 'Overall',
      field: 'overall',
      render: (rowData) => <>
        <Typography style={{ color: rowData?.overall > 100 ? '#FF0202' : '#4FC605', fontSize: '14px' }}>{Number(rowData?.overall).toFixed(0)}% </Typography>
      </>
    },


    // {
    //   title: "QTY Live",
    //   field: "quantity_live",
    // },
    // {
    //   title: "QTY Apvl",
    //   field: "quantity_at_approval",
    // },
    // {
    //   title: "Value Live",
    //   field: "charges_live",
    // },
    // {
    //   title: "Value Apvl",
    //   field: "charges_at_approval",
    // },
    // {
    //   title: "Variance",
    //   field: "variance",
    // },
    {
      title: "Detail",
      render: (rowData) => (
        <IconButton>
          <ViewIcon
            fontSize="small"
            color={ICON_COLOUR}
            onClick={() => {
              setAction("View")
              setSelectedActionRowData(rowData)
            }}
          />
        </IconButton>
      ),
    },
  ];

  //Estimation Departure
  const column4 = [
    {
      title: "Type",
      field: "type",

      headerStyle: {
        textAlign: "left",
        paddingLeft: "22px",
      },
      cellStyle: {
        textAlign: "left",
        paddingLeft: "22px",
      },

    },
    {
      title: "Position",
      field: "position",

    },
    {
      title: "Date",
      field: "date",
    },
    {
      title: "History",
      field: "",
      width: "20%",

      headerStyle: {
        textAlign: "left",

      },
      cellStyle: {
        width: "20%",
      }, render: (rowData) => {
        if (rowData?.type === "Approved") {
          return ""
        }
        else if (rowData?.type === "Revised") {
          return (
            <IconButton onClick={() => {
              setAction("est_history")
              setCurrentSelection(rowData)
            }}>
              <RescheduleIcon />
            </IconButton>

          )
        }

      }
    },
    {
      title: "Inducted / Start",
      field: "inducted_start",


    }, {
      title: "TAT",
      field: "tat_days",


    }, {
      title: "ETD",
      field: "etd",

    }, {
      title: "Progress",
      field: "progress",
      render: (rowData) =>
        <div style={{ position: 'absolute', marginTop: '-10px' }}>
          <LinearProgressBar
            variant='determinate'
            value={Number(rowData.progress)}
            width='70px'
            barHeight='20px'
            bgColor={(rowData.progress > 0 && rowData.progress < 75) ? '#4FC605' : (rowData.progress >= 75 && rowData.progress < 100) ? '#FFB300' : rowData.progress >= 100 ? '#FF0202' : '#fff'}
            color={rowData.progress >= 100 ? "#FFFFFF" : "#4D4F5C"}
          />
        </div>

    },
    {
      title: "Acceptance",
      render: (rowData) => {
        if (rowData?.type === "Approved") {
          if (rowData?.acceptance === "green") {
            return (
              <div style={{ backgroundColor: "transparent", color: "green" }}>
                <img width="25px" height="25px" src={greenCheckIcon} />
              </div>
            );
          } else if (rowData?.acceptance === null) {
            setCheckQuoteAccepted(true)
            return (
              <div style={{ backgroundColor: "transparent" }}>
                <img width="25px" height="25px" src={AmberGear} />
              </div>
            );
          } else if (rowData?.acceptance === "red") {
            return (
              <div style={{ backgroundColor: "transparent" }}>
                <img width="25px" height="25px" src={RedRemoveIcon} />
              </div>
            );
          }
        }
        else {
          return (
            <div style={{ backgroundColor: "transparent" }}>

            </div>
          );
        }
      },
    },
    {
      title: "Action",
      field: "",
      render: rowData => {

        if (rowData?.type === "Approved") {
          return ""
        }
        else if (rowData?.type === "Revised") {
          return (
            <IconButton onClick={() => {
              setAction("edit_est")
              setCurrentSelection(rowData)
              setFormatedDate(moment(rowData?.etd, "DD/MM/YYYY").format("YYYY-MM-DD"))
            }}>
              <EditIcon fontSize="small" htmlColor={ICON_COLOUR} />
            </IconButton>

          )
        }

      }
    }


  ]




  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target)
    formData.append("workshop_order_id", currentSelectedOrder.id)
    try {
      const response = await CommercialServices.getNextStage(formData);
      if (response.success) {
        getList();
        message.showToastMessage({
          message: "Updated Successfully",
          variant: "success",
        });
      }
    } catch (error) {
      message.showToastMessage({
        message: "Something went wrong. Try again!",
        variant: "error",
      });
    }

  }

  const checkUnapprovedPositions = approvedPosition?.data?.length
    ? approvedPosition?.data?.filter((item) => !item.quote_accepted)
    : [];

  const renderApvlState = (state) => {
    setApprovalState("inProgress");
    return state === "amber" ? (
      <div style={{ backgroundColor: "transparent", color: "yellow" }}>
        <img src={AmberGear} alt="Icon" style={{ height: "18px" }} />{" "}
      </div>
    ) : (
      <div style={{ backgroundColor: "transparent", color: "green" }}>
        <img src={GreenHollow} alt="Icon" style={{ height: "18px" }} />{" "}
      </div>
    );
  };

  const renderButton = (rowData) => {
    let ButtonColor, buttonText;
    if (rowData.approval_state === "approved") {
      ButtonColor = "button-positive";
      buttonText = "APPROVED";
    } else if (rowData.approval_state === "not approved") {
      ButtonColor = "button-warning";
      buttonText = "PENDING";
    } else {
      ButtonColor = "button-negative";
      buttonText = "REJECTED";
    }
    return (
      <div>
        <Button
          disabled={!props?.permission.write}
          size="small"
          className={clxc(classes.button, ButtonColor, classes.stockBtn)}
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "clip",
            pointerEvents: orderState === "Closed" && "none"
          }}
          variant="outlined"
          onClick={(e) => {
            handleClick(e);
            setCurrentSelection(rowData);
          }}
        >
          {" "}
          {buttonText}
          <CreateIcon style={{ marginLeft: "3px" }} fontSize="small" />
        </Button>
      </div>
    );
  };

  return (
    <div>
      <Grid>
        <Typography
          color="Primary"
          style={{ marginBottom: "10px", fontSize: "17px" }}
        >
          Current Position
        </Typography>
      </Grid>
      <Grid className={classes.paper}>
        <div style={{ width: "100%" }}>
          <Box display="flex" p={0}>
            <Box p={1} flexGrow={1}>
              <MaterialTable
                style={{
                  boxShadow: "0px 1px 4px #00000033",
                }}
                isLoading={loadingCurrentPosition}
                icons={tableIcons}
                title={""}
                columns={column1}
                data={currentPosition?.data || []}
                options={tableOptions}
                components={{
                  Toolbar: () => null,
                }}
                localization={{
                  toolbar: {},
                  body: {
                    emptyDataSourceMessage: "No data found",
                    filterRow: {
                      filterTooltip: "Filter",
                    },
                  },
                }}
                onChangePage={(page) => {
                  TableAction.setPage(dispatch, page);
                }}
                onChangeRowsPerPage={(pageSize) => {
                  TableAction.setPageSize(dispatch, pageSize);
                }}
                totalCount={totalCount}
                page={page}
                onRowClick={(row) => { }}
              />
            </Box>

            <Grid>
              <Paper style={{ height: "186px", marginTop: "11px" }}>
                <Typography wrap variant="subtitle2" color="Primary" style={{ textAlign: "center", paddingTop: "11px", paddingBottom: '10px' }}>
                  Variance to Approval
                </Typography>
                <Grid  >
                  {chartLoading && state.series.length === 0 ? <LoadingIndicator /> : <Chart
                    options={state.options}
                    series={state.series}
                    type='radialBar'
                    width="310" />}



                </Grid>

                <Grid style={{ marginTop: "-83px" }}>
                  <Grid>
                    <Typography
                      style={{
                        fontSize: "20px",
                        textAlign: "center",
                        // marginTop: "-116px",
                        // color: color,
                        color: !variance.variance_1 ? "" : parseFloat(variance.variance_1).toFixed(2) > 0 ? "red" : "green"
                      }}
                    >
                      {totalRow.total === approveTotal ? "0" : !variance.variance_1 ? "-" : parseFloat(variance.variance_1).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      style={{
                        fontWeight: "600px",
                        fontSize: "23px",
                        textAlign: "center",
                        // color: color,
                        color: !variance.variance_2 ? "" : parseFloat(variance.variance_2).toFixed(2) > 100 ? "red" : "green"
                      }}
                    >
                      {totalRow.total === approveTotal ? "0%" : !variance.variance_1 ? "-" : !approveTotal ? "100%" : `${parseFloat(variance.variance_2).toFixed(2)}%`}
                    </Typography>
                  </Grid>
                </Grid>

              </Paper>

            </Grid>




          </Box>
        </div>
        <Typography
          color="primary"
          style={{ marginTop: "3px", fontStyle: "italic", fontSize: "13px" }}
        >
          * Current Labour value is based on <u>completed</u> Job Clockings. To see estimated hours click on the figure shown
        </Typography>
      </Grid>

      <Grid>
        <Typography
          color="Primary"
          style={{ margin: "10px 0px 10px", fontSize: "17px" }}
        >
          Approved Position
        </Typography>
      </Grid>
      <Grid className={classes.paper}>
        <MaterialTable
          style={{
            boxShadow: "0px 1px 4px #00000033",
            //   marginTop: "10px",
          }}
          isLoading={loadingApprovedPosition}
          icons={tableIcons}
          title={""}
          columns={column2}
          data={approvedPosition?.data || []}
          options={tableOptions}
          components={{
            Toolbar: () => null,
          }}
          localization={{
            toolbar: {},
            body: {
              emptyDataSourceMessage: "No History found",
              filterRow: {
                filterTooltip: "Filter",
              },
            },
          }}
          onChangePage={(page) => {
            TableAction.setPage(dispatch, page);
          }}
          onChangeRowsPerPage={(pageSize) => {
            TableAction.setPageSize(dispatch, pageSize);
          }}
          totalCount={totalCount}
          page={page}
          onRowClick={(row) => { }}
        />
        <Typography
          color="primary"
          style={{ marginTop: "10px", fontStyle: "italic", fontSize: "13px" }}
        >
          All values shown include applicable markup and are shown in the customer’s currency!
        </Typography>
      </Grid>
      {openMenu && (
        <Menu
          id={`long-menu`}
          anchorEl={anchorEl}
          keepMounted
          open={openMenu}
          onClose={handleCloseMenu}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          }}
        >
          {currentSelection?.approval_state === "not approved" && <MROButton
            onClick={() => {
              setOpenMenu(false);
              updateApprovalStatus(currentSelection.id, "approved");
            }}
          >
            Approve
          </MROButton>}
          {(currentSelection?.approval_state === "approved" || currentSelection?.approval_state === "rejected") &&
            <MROButton
              onClick={() => {
                setOpenMenu(false);
                updateApprovalStatus(currentSelection.id, "not approved");
              }}
            >
              Pending
            </MROButton>}
          {currentSelection?.approval_state === "not approved" && <MROButton
            onClick={() => {
              setOpenMenu(false);
              updateApprovalStatus(currentSelection.id, "rejected");
            }}
          >
            Reject
          </MROButton>}
        </Menu>
      )}

      {/* {orderState !== "Closed" && ( */}
      <Box
        display='flex'
        justifyContent={"space-between"}
        alignItems='center'
      >
        <Typography
          noWrap
          className={classes.bold}
          style={{ margin: "10px 0px 10px" }}
        >
          <MROButton
            variant={"contained"}
            disabled={Boolean(checkUnapprovedPositions?.length)}
            style={{
              backgroundColor: Boolean(checkUnapprovedPositions?.length)
                ? "#FFB300"
                : !props?.permission.write
                  ? "#c2bebe"
                  : "#4FC605",
              color: "#fff",
              pointerEvents:
                Boolean(checkUnapprovedPositions?.length) && "none",
            }}
            onClick={() => setAction("approval")}
          >
            + Create New Approvals
          </MROButton>
        </Typography>
        <form onSubmit={handleSubmit}>
          {orderState === "Commercial" && !quoteAccepted && (
            <Box>
              <Tooltip arrow title={"Next Stage"} className={classes.Tooltip}>
                <IconButton type='submit'>
                  <RightIcon
                    fill='#4FC605'
                    stroke='#4FC605'
                    height='38px'
                    width='38px'
                    strokeWidth={0.25}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </form>
      </Box>
      {/* )} */}
      {/* Estimated Departure */}
      <Grid>
        <Typography
          color="Primary"
          style={{ margin: "10px 0px 10px", fontSize: "17px" }}
        >
          Estimated Departure
        </Typography>
      </Grid>



      <Grid className={classes.paper}>
        <MaterialTable
          style={{
            boxShadow: "0px 1px 4px #00000033",
            //   marginTop: "10px",
          }}
          isLoading={loadingEstimatedDeparture}
          icons={tableIcons}
          title={""}
          columns={column4}
          data={estData || []}
          options={tableOptions4}
          components={{
            Toolbar: () => null,
          }}
          localization={{
            toolbar: {},
            body: {
              emptyDataSourceMessage: "No History found",
              filterRow: {
                filterTooltip: "Filter",
              },
            },
          }}
        />

      </Grid>

      {/* Action overview */}
      <Grid>
        <Typography
          color="Primary"
          style={{ margin: "10px 0px 10px", fontSize: "17px" }}
        >
          Actions Overview
        </Typography>
      </Grid>
      <Grid className={classes.paper}>
        <div style={{ width: "100%", marginBottom: "20px" }}>
          <Box display="flex" p={0}>
            <Box
              p={2}
              mt={1}
              style={{
                backgroundColor: "#fff",
                width: "290px",
                height: "142px",
                boxShadow: "0px 1px 4px #0000001A",
              }}
            >
              <Grid
                container
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item>
                  <Typography color="primary" variant="subtitle2">
                    Actions
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={4}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid item>
                  <Typography style={{ fontSize: "40px", color: theme.palette.primary.main }}>
                    {actionIndicators.total_actions}
                  </Typography>
                </Grid>
                <Box
                  height="40px"
                  borderLeft={"2px solid grey"}
                // flexGrow={1}
                // ml={3}
                ></Box>
                <Grid item>
                  <Typography color="secondary" style={{ fontSize: "40px" }}>
                    {actionIndicators.open_actions}
                  </Typography>
                </Grid>
                <Box
                  height="40px"
                  borderLeft={"2px solid grey"}
                // flexGrow={1}
                // ml={3}
                ></Box>
                <Grid item>
                  <Typography
                    style={{
                      fontSize: "40px",
                      textDecoration: actionIndicators.pend_approval > 0 && 'underline',
                      cursor: 'pointer',
                      pointerEvents: actionIndicators.pend_approval === 0 && 'none',
                      color:
                        actionIndicators.pend_approval === 0
                          ? "#50C559"
                          : "#FFA217",
                      marginRight: "25px",
                    }}
                    onClick={() => {
                      setAction("approve_all")
                    }}
                  >
                    {actionIndicators.pend_approval}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={4}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid item>
                  <Typography style={{ fontSize: "13px" }}>Total</Typography>
                </Grid>
                <Grid item>
                  <Typography style={{ fontSize: "13px", marginLeft: "30px" }}>
                    Open
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography style={{ fontSize: "13px" }}>
                    Unapproved
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box flexGrow={1} style={{ paddingLeft: ' 10px', marginTop: '-2px' }}>
              <SearchBarWithFilters
                handleSearch={(text) => {
                  setSearchText(text);
                  TableAction.setPage(dispatch, 0);
                }}
                searchText={searchText}
                boxShadow={"0px 1px 4px #0000001A"}
              >
                <div>
                  <FormControl className={"filter"}>
                    <Select
                      defaultValue={undefined}
                      displayEmpty
                      input={<InputBase />}
                      onChange={(e) =>
                        setFilters((ps) => ({ ...ps, filter: e.target.value }))
                      }
                    >
                      <MenuItem value={undefined}>
                        Approval Status (All)
                      </MenuItem>
                      <MenuItem value={"approved"}>Approved</MenuItem>
                      <MenuItem value={"not approved"}>Pending</MenuItem>
                      <MenuItem value={"rejected"}>Rejected</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </SearchBarWithFilters>
            </Box>
          </Box>
        </div>

        <MaterialTable
          style={{
            boxShadow: "0px 1px 4px #00000033",
          }}
          icons={tableIcons}
          title={""}
          columns={column3}
          data={data?.data || []}
          isLoading={inProgress}
          options={tableOptions3}
          components={{
            Toolbar: () => null,
          }}
          localization={{
            toolbar: {},
            body: {
              emptyDataSourceMessage: "No History found",
              filterRow: {
                filterTooltip: "Filter",
              },
            },
          }}
          onChangePage={(page) => {
            TableAction.setPage(dispatch, page);
          }}
          onChangeRowsPerPage={(pageSize) => {
            TableAction.setPageSize(dispatch, pageSize);
          }}

          totalCount={totalCount}
          page={page}
          onRowClick={(row) => { }}
        />
        <Typography
          color="primary"
          style={{ marginTop: "10px", fontStyle: "italic", fontSize: "13px" }}
        >
          Action % values compare current live values to the value captured when the Action State was approved
        </Typography>
      </Grid>
      <Typography
        color="primary"
        style={{ marginTop: "20px", fontStyle: "italic", fontSize: "13px" }}
      >
        All values shown include applicable markup and are shown in the customer’s currency!
      </Typography>
      {/* {action === "approval" && (
        <ApprovalManage
          handleClose={() => setAction(null)}
          currentSelectedOrder={currentSelectedOrder}
          approvedPosition={approvedPosition}
          currentPositionData={currentPosition?.data}
          getApprovedPosition={getApprovedPosition}
          totalRow={totalRow}
          tatDays={tatDays}
        />
      )}   */}

      {action && action === "approval" && (
        <CreateNewApprovedPosition
          handleClose={() => {
            setAction(null)
            getEstimatedDetails()

          }}
          jobData={[currentData, approvalData, varianceData]}
          currentSelectedOrder={currentSelectedOrder}
          approvedPositionnData={approvalData}
          currentPosition={currentData}
          getApprovedPosition={getApprovedPosition}
          appPosition={approvedPosition}
          currPosition={currentPosition}
          setApprovalData={setApprovalData}
          setCurrentData={setCurrentData}
          setVarianceData={setVarianceData}
          totalRow={totalRow}
          tatDays={tatDays}
          estData={estData}
          approvalInitialData={approvalInitialData}
          setApprovalInitialData={setApprovalInitialData}
        />
      )}
      {action === "View" && (
        <DetailedOverview
          handleClose={() => setAction(null)}
          selectedData={selectedActionRowData}
          currentSelectedOrder={currentSelectedOrder} />
      )}  {action === "approve_all" && (
        <ApproveAll
          handleClose={() => setAction(null)}
          currentSelectedOrder={currentSelectedOrder}
          getList={getList}
          getActionIndicators={getActionIndicators}
        />
      )}
      {(action === "customer_Approval" || action === "view_customer_Approval") && (
        <CustomerApprovalNew
          getApprovedPosition={getApprovedPosition}
          currentSelectedId={selectedRowData?.id}
          handleClose={() => {
            setAction(null)
            getEstimatedDetails()

          }}
          approveData={selectedRowData}
          actionMode={action}
          currentSelectedOrder={currentSelectedOrder}
        />
      )}
      {action === "est_history" && (
        <EstimationHistory
          currentSelection={currentSelection}
          handleClose={() => setAction(null)}

        />
      )}
      {action === "edit_est" && (
        <UpdateEstimation
          currentSelection={currentSelection}
          handleClose={() => {
            setAction(null)
            getEstimatedDetails()
          }}
          id={currentSelectedOrder.id}
          date={formatedDate}
        />
      )}
      {/* {action === "report" &&
        <QuotationReport
          open={true}
          handleClose={() => setAction(null)}
          quoteReport={currentSelection}
        />} */}
      {labourAction && labourAction === 'labour' && <LabourPopup handleClose={() => setlabourAction(null)} open={true} currentRow={currentRow} />}
    </div>
  );
}

export default Commercial;
