import { CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Typography, makeStyles, Grid } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import MROButton from 'components/buttons'
import FileUpload from 'components/form_components/fileUpload'
import tableIcons, { options } from 'components/universal/table_attributes'
import { BASE_API_URL_BOXR } from 'lib/constants'
import { ICON_COLOUR } from 'lib/constants/style_constants'
import { ToastMessageContext } from 'lib/contexts/message_context'
import PartsClientServices from 'lib/services/api/stores/tooling/parts_client'
import MaterialTable from 'material-table'
import React, { useContext, useEffect, useState } from 'react'
import GreenTick from 'assets/CheckTick.svg'
import CheckBoxIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { getOrganisationId } from 'lib/utils/common_utils'

const useStyles = makeStyles((theme) => ({

    dialogTitle: {
        color: theme.palette.primary.main,
        fontSize: '18px'
    },
    modal: {
        "&.MuiDialog-paperWidthMd": {
            width: "563px",
        },
    },
    actionButtons: {
        paddingTop: "5px",
        paddingBottom: "20px",
        textAlign: "right",
        marginRight: "20px",
        "& button:not(:last-child)": {
            marginRight: "20px",
        },
    },

}))

function PartsCertificate(props) {
    const classes = useStyles()
    const { handleClose, selectedSecondaryPartId, selectedSecondaryPart, dialogCloseHandler } = props
    const [loading, setLoading] = useState(true)
    const [files, setFiles] = useState([])
    const [edit, setEdit] = useState(false)
    const [boolState, setBoolState] = useState(false);
    const [deletedStatus, setDeletedStatus] = useState(false);
    const [records, setRecords] = useState([]);
    const [busy, setBusy] = useState(null);
    const [tableData, setTableData] = useState([])
    const [tableLoading, setTableLoading] = useState(false)
    const message = useContext(ToastMessageContext)
    // console.log(selectedSecondaryPartId)

    const fetchFiles = () => {
        if (selectedSecondaryPartId) {
            setLoading(true)
            PartsClientServices.fetchMaterialCertificates(
                selectedSecondaryPartId
            ).then((response) => {
                setFiles(response.data)
                setTableData(response.certification_requirement);
            }).catch(() => {

            }).finally(() => {
                setLoading(false)
            })
        }
    };

    // // for fetching files
    useEffect(() => {
        fetchFiles();
    }, [selectedSecondaryPartId]);


    const deleteFileHandler = (fileId) => {
        // setSelectedFileId(fileId);
        setBusy("file-deleting");
        PartsClientServices.deleteFile(fileId)
            .then((res) => {
                message.showToastMessage({
                    message: "File deleted successfully",
                    variant: "success",
                });
                fetchFiles();
            })
            .catch((err) => {
                message.showToastMessage({
                    message: "Something went wrong",
                    variant: "error",
                });
                setBusy("file-deleting-error");
            })
            .finally(() => {
                setBusy(null);
            });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        // formData.append("task_id", currentSelection?.id);
        if (records.length > 0) {
            records.forEach((item) => formData.append("certificates[]", item));

            formData.append("id", selectedSecondaryPartId);
            formData.append("part_type", "SecondaryPart");
            setTableLoading(true)
            PartsClientServices.postDocument(formData)
                .then((res) => {
                    message.showToastMessage({
                        message: " File added successfully",
                        variant: "success",
                    });
                    dialogCloseHandler();
                })
                .catch((err) => {
                    message.showToastMessage({
                        message: "Something went wrong",
                        variant: "error",
                    });
                })
                .finally(() => {
                    setTableLoading(false);
                });
        }
        else {
            dialogCloseHandler()
        }

    };
    useEffect(() => {
        if (records.length) {
            setDeletedStatus(false);
        }
    }, [records]);

    const tableOptions = {
        ...options,
        paging: false,
        search: false,
        toolbar: false
    };


    const handleCertificateStatus = (rowData) => {
        const params = {
            secondary_part_id: selectedSecondaryPartId,
            organisation_id: getOrganisationId(),
            data: [
                {
                    id: rowData?.id,
                    status: rowData?.state === true ? false : true
                },

            ]
        }

        PartsClientServices.updateSecPartsCertification(params).then((res) => {
            // setBoolState(false)
            fetchFiles()
        })

    }




    const columns = [{
        title: "Part Certificates",
        field: "name",
        width: '80%',
        headerStyle: {
            width: '80%',
            textAlign: 'left',
            paddingLeft: '15px'
        },

        cellStyle: {
            textAlign: 'left',
            width: '80%',
            paddingLeft: '15px'
        }
    },
    {
        title: "Status",
        field: "status",
        headerStyle: {

            textAlign: 'center'
        },

        cellStyle: {
            textAlign: 'center'

        },
        render: (rowData) =>
        (
            rowData?.state && boolState ?
                <IconButton onClick={() => {
                    handleCertificateStatus(rowData)
                }}>
                    <img src={GreenTick} height={'16px'} />
                </IconButton> : !rowData?.state && boolState ? <IconButton onClick={() => {
                    handleCertificateStatus(rowData)
                }}>
                    <CheckBoxIcon fontSize='14px' />
                </IconButton> : rowData?.state ?
                    <img src={GreenTick} height={'16px'} />
                    : ''

        )
    }
    ]



    return (
        <div>
            <Dialog
                maxWidth={"md"}
                className={classes.modal}
                aria-labelledby="part-certificate"
                onClose={dialogCloseHandler}
                classes={{ root: classes.modal }}

                open={true}
            >

                <DialogTitle >
                    <Grid container>

                        <Grid xs={11} style={{ marginLeft: '10px' }}><Typography variant='subtitle2' className={classes.dialogTitle}>Part Certificates</Typography>
                        </Grid>

                        {props.permission?.write ? <Grid><IconButton onClick={() => {
                            setBoolState(true)

                        }}>

                            <Edit htmlColor={ICON_COLOUR} style={{ fontSize: '15px' }} />
                        </IconButton>
                        </Grid> : ""}
                    </Grid>
                </DialogTitle>




                <DialogContent style={{ width: '693px' }} >
                    <form noValidate onSubmit={handleSubmit}>
                        <FileUpload
                            isLoading={loading}
                            defaultValue={files !== undefined ? files.map((file) => ({
                                filename: file?.filename,
                                url: file?.url,
                                id: file?.id,
                            })) : ''}
                            setFiles={(files) => {
                                setRecords(files);
                            }}
                            accept={"multiple"}
                            onDelete={boolState === false ? null : deleteFileHandler}
                            showBorderOnNoFiles={true}
                            multiple={true}
                            APIUrl={BASE_API_URL_BOXR}
                            isDeleting={busy === "file-deleting"}
                            uploadButtonView={boolState === false}
                            // name={"certificates[]"}
                            action={"Upload Document"} />

                        <br />
                        <div style={{ marginBottom: '20px' }}>
                            <MaterialTable

                                style={{ boxShadow: "0px 1px 3px #00000033", marginTop: "10px" }}
                                icons={tableIcons}
                                classes
                                title={""}
                                columns={columns}
                                data={tableData || []}
                                // isLoading={loading}
                                options={tableOptions}
                            />

                        </div>


                        {boolState === true && (
                            <div className={classes.actionButtons}>
                                <MROButton variant={"contained"}
                                    onClick={dialogCloseHandler}>
                                    Cancel
                                </MROButton>
                                <MROButton
                                    loading={tableLoading}
                                    variant={"contained"}
                                    color={"primary"}
                                    type={"submit"}
                                >
                                    Done
                                </MROButton>
                            </div>
                        )}
                    </form>
                </DialogContent>
            </Dialog>

        </div>
    )
}


export default PartsCertificate