import React, { useState, useEffect, useReducer, useContext } from "react";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import MaterialTable from "material-table";
import tableIcons, { options } from "components/universal/table_attributes";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { ICON_COLOUR } from "lib/constants/style_constants";
import { ToastMessageContext } from "lib/contexts/message_context";
import { getOrganisationId } from "lib/utils/common_utils";
import AddIconBox from "@material-ui/icons/AddBox";
import CreateProjectParts from "../QuotePopUp/CreateProjectParts";
import UpdateProjectParts from "./UpdateProjectParts";
import { Visibility } from "@material-ui/icons";


function ProjectParts(props) {
    const { currentSelection, workshopOrderId, tabState, disabled, tab, actions, setActions, orderStateNum } = props
    // const [actions, setActions] = useState(null)
    const [currentSelectedData, setCurrentSelectedData] = useState({})
    const [projectParts, setProjectParts] = useState([])
    const [projectLoading, setProjectLoading] = useState(false)

    const message = useContext(ToastMessageContext)

    const tableOptions1 = {
        ...options,
        paging: false,
        search: false,
        toolbar: true,
    };
    const columns1 = [
        {
            title: "Item",
            field: "item",
            width: "4%",
            // render: (rowData) => <span>&nbsp;</span>,
        },
        {
            title: "Part #",
            field: "part_number",

            headerStyle: {
                textAlign: "left",
                width: "12%"
            },
            cellStyle: {
                textAlign: "left",

                width: "12%"
            }
        },
        {
            title: "Description",
            field: "description",

            headerStyle: {
                textAlign: "left",
                width: "20%"
            },
            cellStyle: {
                textAlign: "left",
                paddingLeft: '15px',
                width: "20%"
            }
        }, {
            title: "Serial #s",
            field: "serial_numbers",

            headerStyle: {
                textAlign: "left",
                width: "60%"
            },
            cellStyle: {
                textAlign: "left",
                paddingLeft: '15px',
                width: "60%"
            }
        },
        {
            title: "Quantity",
            field: "quantity",


        },

        {
            title: "Action",
            field: "",
            headerStyle: {
                textAlign: "left",
            },
            cellStyle: {
                textAlign: "left",

            },
            render: (rowData) =>

                <IconButton
                    // disabled={!props.permission.write || disabled}
                    // style={{ display: !props.permission.write && "none" }}
                    onClick={() => {
                        setActions("edit_parts");
                        setCurrentSelectedData(rowData);
                    }}
                >
                    <Visibility fontSize="small" htmlColor={ICON_COLOUR} />
                </IconButton>

        }

    ];

    useEffect(() => {

        getProjectParts()

    }, [currentSelectedData])


    const getProjectParts = async () => {
        const params = {
            organisation_id: getOrganisationId(),
            reportable_id: workshopOrderId,
            // project_primary_part_id: currentSelection.primary_part_id,
            reportable_type: "WorkshopOrder"

        }
        setProjectLoading(true)
        await MaintenanceServices.getAllProjectParts(params)
            .then((res) => {
                if (res) {
                    setProjectParts(res.data);
                }
                // setLoading(false);
            })
            .catch((err) => {
                message.showToastMessage({
                    message: "Something went wrong. Try again!",
                    variant: "error",
                });
                // setLoading(false);
            }).finally(() => {
                setProjectLoading(false)
            })
    };


    return (
        <div style={{ marginBottom: "15px" }}>
            <MaterialTable
                style={{ boxShadow: "0px 1px 3px #00000033", marginTop: "10px" }}
                icons={tableIcons}
                title={""}
                isLoading={projectLoading}
                columns={columns1}
                data={projectParts || []}
                options={tableOptions1}
                localization={{
                    body: {
                        emptyDataSourceMessage: "No Records Found",
                    },
                }}
                actions={props?.permission.write && [
                    {
                        icon: () => (

                            <AddIconBox color="secondary" />

                        ),
                        tooltip: "Add",
                        hidden: disabled || orderStateNum > 2,
                        isFreeAction: true,
                        onClick: (rowData) => {
                            setActions('add_parts')
                            setCurrentSelectedData(rowData)
                        }
                    },
                ]}
            />
            {
                actions && actions === 'add_parts' &&
                <CreateProjectParts
                    handleClose={() => setActions(null)}
                    action={actions}
                    getPartDetails={getProjectParts}
                    id={workshopOrderId}
                    primeId={{ id: currentSelection.primary_part_id }}
                    tab={"quote"}
                    quoteStatus={true}

                />
            }
            {
                actions && actions === 'edit_parts' &&
                <UpdateProjectParts
                    handleClose={() => {
                        setActions(null)
                        setCurrentSelectedData({})
                    }}
                    action={actions}
                    getPartDetails={getProjectParts}
                    id={workshopOrderId}
                    currentSelection={currentSelectedData}
                    projectParts={projectParts}
                    setCurrentSelectedData={setCurrentSelectedData}
                    primeId={{ id: currentSelection.primary_part_id }}
                    workshopDetails={currentSelection}
                    disabled={disabled}
                    orderStateNum={orderStateNum}

                />
            }
        </div>
    )
}

export default ProjectParts