import React, { useContext, useMemo, useState } from "react";
import {
  Typography,
  makeStyles,
  Grid,
  Box,
  MenuItem,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import MROTextField from "components/form_components/TextField";
import QrCodeIcon from "assets/icons/qr-code.png";
import MROButton from "components/buttons";
import UploadButton from "components/form_components/upload_multiple";
import FileUpload from "components/form_components/fileUpload";
import { useEffect } from "react";
import { ToastMessageContext } from "lib/contexts/message_context";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import {
  fixedInputValueTo2DecimalPlaces,
  removeSecFromDateTime,
  validateFormData,
} from "lib/utils/helperFunctions";
import YesNoField from "components/form_components/YesNoField";
import PrintLable from "./PrintLable";
import { BASE_API_URL_BOXP } from "lib/constants";
import lock from "../../../../../assets/icons/Lock.svg";
import unlock from "../../../../../assets/icons/Unlock.svg";

import moment from "moment";
import { WorkshopContext } from "../../WorkshopContext/WorkshopContext";
import ManageAddress from "../../Release/AddAddress";
import Add from "@material-ui/icons/Add";


const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "85%",
    margin: "1% 3%",
  },
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
  paper: {
    borderRadius: "5px",
    backgroundColor: "#F5F5F5",
    padding: theme.spacing(3),
    boxShadow: "0px 1px 4px #0000001A",
  },
  partValue: {
    fontSize: "14px",
    color: "#4D4F5C",
    padding: "2px",
    display: "flex",
    alignItems: "center",
  },
  partKey: {
    "& p": {
      fontSize: "14px",
      padding: "1px",
    },
  },
  partCard: {
    borderRadius: "8px",
    padding: "17px 24px",
    "& p": {
      fontSize: "14px",
      padding: "5px",
    },
  },
  sec1: {
    borderRadius: "5px",
    backgroundColor: "#fff",
    padding: theme.spacing(3),
    boxShadow: "0px 1px 4px #0000001A",
  },
  fileView: {
    backgroundColor: "#ffffff",
    padding: "45px",
    border: "1px solid gray",
    borderRadius: " 8px",
    marginBottom: "12px",
  },
  viewOnlyField: {
    pointerEvents: "none",
    background: "#F5F5F5",
  },

  svg: {
    filter: "invert(1)",
    height: 18,
    marginBottom: 2,
  },
}));

const numFields = ["weight", "length", "width", "height"];

export default function Induction(props) {
  const classes = useStyles();
  const {
    data,
    inductionDetails,
    getInductionList,
    isGettingDetails,
    editTab,
    setEditTab,
    access,
    getHeaderDetails,
    orderStateNum,
    tabStatus,
    closeTabStatus,
    getWorkshopOrderDetails,
    setIsGettingDetails,
  } = props;
  const [value, setValue] = useState(null);
  // const [editTab, setEditTab] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [aogUpdate, setAogUpdate] = useState();
  const [errors, setErrors] = useState({});
  const message = useContext(ToastMessageContext);
  const { editButton, setEditButton } =
    useContext(WorkshopContext);
  const [action, setAction] = useState(null);
  const [busy, setBusy] = useState(null);
  const [closeTab, setCloseTab] = useState(false);
  const [customerOrderDoc, setCustomerOrderDoc] = useState([]);
  const [shippingFiles, setShippingFiles] = useState([]);
  const [itemConditionFiles, setItemConditionFiles] = useState([]);
  const [partSerialFiles, setPartSerialFiles] = useState([]);
  const [vehicleList, setVehicleList] = useState([])
  const [customerAddress, setCustomerAddress] = useState([])
  const [filesCus, setFilesCus] = useState([])
  const [addressAction, setAddAction] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [selectedAddressDetails, setSelectedDetailsAddress] = useState(null)
  const [distance, setDistance] = useState(0)

  const [fieldsData, setFieldsData] = useState({
    receipt_by_stamp: null,
    scheduled_date: null,
    arrival_condition: null,
    weight: null,
    length: null,
    width: null,
    height: null,
    physical_check_status: null,
    aog_status: null,
    customer_order_number: null,
    secondary_customer_ref: null,
    receipt_type: null,
    receipt_cost: null,
    collection_address_id: null,
    vehicles_id: null

  });
  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = numFields.includes(name)
      ? fixedInputValueTo2DecimalPlaces(e.target.value)
      : e.target.value;

    resetErrorsHandler(name);
    setFieldsData((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    let tempData = {};
    Object.keys(inductionDetails).forEach((key) => {
      tempData[key] = inductionDetails[key];
    });

    if (!Object.keys(inductionDetails).length) {
      tempData["secondary_customer_ref"] = data?.secondary_customer_ref;
      tempData["customer_order_number"] = data["cus-ref"];
    }

    setFieldsData(tempData);
    setValue(
      inductionDetails.physical_check_status === true
        ? "yes"
        : inductionDetails.physical_check_status === false
          ? "no"
          : null
    );

    const aogStatus = inductionDetails?.aog_status ?? data.aog_status;

    setAogUpdate(
      aogStatus === true ? "yes" : aogStatus === false ? "no" : null
    );
  }, [inductionDetails]);


  const handleCloseTab = () => {
    getInductionList()
    getWorkshopOrderDetails();
    setEditTab(!editTab)
    setDisabled(!disabled);
  }


  const getVehicleList = () => {
    MaintenanceServices.getVehicleList().then((res) => {
      setVehicleList(res.data)
    }).catch(() => {

    })


  }

  const getCustomerAddress = () => {
    const param = {
      workshop_order_id: data?.id,
    };
    MaintenanceServices.getCustomerAddress(param).then((res) => {
      if (res.success) {
        setCustomerAddress(res.data)
        if (selectedAddress !== null) {

          setFieldsData({ ...fieldsData, collection_address_id: selectedAddress })
        }

      }
    }).catch(() => {

    })

  }

  useEffect(() => {
    getVehicleList()
  }, [])

  useEffect(() => {

    getCustomerAddress()
  }, [])

  useEffect(() => {

    if (selectedAddressDetails !== null && selectedAddressDetails !== undefined && data !== undefined) {
      const service = new window.google.maps.DistanceMatrixService();

      // latitude if needed
      const origin1 = { lat: parseFloat(`${data?.base_station_latitude}`), lng: parseFloat(`${data?.base_station_longitude}`) };
      const destinationB = { lat: parseFloat(`${selectedAddressDetails?.latitude}`), lng: parseFloat(`${selectedAddressDetails?.longitude}`) };
      //address
      const origin2 = `${data?.base_station_address}`;
      const destinationA = `${selectedAddressDetails?.address}`;

      const request = {
        origins: [origin2],
        destinations: [destinationA],
        travelMode: window.google.maps.TravelMode.DRIVING,
        unitSystem: window.google.maps.UnitSystem.IMPERIAL,
        avoidHighways: false,
        avoidTolls: false,
      };
      // The total distance of this route, expressed in meters (value) and as text. The textual value is formatted according to the unitSystem specified in the request (or in metric, if no preference was supplied).
      service.getDistanceMatrix(request).then((response) => {

        if (response?.rows[0].elements[0].status === "OK") {
          // const string = response.rows[0].elements[0].distance.text
          // let string1 = string.split(" ")
          // setDistance(string1[0])
          let string2 = response.rows[0].elements[0].distance.value
          setDistance(string2)
        }
        else {
          setDistance(0)
        }

      }).catch((err) => {
        console.log("could not find placess")
      })
    }


  }, [selectedAddressDetails])

  useEffect(() => {
    if (fieldsData.collection_address_id !== null && customerAddress.length > 0) {
      let address = customerAddress.filter((item) => item.id === fieldsData.collection_address_id)

      setSelectedDetailsAddress(address[0])
    }
  }, [fieldsData])

  const handleEditAPiCall = async () => {
    setIsGettingDetails(true);
    const param = {
      workshop_order_id: data?.id,
      tab: "induction"
    };
    await MaintenanceServices.editButton(param)
      .then((res) => {
        if (res.success) {
          getWorkshopOrderDetails();
          setEditButton(!editButton);
        }
      })
      .catch((err) => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
        setBusy(null);
      })
      .finally(() => {
        setBusy(null);
        setIsGettingDetails(false);
      });
  };

  useEffect(() => {
    if (editButton) {
      handleEditAPiCall();
    }
  }, [editButton])

  const handleTab = () => {
    setEditButton(true)
    setEditTab(!editTab);
    setDisabled(!disabled);
  };
  const handleToggle = (value) => {
    setValue(value);
  };

  const resetErrorsHandler = (name) => {
    setErrors((e) => ({ ...e, [name]: false }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);

    const { error, valid } = validateFormData(formData, [
      "secondary_customer_ref",
    ]);

    shippingFiles.forEach((file) =>
      formData.set("shipping_condition_photo[]", file)
    );
    itemConditionFiles.forEach((file) =>
      formData.set("item_condition_photo[]", file)
    );
    partSerialFiles.forEach((file) => formData.set("plate_photo[]", file));

    if (
      customerOrderDoc.length === 0 &&
      !inductionDetails?.customer_order_doc?.length
    ) {
      setErrors((er) => ({ ...er, customer_order_doc: true }));
    }

    if (
      (!valid && !disabled) ||
      (customerOrderDoc.length === 0 &&
        !inductionDetails?.customer_order_doc?.length)
    ) {
      setErrors((err) => ({ ...err, ...error }));
      return;
    }
    customerOrderDoc.forEach((file) => {
      formData.append("customer_order_doc[]", file)
      // console.log("customer order doc", file)
    }
    );
    if (fieldsData.receipt_type === "mro_collect") {

      formData.append("collection_distance", distance)
    }
    // const cusOrder = [];
    // customerOrderDoc.forEach((_, i) => {
    //   cusOrder[i] = Array.from(customerOrderDoc[i]);
    //   cusOrder[i].forEach((file) =>
    //     formData.append("customer_order_doc[]", file)
    //   );
    // });

    formData.append("workshop_order_id", inductionDetails?.workshop_order_id);
    // formData.delete("date_time_good_in");
    formData.delete("date");
    formData.set(
      "date",
      moment(inductionDetails?.date, "DD/MM/YYYY HH:mm:ss").format(
        "YYYY-MM-DD HH:mm:ss"
      )
    );
    formData.set(
      "date_time_good_in",
      moment(inductionDetails?.date_time_good_in, "DD/MM/YYYY HH:mm:ss").format(
        "YYYY-MM-DD HH:mm:ss"
      )
    );
    formData.append("induction_tab_style", 1);
    formData.append("receipt_by", inductionDetails?.receipt_by);
    if (closeTab) {
      formData.append("edit_tab", true)
    }
    setBusy("loading");
    if (addressAction === null) {
      try {
        const response = await MaintenanceServices.updateInductionTabData(
          formData,
          data.induction_id
        );
        if (response.success) {
          getHeaderDetails();
          getInductionList();
          handleCloseTab();
          setFilesCus(response.data.customer_order_doc)
          message.showToastMessage({
            message: "Updated Successfully!",
            variant: "success",
          });
        } else {
          message.showToastMessage({
            message:
              response.error.length > 0
                ? response.error?.join(", ")
                : "Something went wrong!",
            variant: "error",
          });
        }
        console.table("response", response);
        setBusy(null);
      } catch (error) {
        setBusy("error");
        message.showToastMessage({
          message: "Something went wrong",
          variant: "error",
        });
      }
    }
  };

  const deleteFileHandler = async (id) => {
    setBusy("file-deleting");
    try {
      const response = await MaintenanceServices.deleteFile(id);

      if (response.success) {
        message.showToastMessage({
          message: "File deleted successfully",
          variant: "success",
        });
        getInductionList();
      }
      setBusy(null);
    } catch (error) {
      message.showToastMessage({
        message: "Something went wrong",
        variant: "error",
      });
      setBusy("file-deleting-error");
    }
  };

  const customerOrderFiles = useMemo(() => {
    const files = !Object.keys(inductionDetails).length
      ? data?.customer_order_doc
      : inductionDetails?.customer_order_doc;

    return files?.map((file) => ({
      name: file.file_name,
      url: file.path,
    }));
  }, [data, inductionDetails]);

  if (isGettingDetails) {
    return (
      <Box
        width="100%"
        minHeight="400px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" size={40} />
      </Box>
    );
  }

  return (
    <div>
      <form noValidate onSubmit={submitHandler}>
        <Grid>
          <Typography
            color="primary"
            varient="h3"
            style={{ marginBottom: "10px" }}
          >
            Receipt
          </Typography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <MROTextField
              error={errors.receipt_by_stamp}
              name={"receipt_by_stamp"}
              label={"Receipt By"}
              variant="outlined"
              placeholder={""}
              required
              value={fieldsData.receipt_by_stamp}
              InputLabelProps={{ shrink: true }}
              className={classes.viewOnlyField}
              onChange={onChangeHandler}
            ></MROTextField>
          </Grid>
          <Grid
            item
            xs={3}
          // style={{ marginTop: "8px" }}
          // className={classes.viewOnlyField}
          >
            {/* <DateTimeField
              required
              fullWidth
              format="ll - HH:mm"
              label={"Date / Time*"}
              disabled={true}
              name="date_time_good_in"
              value={fieldsData.date_time_good_in}
              onChange={onChangeHandler}
              defaultValue={inductionDetails?.date_time_good_in}
              InputLabelProps={{ shrink: true }}
            /> */}
            <MROTextField
              error={errors.date_time_good_in}
              name={"date_time_good_in"}
              required
              label={"Date / Time"}
              placeholder={""}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={removeSecFromDateTime(inductionDetails?.date_time_good_in)}
              className={classes.viewOnlyField}
            ></MROTextField>
          </Grid>

          <Grid item xs={3}>
            <MROTextField
              error={errors.receipt_type}
              name={"receipt_type"}
              label={"Receipt Method *"}
              variant="outlined"
              placeholder={"Excellent"}
              className={disabled ? classes.viewOnlyField : null}
              select
              value={fieldsData.receipt_type}
              InputLabelProps={{ shrink: true }}
              onChange={onChangeHandler}
            >
              <MenuItem disabled value={null}>
                Select
              </MenuItem>
              <MenuItem value={"customer_delivery"}>Customer Delivery</MenuItem>
              <MenuItem value={"mro_collect"}>MRO Collect</MenuItem>
              <MenuItem value={"mro_arranged_third_party_agent"}>MRO Arranged 3rd Party Agent</MenuItem>

            </MROTextField>
          </Grid>
        </Grid>
        <Grid container spacing={3}>

          {fieldsData.receipt_type === "mro_collect" &&
            <>
              <Grid item xs={3}>
                <MROTextField
                  error={errors.vehicles_id}
                  name={"vehicles_id"}
                  label={"Vehicle Used *"}
                  variant="outlined"
                  placeholder={"Excellent"}
                  className={disabled ? classes.viewOnlyField : null}
                  select
                  value={fieldsData.vehicles_id}
                  InputLabelProps={{ shrink: true }}
                  onChange={onChangeHandler}
                >
                  <MenuItem disabled value={null}> Select</MenuItem>
                  {vehicleList.map((item) =>
                    <MenuItem value={item.id}>{item.registration}</MenuItem>

                  )}
                </MROTextField>
              </Grid>

              <Grid
                item
                xs={6}

              >
                <MROTextField
                  error={errors.collection_address_id}
                  name={"collection_address_id"}
                  label={"Collection Address *"}
                  placeholder={"Enter distance driven"}
                  variant="outlined"
                  select
                  InputLabelProps={{ shrink: true }}
                  className={disabled ? classes.viewOnlyField : null}
                  value={fieldsData?.collection_address_id}
                  onChange={onChangeHandler}
                >
                  <MenuItem disabled value={null}> Select</MenuItem>
                  {customerAddress.map((item) =>
                    <MenuItem value={item.id} key={item.id}>{item.address}</MenuItem>

                  )}
                </MROTextField>
              </Grid>
              <Grid item style={{ marginTop: '21px' }}>
                {disabled ? "" : <IconButton style={{ outline: 'auto' }} onClick={() => {
                  setAddAction("create")
                }}><Add /></IconButton>}

              </Grid>
            </>}
          {fieldsData.receipt_type === "mro_arranged_third_party_agent" && <Grid
            item
            xs={3}
          >
            <MROTextField
              error={errors.receipt_cost}
              name={"receipt_cost"}
              label={"3rd Party Carrier Cost *"}
              placeholder={"Enter the cost of shipping"}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={fieldsData.receipt_cost}
              className={disabled ? classes.viewOnlyField : null}
              onChange={onChangeHandler}
            ></MROTextField>
          </Grid>}
        </Grid>
        <Grid>
          <Typography
            color="primary"
            varient="h3"
            style={{ marginTop: "10px" }}
          >
            Shipping Details
          </Typography>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: "10px" }}>
          <Grid item xs={3}>
            <MROTextField
              error={errors.arrival_condition}
              name={"arrival_condition"}
              label={"Arrival Condition"}
              variant="outlined"
              placeholder={"Excellent"}
              className={disabled ? classes.viewOnlyField : null}
              required
              select
              value={fieldsData.arrival_condition}
              InputLabelProps={{ shrink: true }}
              onChange={onChangeHandler}
            >
              <MenuItem disabled value={null}>
                Select
              </MenuItem>
              <MenuItem value={"Excellent"}>Excellent</MenuItem>
              <MenuItem value={"Good"}>Good</MenuItem>
              <MenuItem value={"Fair"}>Fair</MenuItem>
              <MenuItem value={"Poor"}>Poor</MenuItem>
            </MROTextField>
          </Grid>
          <Grid item xs={3}>
            <MROTextField
              error={errors.weight}
              name={"weight"}
              label={"Weight (kg) *"}
              placeholder={"Shipping weight kg"}
              variant="outlined"
              defaultValue={
                inductionDetails !== undefined && inductionDetails.weight
              }
              className={disabled ? classes.viewOnlyField : null}
              requird
              value={fieldsData.weight}
              InputLabelProps={{ shrink: true }}
              onChange={onChangeHandler}
            ></MROTextField>
          </Grid>
          <Grid item xs={2}>
            <MROTextField
              error={errors.length}
              name={"length"}
              label={"L (cm)"}
              className={disabled ? classes.viewOnlyField : null}
              required
              value={fieldsData.length}
              placeholder={"Length"}
              InputLabelProps={{ shrink: true }}
              onChange={onChangeHandler}
            ></MROTextField>
          </Grid>
          <Grid item xs={2}>
            <MROTextField
              error={errors.width}
              name={"width"}
              label={"W (cm)"}
              className={disabled ? classes.viewOnlyField : null}
              required
              value={fieldsData.width}
              placeholder={"Width"}
              InputLabelProps={{ shrink: true }}
              onChange={onChangeHandler}
            ></MROTextField>
          </Grid>
          <Grid item xs={2}>
            <MROTextField
              error={errors.height}
              name={"height"}
              label={"H (cm)"}
              className={disabled ? classes.viewOnlyField : null}
              required
              value={fieldsData.height}
              placeholder={"Height"}
              InputLabelProps={{ shrink: true }}
              onChange={onChangeHandler}
            ></MROTextField>
          </Grid>
        </Grid>

        <Grid>
          <Typography
            color="secondary"
            variant="h6"
            style={{ marginBottom: "10px", marginTop: "10px" }}
          >
            Shipping Condition Photos
          </Typography>
        </Grid>

        <div>
          <FileUpload
            defaultValue={
              inductionDetails.shipping_condition_photo !== undefined
                ? inductionDetails.shipping_condition_photo.map((item) => ({
                  filename: item?.file_name,
                  url: `${item?.file_path}`,
                  id: item?.id,
                }))
                : ""
            }
            accept={"multiple"}
            setFiles={setShippingFiles}
            APIUrl={""}
            uploadButtonView={editTab === false}
            multiple={true}
            name={"shipping_condition_photo[]"}
            action={"Upload Document"}
            id="shipping_condition_photo[]"
            onDelete={disabled ? null : deleteFileHandler}
            isDeleting={busy === "file-deleting"}
          />
        </div>

        <Grid>
          <Typography
            color="primary"
            varient="h3"
            style={{ marginBottom: "10px", marginTop: "10px" }}
          >
            Item Details
          </Typography>
        </Grid>

        <Box display="flex" style={{ marginBottom: "10px" }}>
          <Box flex={50} className={classes.partKey}>
            <Typography color="secondary" variant="h6">
              Item Condition Photos
            </Typography>
          </Box>
        </Box>

        <FileUpload
          defaultValue={
            inductionDetails.item_condition_photo !== undefined
              ? inductionDetails.item_condition_photo.map((item) => ({
                filename: item?.file_name,
                url: `${item?.file_path}`,
                id: item?.id,
              }))
              : ""
          }
          APIUrl={""}
          uploadButtonView={editTab === false}
          multiple={true}
          name={"item_condition_photo[]"}
          setFiles={setItemConditionFiles}
          action={"Upload Document"}
          id="item_condition_photo[]"
          onDelete={disabled ? null : deleteFileHandler}
          isDeleting={busy === "file-deleting"}
          accept={"multiple"}
        />

        <Box
          className={disabled || access.read ? classes.viewOnlyField : null}
          style={{ background: "transparent" }}
        >
          <YesNoField
            label="P/N &amp; S/N Physically Checked / Correct against this Order AND shipping documentation? *"
            name="physical_check_status"
            handleToggle={handleToggle}
            value={value}
            error={errors.physical_check_status}
            setError={() => resetErrorsHandler("physical_check_status")}
          />
        </Box>

        <Box display="flex" style={{ marginBottom: "10px" }}>
          <Box flex={50} className={classes.partKey}>
            <Typography color="secondary" variant="h6">
              Part / Serial / Data plate photos:
            </Typography>
          </Box>
        </Box>

        <FileUpload
          defaultValue={
            inductionDetails.plate_photo !== undefined
              ? inductionDetails.plate_photo.map((item) => ({
                filename: item?.file_name,
                url: `${item?.file_path}`,
                id: item?.id,
              }))
              : ""
          }
          APIUrl={""}
          uploadButtonView={editTab === false}
          multiple={true}
          setFiles={setPartSerialFiles}
          name={"plate_photo[]"}
          action={"Upload Document"}
          id="plate_photo[]"
          onDelete={disabled ? null : deleteFileHandler}
          isDeleting={busy === "file-deleting"}
          accept={"multiple"}
        />

        <Grid>
          <Typography
            color="primary"
            varient="h3"
            style={{ marginBottom: "10px", marginTop: "10px" }}
          >
            Order Details
          </Typography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <MROTextField
              error={errors.customer_order_number}
              name={"customer_order_number"}
              label={"Customer Order #"}
              inputProps={{ maxLength: 30 }}
              variant="outlined"
              placeholder={"Purchase Order, or Service Order ref"}
              required
              value={fieldsData.customer_order_number}
              className={disabled ? classes.viewOnlyField : null}
              InputLabelProps={{ shrink: true }}
              onChange={onChangeHandler}
            ></MROTextField>
          </Grid>
          <Grid item xs={3}>
            <MROTextField
              error={errors.secondary_customer_ref}
              name={"secondary_customer_ref"}
              label={"Secondary Customer Ref"}
              inputProps={{ maxLength: 30 }}
              placeholder={"Aircraft Reg, or other customer ref"}
              variant="outlined"
              className={disabled ? classes.viewOnlyField : null}
              value={fieldsData.secondary_customer_ref}
              InputLabelProps={{ shrink: true }}
              onChange={onChangeHandler}
            ></MROTextField>
          </Grid>
          <Grid item xs={4}>
            <Box
              mt="20px"
              className={disabled || access.read ? classes.viewOnlyField : null}
              style={{ background: "transparent" }}
            >
              <YesNoField
                label="AOG?*"
                name="aog_status"
                handleToggle={(val) => setAogUpdate(val)}
                value={aogUpdate}
                error={errors.aog_status}
                setError={() => resetErrorsHandler("aog_status")}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ margin: "20px 0px 20px" }}>
          <Grid
            item
            xs={12}
            // className={disabled ? classes.viewOnlyField : undefined}
            style={{ backgroundColor: "transparent" }}
          >
            <Box flex={50} className={classes.partKey} style={{ marginBottom: '15px' }}>
              <Typography color="secondary" variant="h6" >
                Customer Order Doc *
              </Typography>
            </Box>
            <FileUpload
              defaultValue={
                inductionDetails.customer_order_doc !== undefined
                  ? inductionDetails.customer_order_doc.map((item) => ({
                    filename: item?.file_name,
                    url: `${item?.file_path}`,
                    id: item?.id,
                  }))
                  : ""
              }
              accept={"multiple"}
              APIUrl={''}
              uploadButtonView={editTab === false}
              multiple={true}
              setFiles={(files) => {
                setCustomerOrderDoc(files);
                resetErrorsHandler("customer_order_doc", false);
              }}
              name={"customer_order_doc[]"}
              label=""
              action={"Upload Document"}
              onDelete={disabled ? null : deleteFileHandler}
              isDeleting={busy === "file-deleting"}

            />
            {errors.customer_order_doc && editTab ? <span style={{ color: 'red', marginTop: '10px' }} >*Please upload customer order document </span> : ''}
            {/* <UploadButton
              multiple={true}
              readOnly={disabled}
              accept={"application/pdf"}
              action={"Upload Document"}
              defaultValue={filesCus.length > 0 ? filesCus.map((file) => ({
                name: file.file_name,
                url: file.file_path,
              })) :
                data?.customer_order_doc?.map((file) => ({
                  name: file.file_name,
                  url: file.file_path,
                }))}
              // defaultValue={customerOrderFiles}
              error={errors.customer_order_doc}
              name={"customer_order_doc[]"}
              label="Customer Order Doc *"
              setFiles={(files) => {
                setCustomerOrderDoc(files);
                resetErrorsHandler("customer_order_doc[]");
              }}
            /> */}
          </Grid>
        </Grid>

        <Grid>
          <Typography
            color="secondary"
            varient="h3"
            style={{ marginBottom: "10px", marginTop: "10px" }}
          >
            QR Label
          </Typography>
          <MROButton
            style={{ background: "#4FC607" }}
            type="button"
            variant={"contained"}
            color="secondary"
            onClick={() => setAction("qr_code")}
          >
            <img
              src={QrCodeIcon}
              alt="qr-code"
              style={{ width: "18px", height: "18px", marginRight: "8px" }}
            />
            Print Label
          </MROButton>
        </Grid>

        <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
          {orderStateNum > 1 && orderStateNum <= 8 && tabStatus && props?.permission.write &&
            <MROButton
              type="button"
              variant={"contained"}
              style={{
                backgroundColor: "#FFB000",
                color: "white",
                // display: editTab || access.read ? "none" : undefined,
              }}
              onClick={() => handleTab()}
              startIcon={<img className={classes.svg} src={lock} alt="lock" />}
            >
              Edit Tab
            </MROButton>}

          {orderStateNum > 1 && closeTabStatus === 2 && props?.permission.write && (
            <MROButton
              variant={"contained"}
              style={{
                backgroundColor: "red",
                color: "white",
                // display: !editTab ? "none" : undefined,
              }}
              type="submit"
              loading={busy === "loading"}
              startIcon={
                <img className={classes.svg} src={unlock} alt="unlock" />
              }
              onClick={() => setCloseTab(true)}
            >
              Close Tab
            </MROButton>)}

          {action === "qr_code" && (
            <PrintLable
              open={action === "qr_code"}
              handleClose={() => setAction(null)}
              id={data.id}
            />
          )}
          {addressAction && addressAction === "create" && (
            <ManageAddress
              handleClose={() => setAddAction(null)}
              workshopDetails={data}
              setSelectedAddress={setSelectedAddress}
              getCustomerAddress={getCustomerAddress}

            />
          )}
        </Grid>
      </form>
    </div >
  );
}
