import React, { createContext, useState } from "react";

export const WorkshopContext = createContext();

function WorkshopContextProvider({ children }) {
  const [state, setState] = useState({
    shouldShowWarning: false,
    handleConfirm: () => null,
    warningState: false,
    warningDialogState: false,
    editTabStatus: false,
    controlPlanningPop: false
  });
  const [shouldTabDataUpdate, setShouldTabDataUpdate] = useState(false);
  const [colorStatus, setColorStatus] = useState(null);
  const [stageUpdate, setStageUpdate] = useState(false);
  const [approvalStateCommercial, setApprovalStateCommercial] = useState(false);
  const [editButton, setEditButton] = useState(false);
  const [tabStatus, setTabStatus] = useState(false);
  const [openPopup, setOpenPopup] = useState(false)
  const [EdtabStatus, setEditTabStatus] = useState(false)
  const [conditionState, setConditionState] = useState(false)
  const [tatCount, setTatCount] = useState(null)
  const [stockDetails, setStockDetails] = useState(null)
  const [copyTabStatus, setcopyTabStatus] = useState([]);
  const [copyCalendarStatus, setCopyCalendarStatus] = useState(false);
  const [copyAllActions, setCopyAllactions] = useState(false);
  const [copyPartTabStatus, setcopyPartTabStatus] = useState([]);
  const [copyServiceTabStatus, setcopyServiceTabStatus] = useState([]);
  const [copyEquipmentTabStatus, setCopyEquipmentTabStatus] = useState([]);
  const [flag, setflag] = useState(false)
  const [serviceStatus, setServiceStatus] = useState(false)
  const [equipmentStatus, setEquipmentStatus] = useState(false)
  const [copyPlanningTab, setCopyPlanningTab] = useState({
    status: false,
    releaseA: false,
    releaseB: false,
    releaseAuth: false,
    description: false,
    techData: false,
    inspection: false,
    capability: false,
    image: false,
    service: false,
    projectParts: false
  });
  const [copyIdflag, setCopyIdFlag] = useState(false)
  const [planningCopyId, setCopyPlanningId] = useState({})
  //Line maintenance

  const [tableRefresh, setTableRefresh] = useState(false)
  //Procurement
  const [linePartsCopy, setLinePartsCopy] = useState([]);
  const [updateLineValue, setUpdatedlinevalue] = useState([])
  const [deleteState, setDeleteState] = useState(false)
  // Procurement MinMax
  const [lineMinMaxPartsCopy, setLineMinMaxPartsCopy] = useState([]);
  const [updateMinMaxLineValue, setUpdatedMinMaxlinevalue] = useState([])
  const [deleteMinMaxState, setMinMaxDeleteState] = useState(false)
  // Procurement Forcast
  const [lineForecastPartsCopy, setForecastPartsCopy] = useState([]);
  const [updateForecastValue, setUpdatedForecastvalue] = useState([])
  const [deleteForecastState, setForecastDeleteState] = useState(false)
  //Inspection history tab status
  const [copyInspection, setCopyInspection] = useState(false)
  const [copyParts, setCopyParts] = useState([])
  const [copyServices, setCopyServices] = useState([])
  const [serviceFlag, setServiceFlag] = useState(false)
  const [copyEquipments, setCopyEquipments] = useState([]);
  const [equipmentFlag, setEquipmentFlag] = useState(false)

  const [planningNullId, setPlanningNullId] = useState(null)

  const [copiedJobs, setCopiedJobs] = useState([])
  const [messageTotalCount, setMessagetotalCount] = useState(0)
  const value = {
    state,
    setState,
    shouldTabDataUpdate,
    setShouldTabDataUpdate,
    colorStatus,
    setColorStatus,
    stageUpdate,
    setStageUpdate,
    approvalStateCommercial,
    setApprovalStateCommercial,
    editButton,
    setEditButton,
    tabStatus,
    setTabStatus,
    openPopup,
    setOpenPopup,
    EdtabStatus,
    setEditTabStatus,
    conditionState,
    setConditionState,
    tatCount,
    setTatCount,
    stockDetails,
    setStockDetails,
    copyTabStatus,
    setcopyTabStatus,
    copyPlanningTab,
    setCopyPlanningTab,
    copyPartTabStatus,
    setcopyPartTabStatus,
    copyServiceTabStatus,
    setcopyServiceTabStatus,
    flag,
    setflag,
    serviceStatus,
    setServiceStatus,
    copyEquipmentTabStatus,
    setCopyEquipmentTabStatus,
    equipmentStatus,
    setEquipmentStatus,
    copyCalendarStatus,
    setCopyCalendarStatus,
    copyIdflag,
    setCopyIdFlag,
    planningCopyId,
    setCopyPlanningId,
    tableRefresh,
    setTableRefresh,
    linePartsCopy,
    setLinePartsCopy,
    updateLineValue,
    setUpdatedlinevalue,
    lineMinMaxPartsCopy,
    setLineMinMaxPartsCopy,
    updateMinMaxLineValue,
    setUpdatedMinMaxlinevalue,
    deleteState,
    setDeleteState,
    deleteMinMaxState,
    setMinMaxDeleteState,
    copyInspection,
    setCopyInspection,
    copyParts,
    setCopyParts,
    copyServices,
    setCopyServices,
    serviceFlag,
    setServiceFlag,
    copyEquipments,
    setCopyEquipments,
    equipmentFlag,
    setEquipmentFlag,
    copiedJobs,
    setCopiedJobs,
    messageTotalCount,
    setMessagetotalCount,
    planningNullId,
    setPlanningNullId,
    lineForecastPartsCopy,
    setForecastPartsCopy,
    updateForecastValue,
    setUpdatedForecastvalue,
    deleteForecastState,
    setForecastDeleteState,
    copyAllActions, setCopyAllactions
  };

  return (
    <WorkshopContext.Provider value={value}>
      {children}
    </WorkshopContext.Provider>
  );
}

export default WorkshopContextProvider;
