import MaterialTable from "material-table";
import React, { useReducer, useState, useEffect } from "react";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import tableIcons, { options } from "components/universal/table_attributes";
import TableAction from "actions/table_actions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Box, IconButton } from "@material-ui/core";
import FileCopy from '@material-ui/icons/FileCopy';
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import { Visibility as ViewIcon } from "@material-ui/icons";
import { ICON_COLOUR } from "lib/constants/style_constants";
import { ToastMessageContext } from "lib/contexts/message_context";
import { useContext } from "react";
import _ from "lodash";
import CopyFileIcon from "assets/CopyFiles.svg"
import GreenCopyIcon from "assets/GreenCopyIcon.svg"
import { WorkshopContext } from "views/Operations/Workshop/WorkshopContext/WorkshopContext";
import alertIcon from 'assets/icons/Alert_icon.png'
import { getOrganisationId } from "lib/utils/common_utils";

const useStyles = makeStyles(() => ({}));


function Plan(props) {
  const classes = useStyles();
  const { currentClosedDetails, currentPlanningDetails, planningId, planningWholeDetails } = props
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const message = useContext(ToastMessageContext)
  const { copyPlanningTab, setCopyPlanningTab, planningNullId } = useContext(WorkshopContext)
  const { page, totalCount, pageSize } = tableState;
  const [loading, setLoading] = useState(false)
  const [mainFiles, setMainFiles] = useState([])
  const [planningData, setPlanningData] = useState({})
  const tableOptions = {
    ...options,
    page: page,
    total: totalCount,
    pageSize: pageSize,
    search: false,
    toolbar: false,
  };




  const handleFileCopy = async (item, value, id) => {

    const formData = new FormData(document.getElementById("my-form"));

    const param = {
      id: currentPlanningDetails?.planning_id ? currentPlanningDetails?.planning_id : planningId.planning_id ? planningId.planning_id : planningNullId ? planningNullId : currentPlanningDetails?.planning_id,
      save_later: 1,
    }
    formData.append("workshop_order_id", currentPlanningDetails?.id);
    if (copyPlanningTab.capability === false && planningWholeDetails?.capabilty_id) {
      formData.delete("capability_id")
      formData.append("capability_id", planningWholeDetails.capabilty_id)

    }
    if (copyPlanningTab.capability === false && planningWholeDetails?.capabilty_id) {
      formData.delete("capabilty_ref_data")
      formData.append("capabilty_ref_data", planningWholeDetails.capabilty_ref_data)

    }
    formData.append("inspection_action", planningData.inspection_action)
    if (item === "Status / Work" || copyPlanningTab.status) {
      formData.delete("status_work")
      formData.append("status_work", planningData.status_work)
    }



    if (item === "Release Auth" || copyPlanningTab.releaseAuth) {
      formData.delete("release_auth")
      formData.delete("release_auth_name")
      formData.append("release_auth", planningData.release_auth)
      formData.append("release_auth_name", planningData.release_auth_name)
    }

    if (item === "Description of work" || copyPlanningTab.description) {
      formData.delete("description")
      formData.append("description", planningData.planning_description)
    }

    if (item === "Technical Data CRS remarks" || copyPlanningTab.techData) {
      formData.delete("tech_data_crs_remarks")
      formData.append("tech_data_crs_remarks", planningData.tech_data_crs_remarks)
    }

    if (item === "Inspection Actions" || copyPlanningTab.inspection) {
      formData.delete("inspection_action")
      formData.append("inspection_action", planningData.inspection_action)
    }

    if (item === "Capability Reference" || copyPlanningTab.capability) {
      formData.delete("capability_id")
      formData.append("capability_id", planningData.capability_id)
      formData.delete("capabilty_ref_data")
      formData.append("capabilty_ref_data", planningData.capabilty_ref_data)

    }

    if (item === "Technical / Maint Data") {

      formData.delete("tech_maintenance_avail")
      formData.append("tech_maintenance_avail", true)

    }
    await MaintenanceServices.updateSaveForLaterData(formData, param)
      .then((res) => {
        if (res.success) {
          // getWorkshopOrderDetails()

          message.showToastMessage({
            message: "Copied successfully ",
            variant: "success",
          });
        }
      }).catch(() => {
        message.showToastMessage({
          message: "Something went wrong",
          variant: "error",
        });
      }).finally(() => {
        setLoading(null)
      })
  }

  const handleTechDataFile = async (item) => {

    if (item !== undefined && item.length != 0) {


      const formData = new FormData(document.getElementById("my-form"));
      formData.delete("tech_maintenance_avail")
      formData.append("tech_maintenance_avail", true)
      formData.append("from_workshop_order_id", currentClosedDetails.id)
      formData.append("to_workshop_order_id", currentPlanningDetails?.id)

      await MaintenanceServices.copyPlanningFile(formData).then((response) => {
        if (response.success) {
          // getWorkshopOrderDetails()

          message.showToastMessage({
            message: "Copied successfully ",
            variant: "success",
          });
        }
      }).catch((err) => {
        message.showToastMessage({
          message: "Something went wrong",
          variant: "error",
        });
      })
    }
    else {
      setCopyPlanningTab((ps) => ({ ...ps, image: false }));
      message.showToastMessage({
        message: "No Report Found",
        variant: "error",
      });
    }
  }


  const handleService = async () => {
    const formData = new FormData()

    formData.append("from_workshop_order_id", currentClosedDetails.id)
    formData.append("to_workshop_order_id", currentPlanningDetails?.id)


    await MaintenanceServices.updateServiceLifes(formData)
      .then((res) => {
        if (res.success) {
          // getWorkshopOrderDetails()

          message.showToastMessage({
            message: "Copied successfully ",
            variant: "success",
          });
        }
      }).catch(() => {
        message.showToastMessage({
          message: "Something went wrong",
          variant: "error",
        });
      }).finally(() => {
        setLoading(null)
      })



  }
  const handleProjectParts = async () => {
    const formData = new FormData()

    formData.append("from_workshop_order_id", currentClosedDetails.id)
    formData.append("to_workshop_order_id", currentPlanningDetails?.id)
    formData.append("reportable_type", "WorkshopOrder")
    formData.append("organisation_id", getOrganisationId())


    await MaintenanceServices.updateCopyProjectParts(formData)
      .then((res) => {
        if (res.success) {
          // getWorkshopOrderDetails()
          setCopyPlanningTab((ps) => ({ ...ps, projectParts: true }));
          message.showToastMessage({
            message: "Copied successfully ",
            variant: "success",
          });
        }
      }).catch((res) => {

        setCopyPlanningTab((ps) => ({ ...ps, projectParts: false }));
        message.showToastMessage({
          message: res?.error ? res?.error : "Something went wrong",
          variant: "error",
        });
      }).finally(() => {
        setLoading(null)
      })



  }
  const columns = [
    {
      title: "Item",
      field: "item",
      headerStyle: {
        textAlign: "left",
        paddingLeft: "20px"
      },
      cellStyle: {
        textAlign: "left",
        paddingLeft: "20px"
      },

    },
    {
      title: "Detail",
      field: "detail",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
        width: "50%",
      },
    },
    {
      title: "Action",
      field: "action",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
      },
    }
  ];

  //api call planning list 
  const getPlanningList = async () => {
    setLoading(true)
    const params = {
      workshop_order_id: currentClosedDetails.id,
      organisation_id: getOrganisationId()
    }
    await MaintenanceServices.copyPreviousGetPlanningList(params).then((response) => {

      setPlanningData(response.data)
    }).catch((err) => {
      message.showToastMessage({
        message: "Something went wrong. Try again",
        variant: "error",
      })
    }).finally(() => {
      setLoading(false)
    })
  }
  useEffect(() => {
    getPlanningList()


  }, [currentClosedDetails.id, page, pageSize, totalCount])

  const handleFile = (report) => {

    return (
      <Box>
        <IconButton
        // disabled={!props.permission.write}
        >
          <ViewIcon fontSize="small" style={{ color: ICON_COLOUR }} onClick={() => {
            if (report !== undefined && report.length != 0) {
              window.open(report.map(document => document.file_path))
            }
            else {
              message.showToastMessage({
                message: "No Report Found.",
                variant: "error",
              })
            }
          }} />
        </IconButton>
      </Box>
    )

  }


  return (
    <div>
      <MaterialTable
        style={{
          boxShadow: "0px 1px 3px #00000033",
          marginTop: "10px",
        }}
        icons={tableIcons}
        title={""}
        columns={columns}
        data={[

          {
            item: "Status / Work",
            detail: planningData.status_work ? planningData.status_work : "N/A",
            action: (<IconButton onClick={(event, rowData) => {
              setCopyPlanningTab((ps) => ({ ...ps, status: true }));
              handleFileCopy("Status / Work")
            }}
              disabled={copyPlanningTab.status || planningData.status_work === null}
            >
              {copyPlanningTab.status ? <img src={GreenCopyIcon} height="25px" style={{ pointerEvents: copyPlanningTab.status ? "none" : "", }} /> :

                <img src={CopyFileIcon} height="25px" style={{ pointerEvents: planningData.status_work === null || planningData === undefined ? "none" : "", }} />
              }

            </IconButton >)
          },
          {
            item: "Release Type 1",
            detail: planningData.release_type_a_name ? planningData.release_type_a_name : "N/A",
            id: planningData.release_type_a,
            action: (<IconButton onClick={(rowData) => {
              setCopyPlanningTab((ps) => ({ ...ps, releaseA: true }));
              handleFileCopy("Release Type 1")


            }}
              disabled={copyPlanningTab.releaseA || planningData.release_type_a_name === null}
            >
              {copyPlanningTab.releaseA ? <img src={GreenCopyIcon} height="25px" style={{ pointerEvents: copyPlanningTab.releaseA ? "none" : "", }} /> :

                <img src={CopyFileIcon} height="25px" style={{ pointerEvents: planningData.release_type_a_name === null || planningData === undefined ? "none" : "", }} />
              }



            </IconButton >)

          },
          {
            item: "Release Type 2",
            detail: planningData.release_type_b_name ? planningData.release_type_b_name : "N/A",
            id: planningData.release_type_b,
            action: (<IconButton onClick={() => {
              setCopyPlanningTab((ps) => ({ ...ps, releaseB: true }));
              handleFileCopy("Release Type 2")

            }}
              disabled={copyPlanningTab.releaseB || planningData.release_type_b_name === null}
            >
              {copyPlanningTab.releaseB ? <img src={GreenCopyIcon} height="25px" style={{ pointerEvents: copyPlanningTab.releaseB ? "none" : "", }} /> :

                <img src={CopyFileIcon} height="25px" style={{ pointerEvents: planningData.release_type_b_name === null || planningData === undefined ? "none" : "", }} />
              }


            </IconButton >)
          }, //
          {
            item: "Release Auth",
            detail: planningData.release_auth_name ? planningData.release_auth_name : "N/A",
            id: planningData.release_auth,
            action: (<IconButton onClick={() => {
              setCopyPlanningTab((ps) => ({ ...ps, releaseAuth: true }));
              handleFileCopy("Release Auth")

            }}
              disabled={copyPlanningTab.releaseAuth || planningData.release_auth_name === null}
            >
              {copyPlanningTab.releaseAuth ? <img src={GreenCopyIcon} height="25px" style={{ pointerEvents: copyPlanningTab.releaseAuth ? "none" : "", }} /> :

                <img src={CopyFileIcon} height="25px" style={{ pointerEvents: planningData.release_auth_name === null || planningData === undefined ? "none" : "", }} />
              }


            </IconButton >)
          },





          {
            item: "Description of work",
            detail: planningData.planning_description,
            action: (<IconButton onClick={() => {
              setCopyPlanningTab((ps) => ({ ...ps, description: true }));
              handleFileCopy("Description of work")
            }}
              disabled={copyPlanningTab.description || planningData.planning_description === null}
            >
              {copyPlanningTab.description ? <img src={GreenCopyIcon} height="25px" style={{ pointerEvents: copyPlanningTab.description ? "none" : "", }} /> :

                <img src={CopyFileIcon} height="25px" style={{ pointerEvents: planningData.planning_description === null || planningData === undefined ? "none" : "", }} />
              }



            </IconButton >)
          },
          {
            item: "Technical Data CRS remarks",
            detail: planningData.tech_data_crs_remarks ? planningData.tech_data_crs_remarks : "N/A",
            action: (<IconButton onClick={() => {
              setCopyPlanningTab((ps) => ({ ...ps, techData: true }));
              handleFileCopy("Technical Data CRS remarks")


            }}
              disabled={copyPlanningTab.techData || planningData.tech_data_crs_remarks === null}
            >
              {copyPlanningTab.techData ? <img src={GreenCopyIcon} height="25px" style={{ pointerEvents: copyPlanningTab.techData ? "none" : "", }} /> :

                <img src={CopyFileIcon} height="25px" style={{ pointerEvents: planningData.tech_data_crs_remarks === null || planningData === undefined ? "none" : "", }} />
              }

            </IconButton >)

          }, {
            item: "Service Life",
            detail: planningData && planningData?.service_lifes ? planningData?.service_lifes.toString() : "N/A",
            action: (<IconButton onClick={() => {
              setCopyPlanningTab((ps) => ({ ...ps, service: true }));
              handleService("service_life")


            }}
              disabled={copyPlanningTab.service}
            >
              {copyPlanningTab.service ? <img src={GreenCopyIcon} height="25px" style={{ pointerEvents: copyPlanningTab.service ? "none" : "", }} /> :

                <img src={CopyFileIcon} height="25px" style={{ pointerEvents: planningData === undefined ? "none" : "", }} />
              }

            </IconButton >)

          }, {
            item: "Projects Parts",
            detail: planningData && planningData?.project_parts ? planningData?.project_parts : "N/A",
            action: (<IconButton onClick={() => {

              handleProjectParts()


            }}
              disabled={copyPlanningTab.projectParts || planningData?.project_parts === 0}
            >
              {copyPlanningTab.projectParts ? <img src={GreenCopyIcon} height="25px" style={{ pointerEvents: copyPlanningTab.projectParts ? "none" : "", }} /> :

                <img src={CopyFileIcon} height="25px" style={{ pointerEvents: planningData?.project_parts === 0 || planningData === undefined ? "none" : "", }} />
              }

            </IconButton >)

          },
          {
            item: "Inspection Actions",
            detail: planningData.inspection_action === true ? "Yes" : "No",
            action: (<IconButton onClick={() => {
              setCopyPlanningTab((ps) => ({ ...ps, inspection: true }));
              handleFileCopy("Inspection Actions")
            }}
              disabled={copyPlanningTab.inspection || planningData.inspection_action === null}
            >
              {copyPlanningTab.inspection ? <img src={GreenCopyIcon} height="25px" style={{ pointerEvents: copyPlanningTab.inspection ? "none" : "", }} /> :

                <img src={CopyFileIcon} height="25px" style={{ pointerEvents: planningData.inspection_action === null || planningData === undefined ? "none" : "" }} />
              }


            </IconButton >)
          },
          {
            item: "Capability Reference",
            detail: planningData.capabilty_ref_data ? planningData.capabilty_ref_data : "N/A",
            id: planningData.capability_id,
            action: (<IconButton onClick={() => {
              setCopyPlanningTab((ps) => ({ ...ps, capability: true }));
              handleFileCopy("Capability Reference")
            }}
              disabled={copyPlanningTab.capability || planningData.capabilty_ref_data === null}
            >
              {copyPlanningTab.capability ? <img src={GreenCopyIcon} height="25px" style={{ pointerEvents: copyPlanningTab.capability ? "none" : "", }} /> :

                <img src={CopyFileIcon} height="25px" style={{ pointerEvents: planningData.capabilty_ref_data == null || planningData === undefined ? "none" : "" }} />
              }


            </IconButton >)
          },
          // {
          //   item: "Technical / Maint Data",
          //   detail: handleFile(planningData.tech_maintenance_file),
          //   action: (<IconButton onClick={() => {
          //     setCopyPlanningTab((ps) => ({ ...ps, image: true }));
          //     handleFileCopy("Technical / Maint Data")
          //     handleTechDataFile(planningData.tech_maintenance_file)
          //   }}
          //     disabled={copyPlanningTab.image}
          //   >
          //     {copyPlanningTab.image ? <img src={GreenCopyIcon} height="25px" style={{ pointerEvents: copyPlanningTab.image ? "none" : "", }} /> :

          //       <img src={CopyFileIcon} height="25px" />
          //     }
          //   </IconButton >)
          // },

        ].filter((item) => {
          if (!currentPlanningDetails?.project_part) {
            if (!planningData.inspection_action) {
              return item.item !== "Projects Parts" && item.item !== "Inspection Actions"

            } else {
              return item.item !== "Projects Parts"
            }


          }
          else {
            if (!planningData.inspection_action) {
              return item.item !== "Inspection Actions"

            }
            else {
              return item
            }

          }


        })}
        isLoading={loading}
        options={tableOptions}
        onChangePage={(page) => {
          TableAction.setPage(dispatch, page);
        }}
        onChangeRowsPerPage={(pageSize) => {
          TableAction.setPageSize(dispatch, pageSize);
        }}
      // totalCount={totalCount}
      // page={page}
      />
      {/* <div style={{ margin: ' 14px 0px 20px 3px' }}>
        <img src={alertIcon} height="15px" /> <span style={{ padding: '6px' }}>  If copying Tech Data be sure to confirm it is the latest revision!</span>
      </div> */}
    </div>
  );
}

export default Plan;
