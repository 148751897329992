import requestrApi from "../common/api_client";


function createKits(formData) {
    return requestrApi({
        url: `/primary_parts`,
        method: 'POST',
        data: formData
    });
}

function getKItsPrimaryParts(params) {
    return requestrApi({
        url: `/kits/kit_primary_parts`,
        method: 'GET',
        params: params
    });
}

function mapKitPrimaryParts(params) {
    return requestrApi({
        url: `/kits/map_kit_primary_parts`,
        method: 'PUT',
        params: params
    });
}


//
function NewKitsListing(params) {
    return requestrApi({
        url: `/kits`,
        method: 'GET',
        params
    });
}
//stock listing

function NewStockListing(params) {
    return requestrApi({
        url: `/primary_parts/listing_non_kits`,
        method: 'GET',
        params
    });
}
// add quantity

function AddNewparts(formData) {

    return requestrApi({
        url: `/kits/create_kit_order`,
        method: 'POST',
        data: formData
    });

}
//kitscontent listing
const KitsContentListing = (params) => {

    return requestrApi({
        url: `/kits/listing_kit_items`,
        method: 'GET',
        params
    });

}


const updateKitsQuantity = (params) => {
    return requestrApi({
        url: `/kits/update_kit_item`,
        method: 'PUT',
        data: params
    });
}


const deleteKitsItem = (params) => {
    return requestrApi({
        url: `kits/destroy_kit_item`,
        method: 'DELETE',
        data: params
    });

}
//Kits Order 

const getKitsOrder = (params) => {
    return requestrApi({
        url: `/kit_orders/listing_orders`,
        method: 'GET',
        params
    });
}

function fetchDependencies() {
    return requestrApi({
        // url: '/parts/fetch_filter_dependencies',
        url: "/primary_parts/fetch_dependencies",
        method: "GET",
    });
}

function getKitsPrimeParts(params) {
    return requestrApi({
        url: "/primary_parts/listing_kits",
        method: "GET",
        params
    });
}

function createKitOrder(formData) {

    return requestrApi({
        url: `/kit_orders/create_order`,
        method: 'POST',
        data: formData
    });

}

function getKitsOrderLineItems(params) {
    return requestrApi({
        url: "/kit_orders/listing_parts",
        method: "GET",
        params
    });
}
function updateKitOrder(params) {

    return requestrApi({
        url: `/kit_orders/update_order`,
        method: 'PUT',
        params
    });

}

function deleteKitOrder(data) {
    return requestrApi({
        url:"/kit_orders/delete_kit_order",
        method:"DELETE",
        data
    })
}

const KitsClientService = {
    createKits,
    getKItsPrimaryParts,
    mapKitPrimaryParts,
    NewKitsListing,
    NewStockListing,
    AddNewparts,
    KitsContentListing,
    updateKitsQuantity,
    deleteKitsItem,
    getKitsOrder,
    fetchDependencies,
    getKitsPrimeParts,
    createKitOrder,
    getKitsOrderLineItems,
    updateKitOrder,
    deleteKitOrder
}
export default KitsClientService;
