import React, { useContext, useEffect, useReducer, useState } from "react";
import MROFullScreenRightDrawer from "components/fullwidth_dialog";
import { makeStyles } from "lib/utils/common_utils";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Tooltip,
  useTheme,
  Typography,
  Grid,
} from "@material-ui/core";
import BackIcon from "@material-ui/icons/KeyboardBackspace";

import LoadingIndicator from "components/universal/loading_indicator";
import tableReducer, { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import TableAction from "actions/table_actions";
import MaterialTable from "material-table";
import tableIcons, { options } from "components/universal/table_attributes";
import ViewIcon from "@material-ui/icons/Visibility";
import RescheduleIcon from "@material-ui/icons/History";
import MROButton from "components/buttons";
import EditPartDetails from "./EditPartDetails";
import HistoryTablePopup from "views/Stores/Parts/History/HistoryTablePopup";
import ReplayIcon from "@material-ui/icons/Replay";
import ReturnIssuedPart from "./ReturnIssuedPart/ReturnIssuedPart";
import EditIcon from "@material-ui/icons/Edit";
import { ICON_COLOUR } from "lib/constants/style_constants";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import moment from "moment";
import BoxpTableAction from "actions/boxp_table_actions";
import { ToastMessageContext } from "lib/contexts/message_context";
import CertificatePopup from "views/Stores/Parts/Stocks/CertificatePopup";
import { BASE_API_URL_BOXR } from "lib/constants";
import BoxrTableAction from "actions/boxr_table_actions";
import WarningIcon from "@material-ui/icons/Warning";
const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#fff",
    color: "#000000CC",
    boxShadow: "0px 3px 6px #0000001A",
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  wrapper: {
    padding: "2%",
    background: "white",
  },
  root: { "& > :first-child": { position: "unset" } },
  //   detail: {
  //     padding: "3%",
  //     backgroundColor: "#fff",
  //     boxShadow: "0px 3px 6px #0000001A",
  //   },

  partCard: {
    padding: "17px 24px",
  },
}));

const GetInfoCard = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box display="flex">
      <Box flex={50} className={classes.partKey}>
        <Typography
          style={{ padding: "5px", color: theme.palette.primary.main }}
          color="secondary"
        >
          {props?.keyName}
        </Typography>
      </Box>
      <Box flex={50} className={classes.partValue}>
        {props?.value ?? "-"}
      </Box>
    </Box>
  );
};

function PartInfo(props) {
  const { onClose, currentSelectedRequest, actionDpdOptions, getData, partsAndToolsStatus } = props;

  console.log("parts tools is===================", props.permission)

  const message = useContext(ToastMessageContext);
  const theme = useTheme();
  const classes = useStyles();

  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  const { page, totalCount, pageSize, searchText, inProgress, data } =
    tableState;

  const [anchorEl, setAnchorEl] = useState(null);
  const [busy, setBusy] = useState(null);

  const [value, setValue] = useState("no");
  const [isEdit, setIsEdit] = useState(false);
  const [isReturnIssuedPart, setIsReturnIssuedPart] = useState(false);

  const [currentSelection, setCurrentSelection] = useState({});
  const [isMaterialPopupOpen, setIsMaterialPopupOpen] = useState(false);


  const [allocId, setAllocId] = useState(null);
const [partConditionId , setPartConditionId] = useState(null);
  const [partInfo, setPartInfo] = useState({});
  const [requestedDate, setRequestedDate] = useState("");
  const [popupData, setPopupData] = useState([]);
  const [msds, setMsds] = useState([]);

  useEffect(() => {
    if (
      partInfo?.part_condition_details?.created_at !== undefined &&
      partInfo?.part_condition_details?.created_at !== null
    ) {
      let momentDate = moment.utc(partInfo?.part_condition_details?.created_at);
      let formattedDate = moment(momentDate, "DD/mm/YYYY hh:mm")
        .local()
        .format("DD/MM/YYYY hh:mm");
      setRequestedDate(formattedDate);
    }
  }, [partInfo]);

  const getPartDetials = () => {
    // setTableRefresh(!tableRefresh)
    MaintenanceServices.getRequestPartDetails({ id: currentSelectedRequest.id })
      .then((res) => {
        setPartInfo(res);
        setMsds(res.data.mds);
      })
      .catch((error) => {
        console.log("Something went wrong!", error);
      });
  };

  const getList = () => {
    const params = {
      page: page + 1,
      limit: pageSize,
      id: currentSelectedRequest.id,
    };
    BoxrTableAction.getList(dispatch, message, params, "allocationDetailsList");
  };

  useEffect(() => {
    getPartDetials();
    getList();
  }, [currentSelectedRequest?.id]);

  const tableOptions = {
    ...options,
    page: page,
    search: false,
    total: totalCount,
    pageSize: pageSize,
    searchText: searchText,
  };

  const handleToggle = (value) => {
    if (value) {
      setValue(value);
    }
  };

  const getPopupData = async () => {
    const params = {
      id: currentSelection.id,
    };

    try {
      setBusy("loading");
      const { data } = await MaintenanceServices.getConditionedParts(params);
      setPopupData(data);
    } catch (error) {
      setBusy("error");
    } finally {
      setBusy(null);
    }
  };

  useEffect(() => {
    if (currentSelection.id) {
      getPopupData();
    }
  }, [currentSelection.id]);

  const renderStatBtn = (condition) => {
    if (condition === "issued" || condition === "rts") {
      return (
        <MROButton
          variant="outlined"
          style={{
            borderColor: "darkgrey",
            color: "darkgrey",
            borderRadius: "8px",
            PointerEvent: "none",
            textTransform: "uppercase",
          }}
        >
          {condition}
        </MROButton>
      );
    } else {
      const bgColor = condition === "pre_load" ? "#4FC607" : "#FFB302";
      return (
        <MROButton
          variant="contained"
          style={{
            background: bgColor,
            color: "white",
            borderRadius: "8px",
            PointerEvent: "none",
            textTransform: "uppercase",
          }}
        >
          {condition}
        </MROButton>
      );
    }
  };

  const columns = [
    {
      field: "alloc_id",
      title: "ALOC ID",
    },
    {
      field: "batch_number",
      title: "B/N",
    },
    {
      field: "quantity",
      title: "QTY",
    },
    {
      field: "condition",
      title: "State",
      render: (rowData) => renderStatBtn(rowData.condition),
    },
    {
      field: "user",
      title: "By",
    },
    {
      field: "receipt_date",
      title: "Date",
    },
    {
      field: "eta",
      title: "ETA",
      render: (rowData) => {
        if (rowData?.eta === "" || rowData?.eta === null) {
          return <>{"-"}</>
        } else {
          return <>{rowData?.eta}</>
        }
      }
    },
    {
      field: "cert",
      title: "Cert",
      render: (rowData) => (
        <IconButton
          onClick={() => {
            setIsMaterialPopupOpen(true);
            setMsds(
              rowData.certificates?.map((item) => ({
                ...item,
                url: BASE_API_URL_BOXR + item.url,
              }))
            );
          }}
        >
          <ViewIcon color={ICON_COLOUR} />
        </IconButton>
      ),
    },
    {
      field: "",
      title: "Action",
      render: (rowData) => (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Tooltip title="History">
            <IconButton
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setCurrentSelection(rowData);
              }}
              className={"text-info"}
            >
              <RescheduleIcon />
            </IconButton>
          </Tooltip>
          {partsAndToolsStatus === 3 || partsAndToolsStatus === 4 ? null : rowData.condition?.toLowerCase() === "issued" && props.permission.write &&
            <IconButton onClick={() => {
              setIsReturnIssuedPart(true);
              setAllocId(rowData.alloc_id);
              setPartConditionId(rowData?.id);
              setCurrentSelection(rowData)
            }}>
              <Tooltip title="Return">
                <ReplayIcon />
              </Tooltip>
            </IconButton>}
          <HistoryTablePopup
            busy={busy}
            anchorEl={anchorEl}
            handleClick={(event) => setAnchorEl(event.currentTarget)}
            handleClose={() => setAnchorEl(null)}
            currentSelection={currentSelection}
            data={popupData}
          />
        </Box>
      ),
    },
  ];

  return (
    <MROFullScreenRightDrawer open={true} className={classes.root}>
      <AppBar position="static" className={classes.appBar} elevation="">
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <Box display="flex" alignItems="center">
            <IconButton
              onClick={onClose}
              edge="start"
              className={classes.backButton}
              color="inherit"
              aria-label="back"
            >
              <BackIcon />
            </IconButton>
            <Typography variant="subtitle2" style={{ fontSize: "17px" }}>Part Details </Typography>
          </Box>
          {partsAndToolsStatus === 3 || partsAndToolsStatus === 4 ? null : props.permission.write &&
            <IconButton onClick={() => setIsEdit(true)}>
              <EditIcon fontSize="small" color={ICON_COLOUR} />
            </IconButton>}
        </Toolbar>
      </AppBar>
      {/* <div className={classes.wrapper}>
                <GeneralWorkOrderDetails
                    getList={props.getList}
                    handleClose={handleClose}
                    data={currentSelection} />
            </div> */}
      {true ? (
        <div className={classes.wrapper} style={{ marginTop: "0.5%" }}>
          {/* Request detials container */}
          <Box mt="15px">
            <Grid container>
              <Grid item xs={10}>
                <Typography
                  style={{
                    color: theme.palette.primary.main,
                    marginBottom: "12px",
                    fontSize: "17px",
                  }}
                  color="primary"
                  variant="subtitle2"
                >
                  Request Details
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Grid container style={{ marginBottom: '12px' }}>
                  {currentSelectedRequest?.customer_supply === true ?
                    <>
                      < WarningIcon
                        style={{
                          color: "#FFB300",
                          marginRight: "10px",
                          fontSize: 20,
                        }}
                      />
                      <Typography >Customer Supply Part!</Typography>
                    </> : <Typography> </Typography>}
                </Grid>
              </Grid>
            </Grid>
            <Box
              display="flex"
              alignItems="center"
              //   justifyContent={(loading || _.isEmpty(partDetails)) && "center"}
              bgcolor="#F5F5F5"
              className={classes.partCard}
            >
              <Box flexGrow={1}>
                <div style={{ width: "100%" }}>
                  <GetInfoCard
                    keyName={"Part Number"}
                    value={currentSelectedRequest.part_number}
                  />
                  <GetInfoCard
                    keyName={"Description"}
                    value={currentSelectedRequest.description}
                  />
                  <GetInfoCard
                    keyName={"Maint Data Ref"}
                    value={currentSelectedRequest.maint_data_ref}
                  />

                  <GetInfoCard
                    keyName={"Quantity"}
                    value={partInfo?.data?.required_quantity}
                  />
                  <GetInfoCard
                    keyName={"Allocated"}
                    value={partInfo?.data?.allocated_quantity}
                  />
                  <GetInfoCard
                    keyName={"Remaining"}
                    value={partInfo?.data?.pick_quantity}
                  />
                </div>
              </Box>
              <Box flexGrow={1}>
                <div style={{ width: "100%" }}>
                  <GetInfoCard keyName={"Requested"} value={requestedDate} />
                  <GetInfoCard
                    keyName={"Requested By"}
                    value={currentSelectedRequest?.requested_by}
                  />

                  <GetInfoCard
                    keyName={"Request ID"}
                    value={currentSelectedRequest.request_id}
                  />
                  <GetInfoCard
                    keyName={"Action #"}
                    value={partInfo.action_number}
                  />
                  <GetInfoCard
                    keyName={"Comment"}
                    value={partInfo?.data?.comment}
                  />
                  <GetInfoCard
                    keyName={"Material Data"}
                    value={
                      <div style={{ marginTop: "3px" }}>
                        <ViewIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => setIsMaterialPopupOpen(true)}
                          fontSize="small"
                          htmlColor={ICON_COLOUR}
                        />
                      </div>
                    }
                  />
                </div>
              </Box>
            </Box>
          </Box>

          {/* Allocation details section */}
          <Box mt="20px">
            <Typography
              style={{
                color: theme.palette.primary.main,
                marginBottom: "12px",
                fontSize: "17px",
              }}
              color="primary"
              variant="subtitle2"
            >
              Allocation Details
            </Typography>

            <MaterialTable
              style={{ boxShadow: "0px 1px 3px #00000033" }}
              columns={columns}
              icons={tableIcons}
              title=""
              data={data.data || []}
              isLoading={inProgress}
              options={tableOptions}
              onChangePage={(page) => {
                TableAction.setPage(dispatch, page);
              }}
              onChangeRowsPerPage={(pageSize) => {
                TableAction.setPageSize(dispatch, pageSize);
              }}
              onSearchChange={(search) => {
                TableAction.setSearchText(dispatch, search);
              }}
              totalCount={totalCount}
              page={page}
            />
          </Box>
        </div>
      ) : (
        <div>
          <LoadingIndicator variant={"block"} />
        </div>
      )}

      <EditPartDetails
        open={isEdit}
        onClose={() => setIsEdit(false)}
        actionDpdOptions={actionDpdOptions}
        currentSelection={currentSelectedRequest}
        data={partInfo}
        updateData={() => {
          getPartDetials();
          getList();
        }}
        closeHandler={onClose}
        getData={getData}
      />

      {isReturnIssuedPart && (
        <ReturnIssuedPart
          currentSelection={currentSelection}
          open={isReturnIssuedPart}
          onClose={() => setIsReturnIssuedPart(false)}
          allocId={allocId}
          partConditionId = {partConditionId}
          qtyFieldTitle="Quantity Received"
          getPopupData={getPopupData}
          updateTable={() => {
            getList();
            getData();
            getPartDetials();
          }}
        />
      )}

      {isMaterialPopupOpen && (
        <CertificatePopup
          open={isMaterialPopupOpen}
          dialogCloseHandler={() => setIsMaterialPopupOpen(false)}
          // selectedSecondaryPart={selectedRow}
          // selectedSecondaryPartId={selectedSecondaryPartId}
          msds={msds}
        />
      )}
    </MROFullScreenRightDrawer>
  );
}

export default PartInfo;
