import React, { useEffect, useState } from "react";
import MROButton from "../buttons";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { Box } from '@material-ui/core'
import FileIcon from "@material-ui/icons/AttachFile";
import { ReactComponent as UploadIcon } from "../../assets/icons/UploadDoc.svg";
import DataService from "../../lib/services/api";
import { useContext } from "react";
import { ToastMessageContext } from "lib/contexts/message_context";


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
    error: {
        borderColor: "red",
        color: "red",
        // fontSize:"12px"
    },
}));


function UploadButton(props) {
    const classes = useStyles();
    const message = useContext(ToastMessageContext)
    const { width, height, disabled, accept, error, visibilityStatus, name, action, multiple, defaultValue, process, id, label, noFileExist, readOnly, labelName } = props;
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);

    const fileList = Array.from(files[0] || []);

    useEffect(() => {
        if (process && files && files[0] && id) {
            setLoading(true);
            const formData = new FormData();
            formData.append(name, files[0]);
            formData.append('id', id);
            DataService.updateData(formData, "", 'authDoc').then((res) => {
                setLoading(false);
            })
        }
    }, [process])

    const single_file = "application/pdf"
    const multiple_file = ".docx, .doc, .xlsx, .xls, .csv, .pdf, .ppt,.pptx, .jpg,.jpeg, .png"
    const video_file = "*"

    const handleChange = (e) => {
        let bytes = e.target.files[0].size
        let fileSize = Math.round(bytes / (1024 * 1024))
        if (multiple && fileSize < 20) {
            setFiles([...files, e.target.files])
        } else if (!multiple && fileSize < 20) {
            setFiles([e.target.files])
        } else {
            message.showToastMessage({
                message: "File too large! - Greater than 20MB",
                variant: "error"
            })
        }
    }

    useEffect(() => {
        typeof props.setFiles === 'function' && props.setFiles([...files]);
    }, [files.length]);

    return (
        <div>
            <Box display="flex" justifyContent={label && "space-between"} alignItems={label && "center"}>
                {label && <Typography className={error ? classes.error : {}} variant="subtitle1">{label}&nbsp;&nbsp;&nbsp;&nbsp;</Typography>}
                <input
                    accept={accept === "single" ? single_file : accept === "multiple" ? multiple_file : "*"}
                    style={{ display: 'none', }}
                    className={error ? classes.error : {}}
                    id={name ?? "upload-file"}
                    multiple={multiple || false}
                    type="file"
                    name={name}
                    onChange={handleChange}
                />
                <label htmlFor={name ?? "upload-file"} style={{ pointerEvents: readOnly && 'none', display: visibilityStatus }}>
                    <MROButton style={{ width: width, height: height }} disabled={disabled} variant="contained" color="secondary" component="span" startIcon={<UploadIcon />}>
                        {action ? action : "Upload"}
                    </MROButton>
                </label>
            </Box>
            {/* {<Typography style={{marginLeft:"130px"}} className={fileList && fileList.length > 0 ? {} : classes.error} >{fileList && fileList.length > 0 ? "": "Please upload a document *"}</Typography>} */}

            <div style={{ padding: '15px 10px' }}>
                {defaultValue && defaultValue?.length > 0 && <Typography variant={"subtitle2"} color="secondary">{noFileExist ?? "Existing Files:"}</Typography>}
                {defaultValue && defaultValue?.map((file, i) => <div key={`file-${i}`}>
                    {file.name ? <Typography style={{ textDecoration: 'none', color: 'inherit' }} component={"a"} href={file.url ? file.url : file.document_url || "#"} target={file.url ? "_blank" : null} className={"card-tile-secondary"}>
                        <FileIcon />&nbsp;&nbsp;{file.name}</Typography> : "No Files found"}
                </div>)}
                {fileList && fileList.length > 0 && <Typography variant={"subtitle2"} color="secondary">{noFileExist ?? "Chosen Files(new):"}</Typography>}
                {fileList && fileList?.map((file, i) => <div key={file?.lastModified}>
                    <Typography component={"div"} className={"card-tile-secondary"}><span style={{ display: visibilityStatus }}><FileIcon />&nbsp;&nbsp;{file.name}</span></Typography>
                </div>)}
                {error && <Typography variant="body1" color="error" style={{ fontSize: 12 }}>Please select the {labelName?.replace("*", "") ?? label?.replace("*", "")}</Typography>}
            </div>
        </div>
    )
}

export default UploadButton;