import { Box, Checkbox, Divider, FormControlLabel, Grid, MuiThemeProvider, Typography, useTheme } from '@material-ui/core'
import MaterialTable from 'material-table'
import React, { useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import TableAction from '../../../../actions/table_actions'
import MROButton from '../../../../components/buttons'
import MROTextField from '../../../../components/form_components/TextField'
import tableIcons, { options } from '../../../../components/universal/table_attributes'
import { ToastMessageContext } from '../../../../lib/contexts/message_context'
import tableReducer, { INITIAL_TABLE_STATE } from '../../../../reducers/table_reducer'
import MROTheme from '../../../../theme/main_theme'
import { Autocomplete } from '@material-ui/lab'
import DataServiceAction from '../../../../actions/data_actions'
import ManageDataDialog from '../../../../components/form_components/manage_data_dialog'
import { mapTasks } from './RenderMaintTable'
import NumericIncrement from './FieldComponents/numeric_increment'
import DataService from '../../../../lib/services/api'
import PositiveSwitch from 'components/form_components/switch'
import NumericIncrementForWorkOrderLae from './FieldComponents/NumericIncrementForWorkOrderLae'
import NumericIncrementForWorkOrderNonLae from './FieldComponents/NumericIncrementForWorkOrderNonLae'
import LineCustomerServiceNew from "lib/services/api/admin/line/line_customers_client";
function AddMaintTasks({ currentSelection, handleClose, setCreatedId, workOrderId, lineCustomerId }) {

    const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
    const { page, totalCount, pageSize, searchText, inProgress, data: _data } = tableState;
    const message = useContext(ToastMessageContext);
    const [selectedAta, setSelectedAta] = useState(null);
    const [selectedAtaFilter, setSelectedAtaFilter] = useState(null);
    const [ataChapters, setAtaChapters] = useState([]);
    const [filters, setFilters] = useState([]);
    const [error, setError] = useState({});
    const [show, setShow] = useState(false)
    const formRef = useRef(null);
    const [state, setState] = useState({
        part_on: '',
        ata_chapter: '',
        description: '',
        // man_hours: '',
        lae_hrs: '',
        non_lae_hrs: ''
    });
    const [lineData, setLineData] = useState({})
    useEffect(() => {
        if (lineCustomerId) {
            LineCustomerServiceNew.getLineCustomerDetails({
                line_customer_id: lineCustomerId,
            })
                .then((res) => {
                    setLineData(res.line_customer)
                })
                .catch((err) =>
                    console.log("something went wrong from part details", err)
                )
                .finally(() => { });
        }
    }, [lineCustomerId]);
    const handleChange = ({ target: { name, value } }) => {

        if (name === 'description' && value?.length < 1) {
            setCurrentRow({
                ...currentRow,
                description: ''
            })
        }
        setState({
            ...state,
            [name]: value
        })

    }

    useEffect(() => {
        let chapters = []
        for (let i = 0;i < 100;i++) {
            let obj = {
                id: i + 1,
                name: `${i < 10 ? `0${i}` : i}`
            }
            chapters.push(obj);
        }
        let ataFilters = []
        for (let i = 0;i < 100;i++) {
            let obj = {
                id: i + 1,
                name: `${i < 10 ? `0${i}` : i}`
            }
            ataFilters.push(obj);
        }

        getAtaChapters()
        // setAtaChapters(chapters);
        // setFilters(ataFilters);
        // setSelectedAtaFilter(ataFilters);
    }, [])

    const getAtaChapters = useCallback(
        () => {
            let params = {

            }
            DataService.getData(params, 'listingATAChapter').then(res => {
                setAtaChapters(res.data.map(d => ({
                    id: d.ata_chapter_id,
                    name: d.chapter_number
                })))
                setFilters(res.data.map(d => ({
                    id: d.ata_chapter_id,
                    name: d.chapter_number
                })))
            }).catch(err => {

            })
        },
        [],
    )

    useEffect(() => {
        let params = {
            page: page + 1,
            count_per_page: pageSize,
            search_text: searchText,
            ata_chapter_id: selectedAtaFilter?.id
        }
        TableAction.getList(dispatch, message, params, 'operationTaskLibrary')
    }, [page, totalCount, pageSize, searchText, selectedAtaFilter?.id])

    const handleCloseMenu = (data = '') => {
        setCreatedId(data)
        handleClose()
    }

    const [errors, setErrors] = useState({});

    const handleSubmit = formData => {
        formData.append('id', workOrderId)
        formData.append('task_type', mapTasks[currentSelection?.child_section_name])
        formData.append('save_task', formData.get('save_task_check') === null ? 'false' : 'true')
        formData.append('ata_chapter_id', selectedAta?.id)
        formData.append('man_hours', Number(state.lae_hrs) + Number(state.non_lae_hrs))
        formData.append("approved", lineData?.direct_library ? true : false)
        let valid = true;
        let error = {};
        if (formData.get('ata_chapter_id') === '') {
            error['ata_chapter_id'] = {
                valid: false
            }
            valid = false;
        } if (formData.get('task_tlp') === '') {
            error['task_tlp'] = {
                valid: false
            }
            valid = false;
        } if (formData.get('description') === '') {
            error['description'] = {
                valid: false
            }
            valid = false;
        } if (formData.get('lae_hrs') === '') {
            error['lae_hrs'] = {
                valid: false
            }
            valid = false;
        } if (formData.get('non_lae_hrs') === '') {
            error['non_lae_hrs'] = {
                valid: false
            }
            valid = false;
        }

        if (!valid) {
            setErrors(error)
            return
        }

        DataServiceAction.createData(dispatch, message, formData, 'operationalTasks', '', handleCloseMenu)
    }

    const columns = [{
        headerStyle: {
            textAlign: 'left',
        },
        cellStyle: {
            textAlign: 'left',
            width: '1%'
        }
    }, {
        title: 'Description',
        field: 'description',
        render: rowData => rowData.description ? rowData.description : '-',
        headerStyle: {
            textAlign: 'left',
            width: '20%',
            width: 100
        },
        cellStyle: {
            textAlign: 'left',
            width: '20%',
            width: 100,
            minWidth: '10%'
        }
    }, {
        title: 'Total Hrs',
        field: 'man_hours',
        headerStyle: {
            textAlign: 'left',
            width: '30%'
        },
        cellStyle: {
            textAlign: 'left',
            width: '30%'
        }
    }]

    const tableOptions = {
        ...options,
        page: page,
        search: true,
        total: totalCount,
        pageSize: pageSize,
        searchText: searchText,
        scroll: false,
    };

    const [currentRow, setCurrentRow] = useState(null);
    useEffect(() => {
        if (currentRow?.ata_chapter) {
            setSelectedAta(ataChapters.find(ata => ata.name == parseInt(currentRow?.ata_chapter)))
        }
        if (currentRow) {
            setState({
                // ...state,
                // man_hours: currentRow.man_hours,
                lae_hrs: currentRow.lae_hrs || "",
                non_lae_hrs: currentRow.non_lae_hrs || ""
            })
        }
    }, [currentRow?.ata_chapter, currentRow?.lae_hrs, currentRow?.non_lae_hrs]);

    useEffect(() => {
        if (currentRow?.non_lae_hrs !== "0.00" && currentRow?.non_lae_hrs !== null && currentRow?.non_lae_hrs !== undefined) {
            setShow(true)
        } else {
            setShow(false)
        }
    }, [currentRow?.non_lae_hrs])

    return (
        <React.Fragment>
            <ManageDataDialog paddingBottom={70} noTitle type="create" noAction handleSubmit={handleSubmit} handleClose={handleClose} maxWidth={"md"} fullWidth >
                <Box height={400} width="100%" mb={8} mt={2}>
                    <Grid container direction={'row'} justify="space-around" style={{ marginBottom: 50 }}>
                        <Grid item xs={4}>
                            <Box height="100%" width="100%" px={0}>
                                <Box mb={1.5}>
                                    <Typography variant="subtitle2" gutterBottom={12}>
                                        Creating New Tasks
                                    </Typography>
                                </Box>
                                <MROTextField
                                    name="task_tlp"
                                    error={errors.task_tlp && !errors?.task_tlp?.valid}
                                    label="Task/TLP # *"
                                    InputLabelProps={{ shrink: true }} />

                                <Autocomplete
                                    fullWidth
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    value={(selectedAta && selectedAta.id) ? selectedAta : ''}
                                    onChange={(event, value) => {
                                        console.log({ value })
                                        setSelectedAta(value); setError({ ...error, ata: false });
                                    }}
                                    id="tags-standard"
                                    name={"ata_chapter_id"}
                                    options={ataChapters}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <MROTextField
                                            {...params}
                                            name={"ata_chapter_id"}
                                            style={{ backgroundColor: '#fff' }}
                                            variant="outlined"
                                            label="ATA Chapter *"
                                            color={"primary"}
                                            placeholder={"All"}
                                            InputLabelProps={{ shrink: true }}
                                            error={errors.ata_chapter_id && !errors?.ata_chapter_id?.valid}
                                            helperText={error.ata ? "*please select a ATA Chapter" : null}
                                        />
                                    )}
                                />

                                <MROTextField
                                    name="description"
                                    label="Description *"
                                    defultValue={currentRow?.description}
                                    value={state.description || currentRow?.description}
                                    onChange={handleChange}
                                    error={errors.description && !errors?.description?.valid}
                                    InputLabelProps={{ shrink: true, }}
                                    multiline
                                    InputProps={{ style: { height: "max-content" } }}
                                    inputProps={{ maxLength: 500 }}
                                />

                                <NumericIncrementForWorkOrderLae
                                    name="lae_hrs"
                                    label="LAE Man-Hours *"
                                    error={errors.lae_hrs && !errors?.lae_hrs?.valid}
                                    defultValue={currentRow?.lae_hrs}
                                    value={state.lae_hrs}
                                    onChange={handleChange}
                                    noIncreament
                                    state={state}
                                    setState={setState}
                                    inputProps={{ min: 0, step: 0.5 }}
                                    InputLabelProps={{ shrink: true }} />

                                <div>
                                    <FormControlLabel
                                        control={
                                            <PositiveSwitch
                                                checked={show}
                                                onChange={() => setShow(!show)}
                                                name="show_not_approved_items"
                                            />
                                        }
                                        label={<b style={{ fontSize: "10px" }}>{"Add Mechanic / Technician (non LAE) Man-Hours ?"}</b>}
                                        labelPlacement="start"
                                        name={"show_not_ready_items"}
                                    />
                                </div>
                                {show &&
                                    <NumericIncrementForWorkOrderNonLae
                                        name="non_lae_hrs"
                                        label="Man-Hours (non LAE) *"
                                        error={errors.non_lae_hrs && !errors?.non_lae_hrs?.valid}
                                        defultValue={currentRow?.non_lae_hrs}
                                        value={state.non_lae_hrs}
                                        onChange={handleChange}
                                        noIncreament
                                        state={state}
                                        setState={setState}
                                        inputProps={{ min: 0, step: 0.5 }}
                                        InputLabelProps={{ shrink: true }} />}

                                <Box display="flex" justifyContent="flex-end" mb={0.5} mt={4}>
                                    {lineData?.direct_library ? <FormControlLabel
                                        style={{ marginRight: 0 }}
                                        control={
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                name="save_task_check"
                                            />
                                        }
                                        label={lineData?.direct_library ? "Add to your library" : "Request to be added to Library"}
                                    /> : " "}
                                </Box>
                                <Box mt={2} display="flex" justifyContent="flex-end">


                                    <MROButton variant="contained" onClick={handleClose}>Cancel</MROButton>
                                    &nbsp;&nbsp;
                                    <MROButton variant="contained" color="primary"
                                        type="submit"
                                    >Add</MROButton>
                                </Box>
                            </Box>

                        </Grid>

                        <Divider orientation="vertical" flexItem />

                        <Grid item xs={6}>
                            <Box height="100%" width="100%" px={0}>
                                <Typography variant="subtitle2" gutterBottom={12}>
                                    Importing task from library
                                </Typography>
                                <Box mb={1}>
                                    <Box width="80%" mr={2} my={1}>
                                        <Autocomplete
                                            fullWidth
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            value={(selectedAtaFilter && selectedAtaFilter.id) ? selectedAtaFilter : ''}
                                            onChange={(event, value) => {
                                                setSelectedAtaFilter(value);
                                            }}
                                            id="tags-standard"
                                            name={"ata_chapter_id"}
                                            options={filters}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => (
                                                <MROTextField
                                                    {...params}
                                                    name={"ata_chapter_id"}
                                                    style={{ backgroundColor: '#fff' }}
                                                    variant="outlined"
                                                    label="ATA Chapter"
                                                    color={"primary"}
                                                    placeholder={"All"}
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Box>
                                <Box>
                                    {/* <MuiThemeProvider theme={createMuiTheme({
                                        ...MROTheme,
                                        overrides: {
                                            ...MROTheme.overrides,
                                            MuiInputBase: {
                                                root: {
                                                    width: '100% !important'
                                                }
                                            },
                                            MuiMenu: {
                                                paper: {
                                                    minWidth: '80px !important'
                                                }
                                            },
                                            MuiTablePagination: {
                                                toolbar: {
                                                    width: `${MROTheme.spacing(15)}px !important`
                                                },
                                                root: {
                                                    padding: '0px 10px !important'
                                                }
                                            }
                                        }
                                    })}> */}

                                    <MaterialTable
                                        options={{
                                            ...tableOptions,
                                            rowStyle: rowData => ({
                                                backgroundColor: (currentRow?.id === rowData.id) ? '#eee' : '#FFF',
                                            })
                                        }}
                                        icons={tableIcons}
                                        title=""
                                        data={_data.task_libraries}
                                        columns={columns}
                                        isLoading={inProgress}
                                        onChangePage={(page) => {
                                            TableAction.setPage(dispatch, page);
                                        }}
                                        onChangeRowsPerPage={(pageSize) => {
                                            TableAction.setPageSize(dispatch, pageSize);
                                        }}
                                        onSearchChange={(search) => {
                                            TableAction.setSearchText(dispatch, search);
                                        }}
                                        onRowClick={(e, rowData) => { setCurrentRow(rowData) }}
                                        totalCount={totalCount}
                                        page={page}
                                    />
                                    {/* </MuiThemeProvider> */}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </ManageDataDialog>
        </React.Fragment>
    )
}


export default React.memo(AddMaintTasks);
