import React, { useContext, useEffect, useState } from 'react'
import MaterialTable from 'material-table';
import tableIcons, { options } from 'components/universal/table_attributes';
import ViewIcon from "@material-ui/icons/Visibility";
import CopyFileIcon from "assets/CopyFiles.svg"
import GreenCopyIcon from "assets/GreenCopyIcon.svg"
import { ICON_COLOUR } from 'lib/constants/style_constants';
import { IconButton, Box } from '@material-ui/core';
import TechFilesPopup from '../TechFilesPopup';
import Stages from '../Stages';
import { WorkshopContext } from "views/Operations/Workshop/WorkshopContext/WorkshopContext";
import { MaintenanceServices } from 'lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation';
import { ToastMessageContext } from 'lib/contexts/message_context';


function Actions(props) {
    const { currentActionData, currentWorkshopDetails, setCurrentActionId, projectPartId } = props
    const [action, setAction] = useState(false)
    const [currentSelection, setCurrentSelection] = useState({})
    const [currentSelectedRow, setCurrentSelectedRow] = useState({})
    const [isTechFiles, setIsTechFiles] = useState(false)
    const [actionData, setActionData] = useState([])
    const [files, setFiles] = useState([])

    const { copyInspection, setCopyInspection, copiedJobs } = useContext(WorkshopContext)
    const message = useContext(ToastMessageContext)



    useEffect(() => {
        let data = { ...currentActionData }
        setActionData([data])
    }, [currentActionData])


    // Copy Actions 

    const handleSubmit = async (rowData) => {
        const formData = new FormData()

        formData.append("workshop_order_id", currentWorkshopDetails?.id)
        formData.append("workshop_action_id", rowData.workshop_action_id)
        formData.append("action_type", rowData.action_type)
        if (projectPartId.id !== null) {
            formData.append("project_part_id", projectPartId.id)
        }
        let userDetails = JSON.parse(sessionStorage.getItem("user"));
        formData.append("raised_by", userDetails?.id);
        setFiles(rowData.maintenance_file)
        files.forEach((file) => formData.set("maintenance_file[]", file))

        await MaintenanceServices.copyPreviousCreateAction(formData).then((response) => {
            if (response.success) {
                setCopyInspection(true)
                setCurrentActionId(response.action_id)
                copiedJobs.push(currentActionData.workshop_action_id)
                message.showToastMessage({
                    message: "Copied successfully ",
                    variant: "success",
                });
            }

        }).catch((error) => {
            message.showToastMessage({
                message: "Something went wrong",
                variant: "error",
            });
        })
    }





    const tableOptions = {
        ...options,
        paging: false,
        search: false,
        toolbar: false,
        rowStyle: { height: 42 },
    };

    const inspectionColumns = [
        {
            title: "Action #",
            field: "action_number",
            headerStyle: {
                textAlign: "left",
                paddingLeft: "20px"
            },
            cellStyle: {
                textAlign: "left",
                paddingLeft: "20px"
            },

        }, {
            title: "Title",
            field: "title",
            headerStyle: {
                textAlign: "left",
            },
            cellStyle: {
                textAlign: "left",
                width: "50%",
            },
        }, {
            title: "Est Man-Hrs",
            field: "estimate_man_hours",
            headerStyle: {
                textAlign: "center",
            },
            cellStyle: {
                textAlign: "center",
            },
        },
        {
            title: "Details",
            // field: "maintenance_file",
            headerStyle: {
                textAlign: "center",
            },
            cellStyle: {
                textAlign: "center",
            },
            render: (rowData) => (
                <div>
                    <IconButton>
                        <ViewIcon fontSize="small" style={{ color: ICON_COLOUR }} onClick={() => {
                            setIsTechFiles(true)
                            setAction("view_files")
                            setCurrentSelectedRow(rowData)
                        }}
                        />
                    </IconButton>

                </div>

            )


        },
        {
            title: "Stages",
            field: "stages",
            headerStyle: {
                textAlign: "center",
            },
            cellStyle: {
                textAlign: "center",
            },
            render: (rowData) => (
                <div>
                    <a style={{ textDecoration: "underline" }} onClick={() => {
                        setAction("view_stages")
                        setCurrentSelection(rowData)
                    }}>{rowData.stages}</a>
                </div>
            )
        },
        {
            title: "Action",
            headerStyle: {
                textAlign: "center",
            },
            cellStyle: {
                textAlign: "center",
            },
            render: (rowData) => (
                <div>
                    <IconButton
                        onClick={() => {
                            handleSubmit(rowData)
                        }}
                        disabled={copyInspection === true && copiedJobs.find((item) => item === rowData.workshop_action_id)}
                    >
                        {copyInspection === true && copiedJobs.find((item) => item === rowData.workshop_action_id) ? <img src={GreenCopyIcon} height="25px" style={{ pointerEvents: copyInspection === true && copiedJobs.find((item) => item === rowData.workshop_action_id) ? "none" : "", }} /> :

                            <img src={CopyFileIcon} height="25px" />
                        }

                    </IconButton>
                </div >
            )
        }

    ]

    return (
        <div>

            <Box pl={'2px'} pr={'8px'}>
                <MaterialTable
                    style={{
                        boxShadow: "0px 1px 3px #00000033",
                        marginTop: "10px",
                    }}
                    icons={tableIcons}
                    title={""}
                    columns={inspectionColumns}
                    data={actionData}
                    // isLoading={actionDetails}
                    options={tableOptions}

                />
            </Box>
            {
                action === 'view_files' && (
                    <TechFilesPopup
                        handleClose={() => setAction(null)}
                        currentSelectedRow={currentSelectedRow}
                        open={isTechFiles}
                    />
                )
            }
            {
                action === 'view_stages' && (
                    <Stages
                        handleClose={() => setAction(null)}
                        currentSelection={currentSelection}
                    />

                )

            }
        </div>
    )
}

export default React.memo(Actions)