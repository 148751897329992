import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  IconButton,
  Box,
  TextField,
  FormControl,
  Select,
  InputBase,
  Menu,
  TableRow,
  TableCell,
  TableBody,
  MenuItem,
  TableContainer,
  Tooltip,
} from "@material-ui/core";
import React, { useContext, useEffect, useReducer, useState } from "react";
import tableReducer from "reducers/table_reducer";
import tableIcons, { options } from "components/universal/table_attributes";
import ViewIcon from "@material-ui/icons/Visibility";
import { ICON_COLOUR } from "lib/constants/style_constants";
import MaterialTable, { MTableToolbar } from "material-table";
import { INITIAL_TABLE_STATE } from "reducers/table_reducer";
import ManageInspection from "./ManageInspection";
import ActionDetails from "./ActionDetails";
import ManageTask from "./ManageTask/ManageTask";
import AddIcon from "@material-ui/icons/AddBox";
import certificate from "assets/certificate.png";
import { Create as CreateIcon } from "@material-ui/icons";
import { useTheme } from "@material-ui/core";
import clxc from "clsx";
// import { MenuItem } from "rc-menu";
import ActionInformation from "./ActionInformation/ActionInformation";
import StageInfo from "./StageInformation/StageInfo";
import AddStageTab from "./AddStage/AddStageTab";
import MROButton from "components/buttons";
import CautionPopUp from "../CautionPopUp";
import CalendarActionDetails from "./CalenderActionDetails";
import CalendarActionInfo from "./CalendarActionInfo";
import AmberGear from "assets/AmberGear.png";
import greenCheckIcon from "assets/green check.png";
import CameraIcon from "assets/camera.png";
import commentIcon from "assets/comment.png";
import infoIcon from "assets/info.png";
import GreenChecklist from "assets/green_checklist.png";
import AmberChecklist from "assets/amber_checklist.png";
import RedChecklist from "assets/red_checklist.png";
import GreenHandIcon from "assets/green_hand.png";
import AmberHandIcon from "assets/amber_hand.png";
import RedHandIcon from "assets/red_hand.png";
import Certificate from "./ManageCertificate/Certificate";
import { alpha } from "@material-ui/core";
import { ToastMessageContext } from "lib/contexts/message_context";
import Comment from "./ManageComments/Comment";
import lock from "assets/icons/Lock.svg";
import unlock from "assets/icons/Unlock.svg";
import { InspectionServices } from "lib/services/api/operaitons/WorkShop/Inspection/InspectionService";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";
import { WorkshopContext } from "../WorkshopContext/WorkshopContext";
import { formatDateFunction, formatDateTime } from "lib/utils/common_utils";
import SpecEquipIconGrey from "assets/maint/SpecEquip/specEquipGray.png";
import SpecEquipIconAmber from "assets/maint/SpecEquip/specEquipIconAmber.png";
import SpecEquipIconRed from "assets/maint/SpecEquip/specEquipIconRed.png";
import SpecEquipIconGreen from "assets/maint/SpecEquip/specEquipIconGreen.png";
import RedCrossIcon from 'assets/Red remove icon.png'
import ServicesIconAmber from "assets/maint/Services/servicesIconAmber.png";
import ServicesIconGray from "assets/maint/Services/services_icon_grey.png";
import ServicesIconRed from "assets/maint/Services/servicesIconRed.png";
import ServiceIconGreen from "assets/maint/Services/ServicesIconGreen.png/";

import PartsIconGrey from "assets/maint/Parts/partsIconGray.png";
import PartsIconGreen from "assets/maint/Parts/partsIconGreen.png";
import PartsIconRed from "assets/maint/Parts/partsIconRed.png";
import PartsIconAmber from "assets/maint/Parts/partsIconAmber.png";

import Gear from 'assets/gear.svg'
import GearGreen from 'assets/gear-green.svg'
import GearRed from 'assets/gear-red.svg'
import AddActions from "./CreateAction";

import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import ReorderAction from "./ReorderAction";
import ReorderStages from "./ReorderStages";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "85%",
    margin: "1% 3%",
  },
  tableCell: {
    display: "flex",
    justifyContent: "space-between",
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
  stockBtn: {
    minWidth: "70px",
    height: "30px",
    borderColor: "#4D4F5C",
    borderRadius: "7px",
    maxWidth: "78px",
  },
  paper: {
    borderRadius: "5px",
    backgroundColor: "#fff",
    padding: theme.spacing(3),
    boxShadow: "0px 1px 4px #0000001A",
  },
  secondPaper: {
    height: "auto",
    borderRadius: "10px",
    backgroundColor: "#fff",
    paddingBottom: theme.spacing(2),
    boxShadow: "0px 1px 4px #0000001A",
  },
  input1: {
    height: "20px",
  },
  head: {
    marginTop: "20px",
    marginBottom: "10px",
    fontSize: "17px",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: "5px 38px",
  },
  viewOnlyField: {
    pointerEvents: "none",
    backgroundColor: "#F5F5F5",
  },
  svg: {
    filter: "invert(1)",
    height: 18,
    marginBottom: 2,
  },
}));

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        color: "black",
        p: 0.5,
        m: 0.5,
        borderRadius: 5,
        textAlign: "center",
        fontSize: "1rem",
        fontWeight: "700",
        ...sx,
      }}
      {...other}
    />
  );
}

function Inspection(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    actions,
    tabId,
    workshop_id,
    action_type_Insp,
    actionTypeMaintenance,
    action_type_prod,
    readOnly,
    readAndWrite,
    orderStateNum,
    tabStatus,
    getWorkshopOrderDetails,
    orderState,
    workshopOrder,
    actionTabId,
    copyTab,
    tabDetails
  } = props;

  // let inspSection = orderStateNum > 3 && orderStateNum <= 5 && tabStatus === true
  // let production = orderStateNum > 5 && orderStateNum <= 8 && tabStatus === true && action_type_prod === "Production"
  // let MaintSection = orderStateNum > 5 && orderStateNum <= 8 && tabStatus === true && actionTypeMaintenance === "Maintenance"
  const message = useContext(ToastMessageContext);
  const [tableState, dispatch] = useReducer(tableReducer, INITIAL_TABLE_STATE);
  // const { data, page, pageSize, totalCount, inProgress } = tableState;
  const { colorStatus, setColorStatus } = useContext(WorkshopContext);
  const [action, setAction] = useState(false);
  const [open, setOpen] = useState(false);
  const [pages, setPages] = useState(0);
  const [totalCounts, setTotalCounts] = useState(5);
  const [pageSizes, setPageSizes] = useState(10);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(5);
  const [pageSize, setPageSize] = useState(10);
  const [openMenu, setOpenMenu] = useState(false);
  const [actionState, setActionState] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editTab, setEditTab] = useState(false);
  const [selected, setSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [allActions, setAllActions] = useState([]);
  const [currentSelection, setCurrentSelection] = useState({});
  const [infoSelectedId, setInfoSelectedId] = useState(null);
  const [isGettingDetails, setIsGettingDetails] = useState(false);
  const [actionStages, setActionStages] = useState([]);
  const [status, setStatus] = useState(null);
  const [selectedStages, setSelectedStages] = useState({});
  const [access, setAccess] = useState({ read: false, write: true });

  const [state, setState] = useState("");
  // const debouncedVal = useDebounce(searchText);

  useEffect(() => {
    if (readOnly) {
      setAccess({ read: true, write: false });
      return;
    }

    if (readAndWrite) {
      setAccess({ read: false, write: true });
      return;
    }
  }, [readAndWrite, readOnly]);

  let action_type =
    actions === "Maintenance"
      ? actionTypeMaintenance
      : actions === "Production"
        ? action_type_prod
        : action_type_Insp;


  // console.log("insp is====================", action_type, tabDetails)

  useEffect(() => {
    if (currentSelection?.id) getAllActionStages();
  }, [currentSelection?.id, page, pageSize, status]);

  const getAllActionStages = async () => {
    setIsGettingDetails(true);
    const params = {
      workshop_action_id: currentSelection?.id,
      page: page + 1,
      limit: pageSize,
      status: status,
      // search: debouncedVal,
    };
    await InspectionServices.getAllActionStages(params)
      .then((res) => {
        if (res) {
          setActionStages(res.data);
          setTotalCount(res.total_count);
        }
      })
      .catch((err) => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      })
      .finally(() => {
        setIsGettingDetails(false);
      });
  };

  useEffect(() => {
    getAllActions();
  }, [pages, pageSizes, selectedRowId]);

  const getAllActions = async () => {
    setLoading(true);
    const params = {
      workshop_order_id: workshop_id,
      action_type: action_type,
      page: pages + 1,
      limit: pageSizes,
    };
    await MaintenanceServices.getAllActions(params)
      .then((res) => {
        if (res) {
          setAllActions(res.data);
          res.data.map((item) => {
            if (selectedRowId === item.id) {
              setColorStatus(item.planning_verification);
              setState(item.status_color)
            }
          });
          setTotalCounts(res.total_count);
        }
        setLoading(false);
      })
      .catch((err) => {
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
        setLoading(false);
      });
  };

  const handleROw = () => {
    console.log("called func");
  };

  const handleTab = () => {
    setEditTab(!editTab);
    setAction("unlock");
  };
  const handleClickOpen = () => {
    setAction("camera");
    setOpen(true);
  };

  const handleClickMsg = () => {
    setAction("msg");
    setOpen(true);
  };
  const handleClickCertificate = () => {
    setAction("certificate");
    setOpen(true);
  };

  const handleInfo = () => {
    setAction(tabId === 23 ? "infoCalendar" : "info");
    setOpen(true);
  };

  const handleClick = (event) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const onRowClick = (event, rowData) => {
    setInfoSelectedId(rowData?.id);
    setCurrentSelection(rowData);
    setSelectedRowId(rowData.id);
    if (rowData.id === selectedRowId) {
      setSelected(true);
      setSelectedRowId(rowData.id);
    } else {
      setSelected(true);
      setSelectedRowId(rowData.id);
    }
  };

  const ITEM_HEIGHT = 48;

  const tableOptions = {
    ...options,
    page: pages,
    total: totalCounts,
    pageSize: pageSizes,
    search: false,
    toolbar: true,
    rowStyle: { height: 42 },
  };
  const tableOptions2 = {
    ...options,
    page: page,
    total: totalCount,
    pageSize: pageSize,
    search: false,
    toolbar: true,
    rowStyle: { height: 42 },
  };

  const closureMessage = [
    {
      id: 1,
      name: "Opening this tab will undo closure certification!",
    },
    {
      id: 2,
      name: "The Order State will be taken backward to this tab stage!",
    },
    {
      id: 3,
      name: "The tab will need to be closed again by a certifier in the APP!",
    },
  ];

  const columns = [
    {
      title: "Action #",
      field: "action_number",
      width: "5%",
    },
    {
      title: "Title",
      field: "title",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        textAlign: "left",
        width: "30%",
      },
    },
    {
      title: "State",
      field: "state",
      render: (rowData) => {
        if (rowData?.status_color === "amber") {
          return (
            <Box display="flex" justifyContent="center">
              {" "}
              <img src={AmberGear} alt="Icon" style={{ height: "18px" }} />{" "}
            </Box>
          );
        } else if (rowData?.status_color === "green") {
          return (
            <Box display="flex" justifyContent="center">
              {" "}
              <img
                src={GearGreen}
                alt="Icon"
                style={{ height: "18px" }}
              />{" "}
            </Box>
          );
        } else if (rowData?.status_color === "red") {
          return (
            <Box display="flex" justifyContent="center">
              {" "}
              <img
                src={GearRed}
                alt="Icon"
                style={{ height: "18px" }}
              />{" "}
            </Box>
          );
        } else if (rowData?.status_color === "red_cross") {
          return (
            <Box display="flex" justifyContent="center">
              {" "}
              <img
                src={RedCrossIcon}
                alt="Icon"
                style={{ height: "18px" }}
              />{" "}
            </Box>
          );
        }
        else {
          return (
            <Box display="flex" justifyContent="center">
              {" "}
              <img
                src={greenCheckIcon}
                alt="Icon"
                style={{ height: "18px" }}
              />{" "}
            </Box>
          );
        }
      },
    },
    {
      title: "Status",
      field: "status",
      headerStyle: {
        width: "30%"
      },
      cellStyle: {
        width: "30%"
      },
      render: (rowData) => {
        return (
          <Grid
            container
            spacing={2}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {" "}
            {rowData?.planning_verification !== null && (
              <Grid item>
                {/* <img src={GroupIcon} alt="Icon" style={{ height: "18px" }} />{" "} */}
                <img
                  src={
                    rowData?.part_status.toLowerCase() ===
                      "green".toLowerCase()
                      ? PartsIconGreen
                      : rowData?.part_status.toLowerCase() ===
                        "amber".toLowerCase()
                        ? PartsIconAmber
                        : rowData?.part_status.toLowerCase() ===
                          "red".toLowerCase()
                          ? PartsIconRed :
                          PartsIconGrey
                  }
                  alt="Icon"
                  style={{ height: "18px" }}
                />{" "}
                <img
                  src={
                    rowData?.planning_verification.toLowerCase() ===
                      "Verified".toLowerCase()
                      ? GreenChecklist
                      : rowData?.planning_verification.toLowerCase() ===
                        "Not Verified".toLowerCase()
                        ? AmberChecklist
                        : RedChecklist
                  }
                  alt="Icon"
                  style={{ height: "18px", marginRight: "3px" }}
                />
                <img
                  src={
                    rowData?.commercial_verification.toLowerCase() ===
                      "approved".toLowerCase()
                      ? GreenHandIcon
                      : rowData?.commercial_verification.toLowerCase() ===
                        "not approved".toLowerCase()
                        ? AmberHandIcon
                        : RedHandIcon
                  }
                  alt="Icon"
                  style={{ height: "18px" }}
                />{" "}
                <img
                  src={
                    rowData?.service_status.toLowerCase() ===
                      "green".toLowerCase()
                      ? ServiceIconGreen
                      : rowData?.service_status.toLowerCase() ===
                        "amber".toLowerCase()
                        ? ServicesIconAmber
                        : rowData?.service_status.toLowerCase() ===
                          "red".toLowerCase()
                          ? ServicesIconRed :
                          ServicesIconGray
                  }
                  alt="Icon"
                  style={{ height: "18px" }}
                />{" "}
                <img
                  src={
                    rowData?.equipment_status.toLowerCase() ===
                      "green".toLowerCase()
                      ? SpecEquipIconGreen
                      : rowData?.equipment_status.toLowerCase() ===
                        "amber".toLowerCase()
                        ? SpecEquipIconAmber
                        : rowData?.equipment_status.toLowerCase() ===
                          "red".toLowerCase()
                          ? SpecEquipIconRed :
                          SpecEquipIconGrey
                  }
                  alt="Icon"
                  style={{ height: "18px" }}
                />{" "}


              </Grid>
            )}
          </Grid>
        );
      },
    },
    {
      title: "Info",
      render: (rowData) => (
        <IconButton
        // onClick={() => onRowClick(null, rowData)}
        >
          <ViewIcon
            fontSize="small"
            htmlColor={ICON_COLOUR}
            onClick={() => setAction("info")}
          />
        </IconButton>
      ),
    },
    {
      title: "Plan Man-Hrs",
      field: "estimate_man_hours",
    },
    {
      title: "Act Man-Hrs",
      field: "actual_man_hours",
    },
    {
      title: "Action",
      render: (rowData) => renderButton(rowData),
    },
  ];

  const columnsData = [
    {
      title: "",
      field: "id",
      width: "4%",
      render: () => <span>&nbsp;</span>,
    },
    {
      title: "Stage",
      field: "stage",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        padding: "10px 5px",
      },
      render: (rowData) => <span>{rowData?.stage_position}</span>,
    },
    {
      title: "Title",
      field: "description",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        width: "40%",
        textAlign: "left",
      },
      render: (rowData) => <span>{rowData?.stage_name}</span>,
    },
    {
      title: "Sub-Fields",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        width: "20%",
        textAlign: "left",
      },
      render: (rowData) => (
        <span>
          {!rowData?.stage_fields?.length ? "0" : rowData?.stage_fields?.length}
        </span>
      ),
    },
    {
      title: "Mech",
      field: "mech_userstamp",
      headerStyle: {
        textAlign: "left",
      },
      cellStyle: {
        padding: "10px 5px",
      },
      render: rowData => {
        if (rowData?.mech_userstamp !== null) {
          return rowData?.mech_userstamp
        } else {
          return "-"
        }
      }
    },
    {
      title: "Insp",
      field: "insp_userstamp",
      headerStyle: {
        textAlign: "center",
      },
      render: rowData => {
        if (rowData?.insp_userstamp !== null) {
          return rowData?.insp_userstamp
        } else {
          return "-"
        }
      }
    },
    {
      title: "Closed",
      field: "closed_date_time",
      headerStyle: {
        textAlign: "center",
      },
      render: rowData => {
        if (rowData?.closed_date_time !== null) {
          return formatDateFunction(rowData?.closed_date_time)
        } else {
          return "-"
        }
      }
    },
    {
      title: "Action",
      render: (rowData) => (
        <div id={`div-${rowData.id}`}>
          <ViewIcon
            fontSize="small"
            htmlColor={ICON_COLOUR}
            onClick={(e) => {
              setSelectedStages(rowData);
              setAction(tabId === 23 ? "view" : "viewAction");
            }}
          />
        </div>
      ),
    },
  ];

  const renderButton = (rowData) => {
    // setCurrentSelection(rowData)
    let ButtonColor, buttonText;
    if (
      (rowData?.id === infoSelectedId && actionState === "Verified") ||
      rowData?.planning_verification === "Verified"
    ) {
      setActionState(null);
      ButtonColor = "button-positive";
      buttonText = "VERIFIED";
    } else if (
      (rowData?.id === infoSelectedId && actionState === "Not Verified") ||
      rowData?.planning_verification === "Not Verified" ||
      rowData?.planning_verification === null
    ) {
      setActionState(null);
      ButtonColor = "button-warning";
      buttonText = "VERIFY";
    }

    else if (
      (rowData?.id === infoSelectedId && actionState === "Rejected") ||
      rowData?.planning_verification === "Rejected"
    ) {
      setActionState(null);
      ButtonColor = "button-negative";
      buttonText = "REJECTED";
    }
    return (
      <div>
        <MROButton
          size="small"
          className={clxc(
            classes.button,
            ButtonColor,
            classes.stockBtn,
            access.read ? classes.viewOnlyField : rowData.status === "Closed" ? classes.viewOnlyField : null
          )}
          style={{ pointerEvents: orderState === "Closed" || rowData.status === "Closed" || !access.write && !props?.permission.write && "none" }}
          variant={"outlined"}
          onClick={(e) => props?.permission.write && handleClick(e)}
        >
          {
            <span style={{ fontSize: "12px", marginLeft: "5px" }}>
              {buttonText}
            </span>
          }
          <CreateIcon style={{ marginLeft: "5px" }} fontSize="small" />
        </MROButton>
      </div>
    );
  };

  const options1 = ["Verify", "Unverify"];

  const handleOption = (option) => {
    if (option === "Verify") {
      setActionState("Verified");
      setOpenMenu(false);
    } else if (option === "Unverify") {
      setActionState("Not Verified");
      setOpenMenu(false);
    }
    // else if (option === "Reject") {
    //   setActionState("Rejected");
    //   setOpenMenu(false);
    // }
  };

  /*This is handleClose function */
  const handleClose = () => {
    setAction(null);
  };

  useEffect(() => {
    updateVerification();
  }, [actionState]);

  const updateVerification = async (e) => {
    if (actionState) {
      const formData = new FormData();
      formData.append("action_id", currentSelection?.id);
      let userDetails = JSON.parse(sessionStorage.getItem("user"));
      formData.append("verified_by", userDetails?.id);
      formData.append("planning_verification", actionState);
      await InspectionServices.updateActionVerification(
        formData,
        currentSelection?.id
      )
        .then((res) => {
          if (res) {
            getAllActions();
            message.showToastMessage({
              message: "Updated successfully!!",
              variant: "success",
            });
          }
        })
        .catch((error) => {
          // setBusy(null);
          message.showToastMessage({
            message: `${error.error}`,
            variant: "error",
          });
        });
      // setBusy(null);
    }
  };

  return (
    <div>
      <Typography color="primary" variant="h5" className={classes.head}>
        {`${actions} Actions`}
      </Typography>
      <MaterialTable
        style={{ boxShadow: "0px 1px 3px #00000033", marginTop: "10px" }}
        icons={tableIcons}
        title={props?.permission.write && (tabDetails === 1 || tabDetails === 2) && <div style={{ paddingTop: '10px' }}><Tooltip title="Reorder actions"><ViewHeadlineIcon color="secondary" style={{ fontSize: "31px", marginTop: '-8px', cursor: 'pointer' }} onClick={() => {
          setAction("reorder")
        }} /></Tooltip></div>}
        isLoading={loading}
        columns={columns}
        data={allActions || []}
        options={{
          ...tableOptions,
          rowStyle: (rowData) => ({
            backgroundColor:
              selected && rowData.id === selectedRowId
                ? alpha(theme.palette.primary.main, 0.15)
                : "#fff",
          }),
        }}
        localization={{
          body: {
            emptyDataSourceMessage: "No Records Found",
          },
        }}
        onChangePage={(page) => {
          setPages(page);
        }}
        onChangeRowsPerPage={(pageSize) => {
          setPageSizes(pageSize);
        }}
        onRowClick={onRowClick}
        totalCount={totalCounts}
        page={pages}
        actions={props?.permission.write && [

          {
            icon: () => (
              <div style={{ display: access.read ? "none" : undefined }}>
                <AddIcon color="secondary" />
              </div>
            ),
            tooltip: "Add",
            isFreeAction: true,
            onClick: () =>
              setAction(tabId === 23 ? "createCalendar" : "create"),
          },

        ]}
      />
      {openMenu && (
        <Menu
          id={`long-menu`}
          anchorEl={anchorEl}
          keepMounted
          open={openMenu}
          onClose={handleCloseMenu}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "13ch",
            },
          }}
        >
          {options1.map((option) => (
            <MenuItem
              key={option}
              style={{ fontSize: "14px" }}
              onClick={() => {
                handleOption(option);
              }}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      )}

      {selectedRowId !== null && (
        <div>
          <Typography color="primary" variant="h5" className={classes.head}>
            Selected Action
          </Typography>
          <Grid className={classes.secondPaper}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderRadius: "10px 10px 0px 0px",
                p: 0,
                m: 0,
                bgcolor:
                  selectedRowId && state === "green_tick" || state === "green"
                    ? alpha("#4fc605", 0.15)
                    : state === "amber"
                      ? alpha("#ffb300", 0.15)
                      : state === "red" || state === "red_cross" ?
                        alpha("#FF0000", 0.15) : ""
              }}
            >
              <div>
                <Item>
                  <Typography
                    style={{
                      fontSize: "18px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span style={{ fontSize: "30px" }}>
                      {currentSelection?.action_number || ""}
                    </span>
                    &nbsp;&nbsp;&nbsp;{currentSelection?.title || ""}
                  </Typography>
                </Item>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Item>
                  <Grid item xs={4}>
                    <TextField
                      //size="small"
                      variant="outlined"
                      label="ATA"
                      value={currentSelection?.ata_chapter_id || ""}
                      inputProps={{ style: { textOverflow: "ellipsis" } }}
                      style={{
                        width: "50px",
                        pointerEvents: "none",
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Item>
                <Item>
                  <TextField
                    //size="small"
                    aria-readonly
                    variant="outlined"
                    label="Mech Auth"
                    inputProps={{ style: { textOverflow: "ellipsis" } }}
                    value={currentSelection?.mech_auth || ""}
                    style={{ width: "100px", pointerEvents: "none" }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Item>
                <Item>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      variant="outlined"
                      label="Insp Auth"
                      inputProps={{ style: { textOverflow: "ellipsis" } }}
                      value={currentSelection?.insp_auth || ""}
                      style={{ width: "100px", pointerEvents: "none" }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Item>
                <Item>
                  <IconButton onClick={() => handleClickOpen("")}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {" "}
                      <img
                        src={CameraIcon}
                        alt="Icon"
                        style={{ height: "18px" }}
                      />{" "}
                    </Box>
                  </IconButton>
                </Item>
                <Item>
                  <IconButton onClick={() => handleClickCertificate("")}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems={"center"}
                    >
                      {" "}
                      <img
                        src={certificate}
                        alt="Icon"
                        style={{ height: "18px" }}
                      />{" "}
                    </Box>
                  </IconButton>
                </Item>
                <Item>
                  <IconButton onClick={() => handleClickMsg("")}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems={"center"}
                    >
                      {" "}
                      <img
                        src={commentIcon}
                        alt="Icon"
                        style={{ height: "18px" }}
                      />{" "}
                    </Box>
                  </IconButton>
                </Item>
                <Item>
                  <IconButton onClick={() => handleInfo("")}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems={"center"}
                    >
                      {" "}
                      <img
                        src={infoIcon}
                        alt="Icon"
                        style={{ height: "18px" }}
                      />{" "}
                    </Box>
                  </IconButton>
                </Item>
              </div>
            </Box>

            <Grid style={{ margin: "2%" }}>
              <Typography variant="subtitle2" color="primary" gutterBottom>
                {" "}
                Table Filters
              </Typography>
              <FormControl className={"filter"}>
                <Select
                  defaultValue={undefined}
                  displayEmpty
                  input={<InputBase />}
                >
                  <MenuItem value={undefined}>Stage (All)</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={"filter"}>
                <Select
                  defaultValue={undefined}
                  displayEmpty
                  input={<InputBase />}
                  // value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <MenuItem value={undefined}>Status (All)</MenuItem>
                  <MenuItem value={"Open"}>Open</MenuItem>
                  <MenuItem value={"Closed"}>Closed</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid style={{ margin: "2%" }}>
              <MaterialTable
                style={{
                  boxShadow: "0px 1px 3px #00000033",
                  // paddingRight: "20px",
                }}
                isLoading={isGettingDetails}
                icons={tableIcons}
                title={props?.permission.write && (tabDetails === 1 || tabDetails === 2) && <div style={{ paddingTop: '10px' }}><Tooltip title="Reorder stages"><ViewHeadlineIcon color="secondary" style={{ fontSize: "31px", marginTop: '-8px', cursor: 'pointer' }} onClick={() => {
                  setAction("reorder_stages")
                }} /></Tooltip></div>}
                columns={columnsData}
                data={actionStages || []}
                options={tableOptions2}
                localization={{
                  body: {
                    emptyDataSourceMessage: "No Records Found",
                  },
                }}
                onChangePage={(page) => {
                  setPage(page);
                }}
                onChangeRowsPerPage={(pageSize) => {
                  setPageSize(pageSize);
                }}
                totalCount={totalCount}
                page={page}
                onRowClick={handleROw}
                detailPanel={(rowData) =>
                  !rowData?.stage_fields?.length ? (
                    <div
                      style={{
                        marginLeft: "120px",
                        wordWrap: "break-word",
                        opacity: "50%",
                        padding: "10px",
                      }}
                    >
                      <span style={{ fontSize: "14px" }}>
                        No sub fields found
                      </span>
                    </div>
                  ) : (
                    <TableContainer
                      sx={{
                        width: "max-content",
                      }}
                    >
                      {" "}
                      <TableBody>
                        {rowData?.stage_fields?.map((item, index) => (
                          <TableRow>
                            <TableCell style={{ padding: "10px" }}>
                              <Grid container className={classes.tableCell}>
                                <Grid item style={{ marginLeft: "140px" }}>
                                  {index + 1}
                                  {/* {index === 0 ? index + 1.1 : `${1}${"."+index}`} */}
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  style={{ marginRight: "20px" }}
                                >
                                  {item?.field_name}
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  style={{ marginRight: "133px" }}
                                >
                                  {item?.field_data}
                                </Grid>
                                <Grid
                                  style={{
                                    paddingLeft: "770px",
                                  }}
                                ></Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </TableContainer>
                  )
                }

                actions={props?.permission.write && [
                  {
                    icon: () => (
                      <div
                        style={{ display: access.read ? "none" : undefined }}
                      >
                        <AddIcon color="secondary" />
                      </div>
                    ),
                    tooltip: "Add",
                    isFreeAction: true,
                    onClick: () => setAction("addButton"),
                  },
                ]}
                components={{
                  Toolbar: (props) => (
                    <div
                      style={{
                        height: "40px",
                      }}
                    >
                      <MTableToolbar {...props} />
                    </div>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </div>
      )}

      {action === "view" && <ManageInspection handleClose={handleClose} />}
      {action === "createCalendar" && (
        <CalendarActionDetails handleClose={handleClose} />
      )}

      {/* New Changs M& item two button */}
      {action === "create" && (
        <AddActions
          getAllActions={getAllActions}
          action_type={action_type}
          workshop_id={workshop_id}
          action={action}
          handleClose={handleClose}
          currentWorkshopDetails={workshopOrder}
          tabId={actionTabId}
          name={actions}
          copyTab={copyTab}
        />
      )}
      {action === "camera" && (
        <ManageTask
          styleProp={access.read ? "none" : undefined}
          handleCloseDailog={() => setAction(null)}
          open={open}
          action_type={action_type}
          status={currentSelection.status}
          selectedId={currentSelection?.id}
          planning_verification={currentSelection.planning_verification}
          permission={props?.permission}
        />
      )}
      {action === "msg" && (
        <Comment
          styleProp={access.read ? "none" : undefined}
          handleClickMsg={handleClickMsg}
          handleClose={handleClose}
          open={open}
          status={currentSelection.status}
          selectedId={currentSelection?.id}
          planning_verification={currentSelection.planning_verification}
          permission={props?.permission}
        />
      )}
      {action === "certificate" && (
        <Certificate
          styleProp={access.read ? "none" : undefined}
          handleClickCertificate={handleClickCertificate}
          handleCloseDailog={() => setAction(null)}
          selectedId={currentSelection?.id}
          action_type={action_type}
          status={currentSelection.status}
          open={open}
          planning_verification={currentSelection.planning_verification}
          permission={props?.permission}
        />
      )}
      {action === "infoCalendar" && (
        <CalendarActionInfo handleClose={handleClose} />
      )}
      {action === "info" && (
        <ActionInformation
          styleProp={access.read ? "none" : undefined}
          readProp={access.read}
          getAllActions={getAllActions}
          selectedId={currentSelection?.id}
          workshop_id={workshop_id}
          handleClose={handleClose}
          getAllActionStages={getAllActionStages}
          setSelectedRowId={setSelectedRowId}
          orderState={orderState}
          planning_verification={currentSelection.planning_verification}
          permission={props?.permission}
          currentWorkshopDetails={workshopOrder}
        />
      )}
      {action === "viewAction" && (
        <StageInfo
          styleProp={access.read ? "none" : undefined}
          getAllActionStages={getAllActionStages}
          action_Id={selectedStages?.id}
          handleClose={handleClose}
          orderState={orderState}
          planning_verification={currentSelection.planning_verification}
          permission={props?.permission}
        />
      )}
      {action === "addButton" && (
        <AddStageTab
          handleClose={handleClose}
          action_Id={currentSelection?.id}
          getActionStages={getAllActionStages}
        />
      )}

      {
        (orderStateNum > 3 && orderStateNum <= 5
          && tabStatus === true &&
          actions === "Inspection"
          && props.permission.write && (
            <Grid
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "20px",
              }}
            >
              <MROButton
                value={editTab}
                variant={"contained"}
                style={{
                  backgroundColor: editTab ? "green" : "#FFB000",
                  color: "white",
                }}
                onClick={handleTab}
                startIcon={
                  <img
                    className={classes.svg}
                    src={editTab ? unlock : lock}
                    alt="lock"
                  />
                }
              >
                {editTab ? "Open" : "Unlock"}
              </MROButton>
            </Grid>
          ))}
      {
        (orderStateNum > 5 &&
          orderStateNum <= 8 && tabStatus === true && actions === "Maintenance"
          && props.permission.write && (
            <Grid
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "20px",
              }}
            >
              <MROButton
                value={editTab}
                variant={"contained"}
                style={{
                  backgroundColor: editTab ? "green" : "#FFB000",
                  color: "white",
                }}
                onClick={handleTab}
                startIcon={
                  <img
                    className={classes.svg}
                    src={editTab ? unlock : lock}
                    alt="lock"
                  />
                }
              >
                {editTab ? "Open" : "Unlock"}
              </MROButton>
            </Grid>
          ))}

      {
        (orderStateNum > 5 && orderStateNum <= 8
          && tabStatus === true && actions === "Production"
          && props.permission.write && (
            <Grid
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "20px",
              }}
            >
              <MROButton
                value={editTab}
                variant={"contained"}
                style={{
                  backgroundColor: editTab ? "green" : "#FFB000",
                  color: "white",
                }}
                onClick={handleTab}
                startIcon={
                  <img
                    className={classes.svg}
                    src={editTab ? unlock : lock}
                    alt="lock"
                  />
                }
              >
                {editTab ? "Open" : "Unlock"}
              </MROButton>
            </Grid>
          ))}
      {action === "unlock" && (
        <CautionPopUp
          open={editTab}
          handleClose={() => setAction(null)}
          cautionMessages={closureMessage}
          getAllActionStages={getAllActionStages}
          workshop_id={workshop_id}
          tab={"inspection"}
          setEditTab={setEditTab}
          editTab={editTab}
          action_type={action_type}
          getWorkshopOrderDetails={getWorkshopOrderDetails}
          getMaintenanceOrderList={props.getMaintenanceOrderList}
        />
      )}
      {action === "reorder" && <ReorderAction handleClose={() => setAction(null)} workshop_id={workshop_id} action_type={action_type} getListing={getAllActions} />}   {action === "reorder_stages" && <ReorderStages handleClose={() => setAction(null)} workshop_id={workshop_id} action_type={action_type} getListing={getAllActionStages} selectedId={currentSelection?.id} />}
    </div>
  );
}

export default Inspection;
