import requestrApi from "lib/services/api/common/boxr_api_client";
// import requestApi from "../../../common/boxp_api_client";

const getBatchNumbers = (params) => {
  return requestrApi({
    url: "/primary_parts/secondary_part_info",
    method: "GET",
    params: params,
  });
};

// Line Work order parts tab removed part section
const getRemovedParts = (params) => {
  return requestrApi({
    url: "/part_conditions/work_order_removed_parts",
    method: "GET",
    params: params,
  });

}

const createRemovedParts = (formdata) => {
  return requestrApi({
    url: "/part_conditions/create_allocation",
    method: "POST",
    data: formdata,
  });

}

const editRemovedParts = (data) => {
  return requestrApi({
    url: "/part_conditions/edit_allocation",
    method: "PUT",
    data
  });

}
const deleteRemovedParts = (data) => {
  return requestrApi({
    url: "/part_conditions/destroy_allocation",
    method: "DELETE",
    data
  });

}

const UpdateMaintTechLog = (data) => {
  return requestrApi({
    url: "/operational_tasks/update_task",
    method: "PUT",
    data
  });

}


const getMaintPlannedTableDetails = (params) => {
  return requestrApi({
    url: "/work_orders/planned_maintenance_table",
    method: "GET",
    params: params,
  });

}
const EditPlannedTask = (data) => {
  return requestrApi({
    url: "/work_orders/edit_planned_tasks",
    method: "PUT",
    data
  });

}


const DeassignPlannedTask = (data) => {
  return requestrApi({
    url: "/work_orders/deassign_planned_task",
    method: "PUT",
    data
  });

}

const EditPlannedRecords = (data) => {
  return requestrApi({
    url: "/work_orders/edit_records",
    method: "PUT",
    data
  });

}

const UndoPlannedTask = (data) => {
  return requestrApi({
    url: "/work_orders/undo_deassign_task",
    method: "PUT",
    data
  });

}
function deleteDocument(params) {
  return requestrApi({
    url: `/generals/remove_attachment`,
    method: "DELETE",
    params
  });
}
export const WorkOrderServices = {
  getBatchNumbers,
  getRemovedParts,
  createRemovedParts,
  editRemovedParts,
  deleteRemovedParts,
  UpdateMaintTechLog,
  getMaintPlannedTableDetails,
  EditPlannedTask,
  DeassignPlannedTask,
  EditPlannedRecords,
  deleteDocument,
  UndoPlannedTask
};
