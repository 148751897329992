import React, { useContext, useEffect, useReducer, useState } from "react";
import { Box, Dialog, DialogContent, Typography } from "@material-ui/core";

import { ToastMessageContext } from "lib/contexts/message_context";
import dataReducer, { INITIAL_DATA_STATE } from "reducers/data_reducer";
import DataServiceAction from "actions/data_actions";

import { useReturnIssuedPartStyles } from "./styles";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import QRCodeStep from "./QRCodeStep";

function ReturnIssuedPart(props) {
  const {
    open,
    onClose,
    allocId,
    currentSelection,
    qtyFieldTitle,
    updateTable,
    getList,
    getPopupData,
    jobType,
    name,
    mroOwned , partConditionId
    // 
  } = props;
  // console.log("condition=====", currentSelection, jobType)
  const classes = useReturnIssuedPartStyles();
  const [busy, setBusy] = useState();
  const [qty, setQty] = useState();
  const message = useContext(ToastMessageContext);
  const [state, rDispatch] = useReducer(dataReducer, INITIAL_DATA_STATE);

  const [step, setStep] = useState(1);
  const [value, setValue] = useState(null);
  const [errors, setErrors] = useState({});
  const [newAllocId, setNewAllocId] = useState(null);

  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    const qty = +formData.get("quantity");

    if (!qty && qty !== 0) {
      setErrors({ quantity: `Please enter quantity` });
      return;
    }

    if (qty > currentSelection?.quantity) {
      setErrors({ quantity: `QTY must not be greater than allocation!` });
      return;
    }

    if (qty === 0) {
      setErrors({
        quantity: "QTY must not be less than zero",
      });
      return;
    }

    setBusy("loading");
    // formData.append("alloc_id", allocId);
    formData.append("part_condition_id" , partConditionId)
    // formData.append("quantity", qty);
    const cb = () => {
      typeof updateTable === "function" && updateTable();
      typeof getList === "function" && getList();
      typeof getPopupData === "function" && getPopupData();
      setStep(2);
      setBusy(null);
    };

    const errCb = () => setBusy("error");
    const res = await DataServiceAction.updateData(
      rDispatch,
      message,
      formData,
      null,
      mroOwned === 'managed_part' ? "directReturnPartrequest" : "editPartRequest",
      null,
      cb,
      errCb
    );

    if (res) {
      setNewAllocId(Number(res.alloc_id));
    }
  };

  const continueHandler = () => {
    if (value !== "yes") {
      setErrors((prevState) => ({
        ...prevState,
        rts_label: "Item/s must be in RTS location with label attached!",
      }));
      return;
    }

    onClose();


    setTimeout(() => {
      setValue(null)
      setStep(1)
    }, 3000)
    // setStep(1)



  };

  const handleToggle = (value) => {
    if (value) {
      setValue(value);
      setErrors((prevState) => ({ ...prevState, rts_label: null }));
    }
  };

  const { quantity } = currentSelection;
  useEffect(() => {
    setQty(quantity);
  }, [quantity]);

  const returnContent = () => {
    switch (step) {
      case 1:
        return (
          <StepOne
            allocId={allocId}
            submitHandler={submitHandler}
            errors={errors}
            setErrors={setErrors}
            setQty={setQty}
            qty={qty}
            currentSelection={currentSelection}
            onClose={onClose}
            step={step}
            setStep={setStep}
            busy={busy}
            jobType={jobType}
            qtyFieldTitle={qtyFieldTitle}
          />
        );

      case 2:
        return (
          <StepTwo
            setStep={setStep}
            handleToggle={handleToggle}
            value={value}
            errors={errors}
            continueHandler={continueHandler}
            mroOwned={mroOwned}
            step={step}
            currentSelection={currentSelection}
          />
        );

      case 3:
        return (
          <QRCodeStep
            setStep={setStep}
            onClose={onClose}
            allocId={newAllocId ?? allocId}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      maxWidth={"lg"}
      open={open}
      onClose={onClose}
      aria-labelledby="return-issued-part"
      className={classes.dialog}
    >
      {(mroOwned === 'managed_part' || currentSelection.condition === "Reserved") ?
        <DialogContent
          style={{
            padding: "26px 40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: step === 3 ? "423px" : "658px",
          }}
        >{step !== 3 &&
          (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb="35px"
            >
              <Typography color="primary" style={{ fontSize: "18px" }}>
                {step === 1 ? `Return to Stock - Step ${step}` : "RTS - Return to Stock"}
                {/* Return to Stock - Step {step} */}
              </Typography>
              <Typography
                color="textPrimary"
                style={{ fontSize: "18px", color: "#4D4F5C" }}
              >
                ALOC ID: {allocId}
              </Typography>
            </Box>
          )}
          {returnContent()}
        </DialogContent> :
        <DialogContent style={{
          padding: "26px 40px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: step === 3 ? "423px" : "658px",
        }}>

          {step !== 3 && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb="35px"
            >
              <Typography color="primary" style={{ fontSize: "18px" }}>
                Return to Stock - Step {step}
              </Typography>
              <Typography
                color="textPrimary"
                style={{ fontSize: "18px", color: "#4D4F5C" }}
              >
                ALOC ID: {allocId}
              </Typography>
            </Box>
          )}
          {returnContent()}
        </DialogContent>}


    </Dialog>
  );
}

export default ReturnIssuedPart;
