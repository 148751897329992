import requestrApi from "./common/boxr_api_client";

const creationUrl = {
    training: '/trainings',
    competency: '/competencies',
    storageCondition: '/storage_conditions',
    procedureCategories: '/procedure_categories',
    aogSMS: '/line_customers/create_aog_sms_contacts',
    kpiEmail: '/line_customers/create_kpi_emails',
    reportEmail: '/line_customers/create_report_emails',
    competencyBaseList: '/competencies/base_applications',
    competencyGroupList: '/competencies/group_applications',
    trainingBaseList: '/trainings/base_applications',
    trainingGroupList: '/trainings/group_applications',
    delayCodes: '/line_customers/create_delay_codes',
    fixedPriceListGroup: '/line_customers/create_aircraft_type_groups',
    fixedPriceList: '/line_customers/create_fixed_price_lists',
    approvalType: '/approval_types',
    companyApprovals: '/company_approvals',
    approvalTypeGroup: '/group_types',
    approvalTypePermission: '/approval_types/create_type_permissions',
    typePermissions: '/approval_types/map_aircraft_permissions',
    approvalTypePerm: '/group_types/map_approval_types',
    authorisation: '/auth_lists',
    customerInfo: '/line_customers/create_customer_info',
    taskLibrary: '/task_libraries',
    vehicles: '/vehicles',
    baseStationChecks: '/base_stations/map_station_checks',
    competencyCategory: '/competencies/create_category',
    competencyQuestion: '/competencies/create_question',
    handoverCheck: '/handovers/create_setup',
    scheduledChecks: '/line_customers/save_scheduled_check_hours',
    lineCustomers: '/line_customers/listing_line_customers',
    createLineCustomer: '/line_customers/create_line_customers',
    updateLineCustomer: '/line_customers/update_line_customers',
    vendorParts: '/vendor_parts',
    saveTooling: '/operation_fields/save_toolings',
    operationalTasks: '/operational_tasks',
    uploadDocs: '/operation_fields/upload_docs',
    primaryParts: '/primary_parts',
    currencies: '/currencies',
    saveCertificates: '/primary_parts/save_certificates',
    formOne: '/form_ones/organisation_form_one',
    createContact: "/customer/contact",
    createEmail: "/customer/report_emails",
    createCheckRate: "/check_rates",
    createFixedFees: "/line_customers/create_lc_app_type_rates",
    createBasedAircraft: "line_customers/create_based_ac_group"

}

function createData(formData, section) {

    return requestrApi({
        url: creationUrl[section],
        method: 'POST',
        data: formData
    })
}

export default createData;
