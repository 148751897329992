import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";

const sanitizeInput = (value) => {
  return value.replace(/[<>/\\]/g, ""); // Removes <, >, /, and \ characters
};

function container() {
  // Use the fullscreen element if in fullscreen mode, otherwise just the document's body
  return document.fullscreenElement ?? document.body;
}

const MROTextField = withStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.primary.main,
        // borderWidth: '1px !important'
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.light + "!important",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "rgba(0, 0, 0, 0.26) !important",
      },
      "&.Mui-error:hover  fieldset": {
        borderColor: "#f44336 !important",
      },
      "& .MuiOutlinedInput-input": {
        padding: "15px",
      },
    },
    "& label": {
      color: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-input": {
      "&::placeholder": {
        textTransform: "none",
      },
    },
  },
}))((props) => {
  const [value, setValue] = useState(props.value || "");
  const handleChange = (event) => {
    // const sanitizedValue = sanitizeInput(event.target.value);
    setValue(event.target.value); // Update local state with sanitized value
    // eslint-disable-next-line no-unused-expressions
    props.onChange?.({
      ...event,
      target: {
        ...event.target,
        value: event.target.value.replace(/[<>\\]/g, ""),
      }, // Update the value with sanitized input
    });
  };
  return !props.select ? (
    <>
      <TextField
        // value={value} // Use local state value
        style={{ width: props?.width ? props?.width : "" }}
        classes={props?.classes}
        InputProps={{
          autoComplete: false,
          autoCorrect: false,
          startAdornment: props.startAdornment ? props.startAdornment : "",
          endAdorment: props.endAdorment ? props.endAdorment : "",
        }}
        fullWidth
        variant={props.variant ?? "outlined"}
        margin={"normal"}
        color={"primary"}
        inputProps={{ maxLength: 50 }}
        helperText={
          props.error
            ? `Please ${props.select ? "select" : "enter"
            } the ${props.label?.replace("*", "")}`
            : null
        }
        {...props}
        // onChange={handleChange} // Sanitize input on change
        SelectProps={{
          MenuProps: { container: container },
        }}
      />
    </>
  ) : (
    <>
      <TextField
        style={{ width: props?.width ? props?.width : "" }}
        classes={props?.classes}
        InputProps={{
          autoComplete: false,
          autoCorrect: false,
          startAdornment: props.startAdornment ? props.startAdornment : "",
          endAdorment: props.endAdorment ? props.endAdorment : "",
        }}
        fullWidth
        variant={props.variant ?? "outlined"}
        margin={"normal"}
        color={"primary"}
        helperText={
          props.error
            ? `Please ${props.select ? "select" : "enter"
            } the ${props.label?.replace("*", "")}`
            : null
        }
        {...props}
        SelectProps={{
          MenuProps: { container: container },
        }}
      />
    </>
  );
});

export default MROTextField;
